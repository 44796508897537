import { Box, Button, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const FooterUpperWrapper = styled(Box)`
  padding-top: 80px;
  border-top-width: 2px;
  border-color: ${PALETTE.secondaryFour};
`;

export const FooterLowerWrapper = styled(Box)`
  border-color: ${PALETTE.secondaryFour};
  border-top-width: 1px;
  margin: 64px auto 0 auto;
  width: 100%;
`;

export const FooterUpperSection = styled(Box)`
  display: flex;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

export const FooterUpperSectionLeftContent = styled(Box)`
  text-align: center;
  display: flex;
  flex-flow: column;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const FooterLowerSection = styled(Box)`
  display: flex;
  padding: 24px 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

export const BrandLabelBox = styled(Box)`
  display: flex;
  gap: 5px;
`;

export const LinkUL = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const NewsletterBox = styled(Box)`
  width: 100%;

  @media (min-width: 640px) {
    width: 400px;
  }
`;

export const NewsletterInputBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

export const FooterLowerSectionUL = styled("ul")`
  display: flex;
  align-items: center;
`;

export const FooterLowerSectionLeftContent = styled(Box)`
  display: flex;
  gap: 10px;
`;

export const SocialNetworksBox = styled(Box)`
  display: flex;
  gap: 5px;
`;