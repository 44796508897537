import { Box } from "@mui/material";
import { BoldMediumText, BoldSmallText } from "../../utils/GlobalStyles";
import { BackgroundTextBox, Image, TextBox } from "./styles";
import { TeamCardProps } from "../../utils/Interfaces/Global";
import { useState } from "react";
import { PALETTE } from "../../assets/theme";

function TeamCard({ img, name, position, description }: TeamCardProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box style={{ position: "relative" }} mb={2.5}>
        <BackgroundTextBox isHovered={isHovered} />
        <TextBox isHovered={isHovered}>
          <BoldSmallText mt={1} textAlign="center">
            {description}
          </BoldSmallText>
        </TextBox>
        <Image src={img} alt={name} />
      </Box>
      <BoldMediumText>{name}</BoldMediumText>
      <BoldSmallText color={`${PALETTE.secondaryFour} !important`}>
        {position}
      </BoldSmallText>
    </Box>
  );
}

export default TeamCard;
