import { Box, Button, styled } from "@mui/material";
import { COLORS } from "../../../../../assets/theme";

export const InvitedTeamMembersHeaderWrapper = styled(Box)`
  display: flex;
  position: relative;
`;

interface InvitedTeamMemberCircleProps {
  pos: number;
}

export const InvitedTeamMemberCircle = styled(
  Box,
)<InvitedTeamMemberCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: ${COLORS.graySix};
  border: 1.5px solid ${COLORS.grayThree};
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: ${({ pos }) => `calc(${pos} * (25px - 6px))`};
  z-index: ${({ pos }) => `${pos}`};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const InvitedTeamMemberCircleButton = styled(
  "button",
)<InvitedTeamMemberCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: ${COLORS.grayFour};
  border: 1.5px solid ${COLORS.grayTwo};
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: ${({ pos }) => `calc(${pos} * (25px - 6px))`};
  z-index: ${({ pos }) => `${pos}`};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:hover {
    background-color: ${COLORS.grayThree};
  }
`;

export const AddMemberButtonCircle = styled(Box)`
  display: flex;
  padding: 0;
  align-items: center;
  flex-flow: column;
  background-color: ${COLORS.graySix};
  width: 23px;
  border-radius: 100%;
  height: 23px;
`;
