import { Box, styled } from "@mui/material";
import { COLORS } from "../../assets/theme";

export const CheckoutStepsWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`;

interface CardWrapperProps {
  checkout?: boolean;
  shoppingCart?: boolean;
}

export const CardWrapper = styled(Box)<CardWrapperProps>`
  display: flex;
  flex-flow: column;
  gap: 15px;
  width: 100%;
  background-color: purple;
  border-radius: 8px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${COLORS.white};

  @media (min-width: 900px) {
    max-width: ${({ shoppingCart }) => (shoppingCart ? "250px" : "100%")};
  }
`;
