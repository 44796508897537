import Rating from "@mui/material/Rating";
import { Box } from "@mui/material";
import {
  BoldMediumText,
  BoldSmallText,
  MediumText,
  SecondaryButton,
  SmallText,
} from "../../../../utils/GlobalStyles";
import {
  LighterDividerSection,
  RatingBox,
  ReviewAuthorInitialsBox,
  ReviewBottomContent,
  ReviewTopContent,
} from "./styles";
import { ReviewsProps } from "../../../../utils/Interfaces/Endpoints";
import { useState } from "react";
import { COLORS } from "../../../../assets/theme";

export default function Reviews({ reviews, reviewsRef }: ReviewsProps) {
  const numberOfItems = 3;
  const [showItems, setShowItems] = useState(numberOfItems);
  const handleShowMore = () => setShowItems(showItems + numberOfItems);

  return (
    <Box ref={reviewsRef}>
      <BoldMediumText mb={3}>Reviews</BoldMediumText>
      {reviews && (
        <>
          {reviews.length > 0 ? (
            <>
              {reviews?.slice(0, showItems)?.map((review, index) => (
                <>
                  <ReviewTopContent>
                    <ReviewAuthorInitialsBox>
                      {review?.firstName?.substring(0, 1).toUpperCase()}
                      {review?.lastName?.substring(0, 1).toUpperCase()}
                    </ReviewAuthorInitialsBox>
                    <Box>
                      <MediumText>
                        {review?.firstName} {review?.lastName}
                      </MediumText>
                      <SmallText>{review?.date}</SmallText>
                    </Box>
                  </ReviewTopContent>
                  <ReviewBottomContent>
                    <RatingBox>
                      <Rating
                        name="half-rating-read"
                        defaultValue={review?.classification}
                        precision={0.05}
                        readOnly
                      />
                    </RatingBox>
                    <MediumText fontWeight={700}>{review?.title}</MediumText>
                  </ReviewBottomContent>
                  <MediumText>{review?.comment}</MediumText>
                  <LighterDividerSection />
                </>
              ))}
              {showItems < reviews.length && (
                <SecondaryButton
                  sx={{ margin: "0 auto" }}
                  onClick={handleShowMore}
                >
                  <BoldSmallText color={`${COLORS.white} !important`}>
                    Ver mais
                  </BoldSmallText>
                </SecondaryButton>
              )}
            </>
          ) : (
            <MediumText>Ainda não há classificações para este caso.</MediumText>
          )}
        </>
      )}
    </Box>
  );
}
