import { Box, styled } from "@mui/material";

export const CookieConsentTextBox = styled(Box)`
  width: 450px;
  margin: 0 auto;

  @media (max-width: 650px) {
    width: 350px;
  }

  @media (max-width: 450px) {
    width: 85vw;
  }
`;
