export const howToPlayData = [
  {
    color: "gray",
    title: "DESARQUIVADOS",
    step: "1",
    description:
      "Monta a tua equipa com os elementos que contigo vão resolver um caso específico.",
  },
  {
    color: "gray",
    title: "Descarrega as pistas",
    step: "2",
    description:
      "Podes aceder às pistas através da plataforma ou descarregá-las.",
  },
  {
    color: "gray",
    title: "Analisa o caso",
    step: "3",
    description:
      "Tira o máximo de informação que conseguires para que no fim não falte nada para o apuramento da verdade.",
  },
  {
    color: "gray",
    title: "Apanha o criminoso!",
    step: "4",
    description:
      "Depois de definido o culpado do caso, está na hora de o reportares. Descobre se acertaram!",
  },
];

export default howToPlayData;
