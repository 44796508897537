import { useContext } from "react";
import { useLocation } from "react-router-dom";
import Authentication from "../Authentication";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { PrivateRouteProps } from "../../utils/Interfaces/Account";

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isAuthenticated } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Authentication previousLocation={location} />
  );
};
