import PageHeader from "../../components/PageHeader";
import TeamCard from "../../components/TeamCard";
import teamData from "../../utils/data/team-data";
import { AboutUsBox, AboutUsWrapper } from "./styles";
import ReactGA from "react-ga4";

function AboutUs() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/about-us",
      title: "About us",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "About Us");
    }
  }

  return (
    <>
      <AboutUsWrapper>
        <PageHeader
          heading="A equipa"
          section={""}
          content="Os membros que fazem parte do projeto e que permitem que isto seja
            possível."
        />
        <AboutUsBox>
          {teamData.map((data) => (
            <TeamCard {...data} />
          ))}
        </AboutUsBox>
      </AboutUsWrapper>
    </>
  );
}

export default AboutUs;
