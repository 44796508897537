import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { Transition } from "../../../utils/Transitions";
import { COLORS } from "../../../assets/theme";
import {
  BoldLargeText,
  BoldSmallText,
  NoBackgroundButton,
  SecondaryButton,
  SmallText,
} from "../../../utils/GlobalStyles";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  AddMembersInputWrapper,
  DialogContentWrapper,
  InvitationRow,
  InvitationsWapper,
  NameEmailBox,
} from "./styles";
import {
  AddTeamMembersModalProps,
  InvitationType,
} from "../../../utils/Interfaces/Game";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import validator from "validator";
import { deleteData, getData, postData } from "../../../utils/endpoints/api";
import SmallLoader from "../../loaders/SmallLoader";

export default function AddTeamMembersModal({
  caseData,
  modalOpen,
  handleModalOpen,
}: AddTeamMembersModalProps) {
  const { user, setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [formReady, setFormReady] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { data: invitations, error: invitationsError } = useQuery<
    InvitationType[],
    Error
  >({
    queryKey: ["invitations"],
    queryFn: () =>
      getData("my-case/team-members", {
        userId: user?.id!,
        orderItemId: caseData?.orderItemId.toString(),
      }),
  });

  const addTeamMemberMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("my-case/team-member/add", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
      setEmail("");
      setErrorMessage(null);
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao adicionar um membro à equipa. Tenta mais tarde!",
      ]);
    },
  });
  const removeTeamMemberMutation = useMutation({
    mutationFn: (params: Record<string, string>) =>
      deleteData("my-case/team-member/remove", params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao remover um membro à equipa. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    if (invitationsError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter os membros da equipa. Tenta mais tarde!",
      ]);
    }
  }, [invitationsError]);

  useEffect(() => {
    function checkFields() {
      if (validator.isEmail(email)) {
        if (invitations?.some((invitation) => invitation.email === email)) {
          setFormReady(false);
          setErrorMessage("Um jogador com este email já foi convidado");
        } else {
          setFormReady(true);
          setErrorMessage(null);
        }
      } else {
        setFormReady(false);
        setErrorMessage(null);
      }
    }
    checkFields();
  }, [email]);

  const handleAddTeamMember = async () => {
    try {
      await addTeamMemberMutation.mutateAsync({
        params: {
          userId: user?.id!,
          userName: user?.firstName + " " + user?.lastName,
          caseName: caseData.name,
          inviteUrlBase: window.location.origin,
          invitedEmail: email,
          orderItemId: caseData?.orderItemId!.toString(),
        },
        body: {},
      });
    } catch (error) {
      console.error("Error inviting team member:", error);
    }
  };

  const handleRemoveTeamMember = async (invitedEmail: string) => {
    try {
      if (user && invitedEmail && caseData?.orderItemId) {
        await removeTeamMemberMutation.mutateAsync({
          userId: user?.id!,
          invitedEmail: invitedEmail,
          orderItemId: caseData?.orderItemId.toString(),
        });
      }
    } catch (error) {
      console.error("Error inviting team member:", error);
    }
  };

  const statusIcon = (status: string) => {
    switch (status) {
      case "PENDING":
        return (
          <Tooltip title="Pendente de aceitação">
            <HistoryRoundedIcon
              sx={{ width: "25px", height: "25px", color: COLORS.orangeOne }}
            />
          </Tooltip>
        );
      case "ACCEPTED":
        return (
          <Tooltip title="Convite aceite">
            <CheckCircleOutlineRoundedIcon
              sx={{ width: "25px", height: "25px", color: COLORS.greenTwo }}
            />
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  const isLoading =
    addTeamMemberMutation.isPending || removeTeamMemberMutation.isPending;

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={modalOpen}
        onClose={handleModalOpen}
        fullWidth
      >
        <DialogTitle
          my={"10px"}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <BoldLargeText>Adicionar membros à equipa</BoldLargeText>
          <NoBackgroundButton onClick={handleModalOpen}>
            <CloseRoundedIcon sx={{ width: "20px", height: "20px" }} />
          </NoBackgroundButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentWrapper mb={1}>
            {isLoading ? (
              <SmallLoader />
            ) : (
              <>
                <BoldSmallText
                  color={
                    invitations && invitations.length < 8
                      ? ""
                      : `${COLORS.redTwo} !important`
                  }
                >
                  Podes adicionar até 8 membros à equipa.
                </BoldSmallText>
                {invitations && invitations.length < 8 && (
                  <>
                    <AddMembersInputWrapper mt={2}>
                      <TextField
                        size="small"
                        value={email}
                        label="Email"
                        variant="outlined"
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setEmail(e?.target?.value)
                        }
                      />
                      <SecondaryButton
                        onClick={handleAddTeamMember}
                        disabled={!formReady}
                      >
                        <SmallText sx={{ color: COLORS.white }}>
                          Adicionar
                        </SmallText>
                      </SecondaryButton>
                    </AddMembersInputWrapper>
                    {errorMessage && (
                      <BoldSmallText
                        mt={2}
                        color={`${COLORS.redTwo} !important`}
                      >
                        {errorMessage}
                      </BoldSmallText>
                    )}
                    <BoldSmallText mt={2}>
                      Caso o membro não receba nenhum email, deve verificar a
                      pasta de spam.
                    </BoldSmallText>
                  </>
                )}
                <InvitationsWapper mt={2}>
                  {invitations &&
                    invitations.map((item: InvitationType) => (
                      <InvitationRow>
                        {statusIcon(item.status)}
                        <NameEmailBox>
                          <BoldSmallText>{item.name}</BoldSmallText>
                          <SmallText>{item.email}</SmallText>
                        </NameEmailBox>
                        <Button
                          onClick={() => handleRemoveTeamMember(item.email)}
                          sx={{ marginLeft: "auto" }}
                        >
                          <CancelRoundedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: COLORS.redTwo,
                            }}
                          />
                        </Button>
                      </InvitationRow>
                    ))}
                </InvitationsWapper>
              </>
            )}
          </DialogContentWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
}
