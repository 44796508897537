export const PALETTE = {
  primaryOne: "#F3D386",
  primaryTwo: "#B00000",
  primaryThree: "#F6C900",
  secondaryOne: "#A52C23",
  secondaryTwo: "#386A69",
  secondaryThree: "#F27F22",
  secondaryFour: "#9D714B",
};

export const COLORS = {
  black: "#000000",
  white: "#ffffff",
  grayOne: "#f2f2f2",
  grayTwo: "#cccccc",
  grayThree: "#ababab",
  grayFour: "#828282",
  grayFive: "#5e5e5e",
  graySix: "#333333",
  blueOne: "#80bfff",
  blueTwo: "#3399ff",
  blueThree: "#0570de",
  blueFour: "#1e40af",
  blueFive: "#2C5454",
  redOne: "#f8bbd0",
  redTwo: "#df1b41",
  redThree: "#8c0000",
  purpleOne: "#553c9a",
  yellowOne: "#FFFBF1",
  yellowTwo: "#fef9e5",
  yellowThree: "#fceeb2",
  yellowFour: "#f9de66",
  yellowFive: "#ddb400",
  greenOne: "#00940a",
  greenTwo: "#007608",
  greenThree: "#3e503b",
  bejeOne: "#fff8d3",
  orangeOne: "#fea400",
};
