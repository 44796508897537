import { Box, Button, styled } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const FooterWrapper = styled(Box)`
  width: 100%;
  height: 70px;
  background-color: ${COLORS.grayTwo};
`;

export const FooterBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 40px;
  border-top-width: 2px;
  border-color: ${COLORS.grayThree};
`;

export const FooterLeftBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 40px;
  border-top-width: 2px;
  border-color: ${COLORS.grayThree};
`;

export const OtherCasesButton = styled("button")`
  &:hover {
    p {
      color: ${COLORS.blueFour} !important;
    }
  }
`;
