import CaseCardToPurchase from "../CaseCardToPurchase";
import { useQuery } from "@tanstack/react-query";
import PageHeader from "../PageHeader";
import { CasesBox, CasesToPurchaseWrapper } from "./styles";
import { CaseType } from "../../utils/Interfaces/Case";
import { getData } from "../../utils/endpoints/api";
import SmallLoader from "../loaders/SmallLoader";
import { useContext, useEffect } from "react";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import ReactGA from "react-ga4";

function CasesToPurchase() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  const { setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;

  const {
    data: casesToPurchase,
    error: casesToPurchaseError,
    isLoading,
  } = useQuery<CaseType[], Error>({
    queryKey: ["casesToPurchase"],
    queryFn: () => getData("case/list"),
  });

  useEffect(() => {
    if (casesToPurchaseError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter a lista de casos. Tenta mais tarde!",
      ]);
    }
  }, [casesToPurchaseError]);

  useEffect(() => {
    if (casesToPurchase && isProduction) {
      ReactGA.send({
        hitType: "pageview",
        page: "/cases",
        title: "Cases to buy",
      });
      if (typeof window.fbq !== "undefined") {
        window.fbq("track", "Cases to purchase");
      }
    }
  }, [casesToPurchase]);

  return (
    <CasesToPurchaseWrapper>
      <PageHeader
        heading="Casos a investigar"
        section={""}
        content="Precisamos da tua ajuda para Desarquivar casos que ficaram por resolver. Só um detetive experiente como tu é capaz de fazer a diferença. Contamos contigo?"
      />
      <CasesBox>
        {isLoading ? (
          <SmallLoader />
        ) : (
          casesToPurchase?.map((props, key) => (
            <CaseCardToPurchase key={key} {...props} />
          ))
        )}
      </CasesBox>
    </CasesToPurchaseWrapper>
  );
}

export default CasesToPurchase;
