import { MediumText, SmallText } from "../../utils/GlobalStyles";
import {
  NewsFooterBox,
  NewsFooterDiv,
  NewsInsideWrapper,
  NewsWrapper,
} from "./styles";

const LATESTNEWS = [
  {
    text: "Caso Activeish Bank vai a tribunal esta terça feira. Jorge Camalho Mendes faz comunicado à TIC",
  },
  {
    text: "Presidente do Expressivo demite-se depois da controvérsia dos quadros falsificados",
  },
  { text: "Esfaqueamento à porta do Vida Doce na Damaia deixa um ferido" },
  {
    text: "Motorista da Uder atacado por taxistas",
  },
  {
    text: "Via Laranja aumenta preços em 2022. PECO PROTESTE repudia novo tarifário da Via Laranja. Está a ser investigado o aumento de forma não constitucional",
  },
  {
    text: "Infitex pressionada pelas autoridades para revelar fornecedores. Amâncio Fortuna deixa comentário ao Expressivo",
  },
];

export default function NewsFooter({}) {
  return (
    <NewsFooterBox>
      <NewsWrapper>
        <NewsInsideWrapper>
          <NewsFooterDiv>
            {LATESTNEWS.map((news) => (
              <SmallText sx={{ textTransform: "uppercase" }}>
                {news.text}
                &nbsp;&nbsp;&nbsp;&nbsp;{"|"}&nbsp;&nbsp;&nbsp;&nbsp;
              </SmallText>
            ))}
          </NewsFooterDiv>
          <NewsFooterDiv>
            {LATESTNEWS.map((news) => (
              <SmallText sx={{ textTransform: "uppercase" }}>
                {news.text}
                &nbsp;&nbsp;&nbsp;&nbsp;{"|"}&nbsp;&nbsp;&nbsp;&nbsp;
              </SmallText>
            ))}
          </NewsFooterDiv>
        </NewsInsideWrapper>
      </NewsWrapper>
    </NewsFooterBox>
  );
}
