import PageHeader from "../../components/PageHeader";
import MiniTutorialStep from "./MiniTutorialStep";
import { ArrowStyledIcon, MiniTutorialWrapper, StepsBox } from "./styles";
import FingerprintRoundedIcon from "@mui/icons-material/FingerprintRounded";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import ImageSearchRoundedIcon from "@mui/icons-material/ImageSearchRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
import LocalMallRoundedIcon from "@mui/icons-material/LocalMallRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import DevicesIcon from "@mui/icons-material/Devices";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import MilitaryTechRoundedIcon from "@mui/icons-material/MilitaryTechRounded";

import {
  BoldSmallText,
  MainButton,
  MediumText,
} from "../../utils/GlobalStyles";
import { NavLink } from "react-router-dom";
import { MiniTutorialStepProps } from "../../utils/Interfaces/Global";

const stepsData: MiniTutorialStepProps[] = [
  {
    step: 1,
    stepText: "Desarquivar o caso",
    icon: UnarchiveRoundedIcon,
    tooltipData: [
      {
        text: "Criar uma conta",
        icon: AssignmentIndRoundedIcon,
        description:
          "Só depois de criar conta é que terás acesso aos casos comprados",
      },
      {
        text: "Escolher um caso",
        icon: AdsClickRoundedIcon,
        description: "Vê os casos disponíveis e escolhe um para Desarquivar",
      },
      {
        text: "Comprar um caso",
        icon: LocalMallRoundedIcon,
        description:
          "Podes comprar um ou vários casos em simultâneo. Basta adicioná-los ao carrinho e seguir os passos do checkout",
      },
    ],
  },
  {
    step: 2,
    stepText: "Reunir os detetives",
    icon: Groups2RoundedIcon,
    tooltipData: [
      {
        text: "A equipa",
        icon: Diversity3RoundedIcon,
        description:
          "Reúne o teu grupo de amigos, familiares ou colegas. Podem se juntar física ou virtualmente. Tudo é possível",
      },
      {
        text: "Partilhar o caso",
        icon: MarkEmailReadRoundedIcon,
        description:
          "Podes partilhar o acesso ao caso e às provas com a restante equipa. Basta colocares o email dos restantes detetives e eles terão também acesso às provas",
      },
    ],
  },
  {
    step: 3,
    stepText: "Investigar o caso",
    icon: ImageSearchRoundedIcon,
    tooltipData: [
      {
        text: "Acesso ao caso",
        icon: DevicesIcon,
        description:
          "Tens accesso aos dados do caso na plataforma. Lá encontrarás todos os tipos de provas como mensagens, áudios, entrevistas, recibos, videos de vigilância, etc...",
      },
    ],
  },
  {
    step: 4,
    stepText: "Descobrir o culpado",
    icon: FingerprintRoundedIcon,
    tooltipData: [
      {
        text: "Identificar o culpado",
        icon: BadgeRoundedIcon,
        description: "Podes submeter a resposta dentro do caso, na plataforma",
      },
      {
        text: "Decisão final",
        icon: GavelRoundedIcon,
        description:
          "Se acertaste no culpado, o caso termina com sucesso! <br/>Caso a escolha não esteja correta, poderás voltar atrás, analisar as provas novamente e submeter um novo relatório",
      },
      {
        text: "Partilhar o caso",
        icon: MilitaryTechRoundedIcon,
        description:
          "Quando desvendares o caso, serás reencaminhado para uma página com o resumo do caso e detalhes sobre cada um dos suspeitos. Terás um certificado para partilhar esta conquista nas redes sociais!",
      },
    ],
  },
];

export default function MiniTutorial() {
  return (
    <MiniTutorialWrapper>
      <PageHeader
        heading="Como Desarquivar um caso"
        section={""}
        content="Tão simples quanto isto."
      />
      <StepsBox>
        <MiniTutorialStep stepsData={stepsData[0]} />
        <ArrowStyledIcon />
        <MiniTutorialStep stepsData={stepsData[1]} />
        <ArrowStyledIcon />
        <MiniTutorialStep stepsData={stepsData[2]} />
        <ArrowStyledIcon />
        <MiniTutorialStep stepsData={stepsData[3]} />
      </StepsBox>
      <MediumText mt={4} textAlign="center">
        Se tiveres mais dúvidas, questões, ou sugestões podes ver as perguntas
        mais frequentes ou enviar-nos uma mensagem. Teremos todo o gosto em
        ouvir a tua opinião e responder às tuas questões
      </MediumText>
      <NavLink to="/contact-us">
        <MainButton size="large">
          <BoldSmallText>Contacta-nos</BoldSmallText>
        </MainButton>
      </NavLink>
    </MiniTutorialWrapper>
  );
}
