import { Box, styled } from "@mui/material";
import { COLORS } from "../../../assets/theme";

export const SmallLoaderDiv = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100%;
  width: 100%;
  min-height: 250px;
  z-index: 2;
`;
