import { useState } from "react";
import { CheckoutContext } from "../../utils/GeneralContext";
import { CheckoutSessionType } from "../../utils/Interfaces/Payment";
import { Navigate, Route, Routes } from "react-router-dom";
import Result from "../../components/CheckoutSteps/Result";
import SelectCheckoutPage from "./SelectCheckoutPage";
import ReactGA from "react-ga4";

function Checkout() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/checkout",
      title: "Checkout",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "Checkout");
    }
  }

  const [step, setStep] = useState<string>("BILLING");
  const [clientSecret, setClientSecret] = useState<CheckoutSessionType | null>(
    null
  );

  return (
    <CheckoutContext.Provider
      value={{
        step,
        setStep,
        clientSecret,
        setClientSecret,
      }}
    >
      <Routes>
        <Route path="/*" element={<Navigate replace to="/" />} />
        <Route path="/result/:sessionId" element={<Result />} />
        <Route path="/" element={<SelectCheckoutPage />} />
      </Routes>
    </CheckoutContext.Provider>
  );
}

export default Checkout;
