import { Box, styled } from "@mui/material";
import { COLORS } from "../../../assets/theme";

export const CaseGamePageWrapper = styled(Box)`
  display: none;

  @media (min-width: 800px) {
    display: block;
  }
`;

export const CaseGamePageMain = styled(Box)`
  display: flex;
  position: relative;
  height: 100vh;
  background-color: ${COLORS.white};
  flex-direction: column;
`;

export const CaseGamePageContent = styled(Box)`
  display: flex;
  flex: 1;
  max-height: calc(100% - 140px);
`;
