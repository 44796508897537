import { createElement } from "react";
import {
  IconBox,
  ItemBox,
  LeftContentBox,
  OurProductCharacteristicsBox,
  OurProductCharacteristicsWrapper,
  RightContentBox,
  Row,
} from "./styles";
import {
  BoldMediumText,
  MediumText,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { COLORS } from "../../assets/theme";

const OPTIONS_TOP = [
  {
    title: "Interrogatórios",
    icon: MicRoundedIcon,
  },
  {
    title: "Câmaras de vigilância",
    icon: VideocamRoundedIcon,
  },
  {
    title: "Mensagens",
    icon: TextsmsRoundedIcon,
  },
];

const OPTIONS_BOTTOM = [
  {
    title: "Fotografias",
    icon: CameraAltRoundedIcon,
  },
  {
    title: "Notícias",
    icon: NewspaperRoundedIcon,
  },
  {
    title: "E muito mais...",
    icon: MoreHorizRoundedIcon,
  },
];

function OurProductCharacteristics() {
  return (
    <OurProductCharacteristicsWrapper>
      <OurProductCharacteristicsBox>
        <LeftContentBox>
          <TitleMediumText color={`${COLORS.black} !important`} my={1.5}>
            A tua experiência
          </TitleMediumText>
          <MediumText color={`${COLORS.grayFive} !important`}>
            Para investigar os casos, terás acesso a uma plataforma onde estão
            reunidas todas as provas que criam uma experiência totalmente
            imersiva
          </MediumText>
        </LeftContentBox>
        <RightContentBox>
          <Row>
            {OPTIONS_TOP.map(({ title, icon: Icon }) => (
              <ItemBox>
                <IconBox>
                  {createElement(Icon as React.ElementType, {
                    style: {
                      width: "28px",
                      height: "28px",
                      color: COLORS.graySix,
                    },
                  })}
                </IconBox>
                <BoldMediumText
                  sx={{ whiteSpace: "wrap" }}
                  color={`${COLORS.grayFive} !important`}
                >
                  {title}
                </BoldMediumText>
              </ItemBox>
            ))}
          </Row>
          <Row>
            {OPTIONS_BOTTOM.map(({ title, icon: Icon }) => (
              <ItemBox>
                <IconBox>
                  {createElement(Icon as React.ElementType, {
                    style: {
                      width: "28px",
                      height: "28px",
                      color: COLORS.graySix,
                    },
                  })}
                </IconBox>
                <BoldMediumText
                  sx={{ whiteSpace: "wrap" }}
                  color={`${COLORS.grayFive} !important`}
                >
                  {title}
                </BoldMediumText>
              </ItemBox>
            ))}
          </Row>
        </RightContentBox>
      </OurProductCharacteristicsBox>
    </OurProductCharacteristicsWrapper>
  );
}

export default OurProductCharacteristics;
