import { ClimbingBoxLoader } from "react-spinners";
import { PALETTE } from "../../../assets/theme";
import { BigLoaderDiv } from "./styles";

function BigLoader() {
  return (
    <BigLoaderDiv>
      <ClimbingBoxLoader speedMultiplier={1.3} color={PALETTE.secondaryTwo} />
    </BigLoaderDiv>
  );
}

export default BigLoader;
