import { useMutation } from "@tanstack/react-query";
import {
  CaseReviewProps,
  CaseReviewRegisterType,
} from "../../utils/Interfaces/Case";
import CaseReviewModal from "./CaseReviewModal";
import CaseReviewSubmittedModal from "../BoughtCaseCard/CaseReviewSubmittedModal";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import React from "react";
import { postData } from "../../utils/endpoints/api";

function CaseReview({
  caseId,
  caseReviewModal,
  setCaseReviewModal,
  handleModalClose,
}: CaseReviewProps) {
  const { user, setWarningMessage } = React.useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const [caseReviewSubmittedModal, setCaseReviewSubmittedModal] =
    React.useState(false);

  const writeReviewMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("case/writeReview", params, body),
    onSuccess: () => {
      setCaseReviewModal(false);
      setCaseReviewSubmittedModal(true);
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao adicionar a review. Tenta mais tarde!",
      ]);
    },
  });

  const handleCaseReviewSubmittedModal = () =>
    setCaseReviewSubmittedModal(!caseReviewSubmittedModal);

  const handleCaseReview = async ({
    title,
    comment,
    classification,
  }: CaseReviewRegisterType) => {
    try {
      if (user?.id) {
        const reviewBody: CaseReviewRegisterType = {
          title: title,
          comment: comment,
          classification: classification,
        };
        await writeReviewMutation.mutateAsync({
          params: {
            userId: user?.id!,
            caseId: caseId?.toString()!,
          },
          body: reviewBody,
        });
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const isLoading = writeReviewMutation.isPending;

  return (
    <>
      <CaseReviewModal
        caseReviewModal={caseReviewModal}
        setCaseReviewModal={setCaseReviewModal}
        handleCaseReview={handleCaseReview}
        handleModalClose={handleModalClose}
        isLoading={isLoading}
      />
      <CaseReviewSubmittedModal
        caseReviewSubmitted={caseReviewSubmittedModal}
        handleModalClose={handleCaseReviewSubmittedModal}
      />
    </>
  );
}

export default CaseReview;
