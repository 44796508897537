import { Box, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../../assets/theme";

const StepWrapper = styled(Box)`
  height: 150px;
  width: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 35px;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (min-width: 850px) {
    height: 160px;
    width: 160px;
  }
`;

export const Step1Wrapper = styled(StepWrapper)`
  border: 2.5px solid ${PALETTE.primaryTwo};
  background-color: ${COLORS.yellowTwo};
`;

export const Step2Wrapper = styled(StepWrapper)`
  border: 2.5px solid ${PALETTE.primaryTwo};
  background-color: ${COLORS.yellowThree};
`;

export const Step3Wrapper = styled(StepWrapper)`
  border: 2.5px solid ${PALETTE.primaryTwo};
  background-color: ${COLORS.yellowFour};
`;

export const Step4Wrapper = styled(StepWrapper)`
  border: 2.5px solid ${PALETTE.primaryTwo};
  background-color: ${PALETTE.primaryThree};
`;
