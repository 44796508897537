import { Box, styled } from "@mui/material";
import { COLORS } from "../../../assets/theme";

export const OrdersTableWrapper = styled("div")`
  width: 100%;
  position: relative;
  overflow-x: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  margin: 25px 0;
`;

export const OrdersTableComp = styled("table")`
  text-align: left;
  width: 100%;
`;

export const THHeader = styled("th")`
  padding: 12px 24px;
`;

export const THBody = styled("th")`
  padding: 16px 24px;
  white-space: nowrap;
`;

export const TRBody = styled("tr")`
  padding: 16px 24px;
  white-space: nowrap;
  border-top: 1px solid ${COLORS.grayTwo};

  &:nth-child(even) {
    background-color: ${COLORS.yellowOne};
  }

  &:nth-child(odd) {
    background-color: ${COLORS.yellowTwo};
  }
`;

export const TDBody = styled("td")`
  padding: 16px 24px;
`;

export const NoOrdersBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 40px auto;
`;
