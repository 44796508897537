import CartItem from "../../../components/CartItem";
import { useContext } from "react";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import {
  CartDetailsBox,
  CartItemsHeaderBox,
  CartTotalBoxLowerSection,
  QuantitySubtotalHeader,
} from "./styles";
import {
  BoldMediumText,
  MainButton,
  MediumText,
  TitleMediumText,
  SmallText,
  BoldSmallText,
  DividerSection,
} from "../../../utils/GlobalStyles";
import { NavLink, useNavigate } from "react-router-dom";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { GroupedCartItemType } from "../../../utils/Interfaces/Cart";
import { NoOrdersBox } from "../../../components/Orders/OrdersTable/styles";
import { CardWrapper, CheckoutStepsWrapper } from "../styles";
import ReactGA from "react-ga4";

function ShoppingCart() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/shopping-cart",
      title: "Shopping cart",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "Shopping Cart");
    }
  }

  const { cart, groupedCart } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const navigate = useNavigate();

  const handleCheckoutNavigation = () => {
    navigate("/checkout");
  };

  return (
    <CheckoutStepsWrapper>
      <TitleMediumText>Carrinho de compras</TitleMediumText>
      {groupedCart && groupedCart?.length > 0 ? (
        <CartDetailsBox>
          <CardWrapper>
            <CartItemsHeaderBox>
              <BoldMediumText>Casos a comprar</BoldMediumText>
              <QuantitySubtotalHeader>
                <SmallText>Quantidade</SmallText>
                <SmallText>Subtotal</SmallText>
              </QuantitySubtotalHeader>
            </CartItemsHeaderBox>
            <DividerSection sx={{ margin: "0" }} />
            {groupedCart.map((item: GroupedCartItemType) => (
              <CartItem {...item} />
            ))}
          </CardWrapper>
          <CardWrapper shoppingCart>
            <BoldMediumText>Subtotal</BoldMediumText>
            <DividerSection sx={{ margin: "0" }} />
            <CartTotalBoxLowerSection>
              <BoldMediumText>€{cart?.totalAmount}</BoldMediumText>
              <SmallText>IVA incluído</SmallText>
            </CartTotalBoxLowerSection>
            <MainButton
              onClick={handleCheckoutNavigation}
              size="medium"
              fullWidth
            >
              <BoldMediumText>Checkout</BoldMediumText>
            </MainButton>
          </CardWrapper>
        </CartDetailsBox>
      ) : (
        <NoOrdersBox>
          <MediumText mb={1}>Não tens itens no carrinho de compras.</MediumText>
          <NavLink to="/cases">
            <MainButton sx={{ marginTop: 1 }}>
              <BoldSmallText>Adiciona aqui!</BoldSmallText>
            </MainButton>
          </NavLink>
        </NoOrdersBox>
      )}
    </CheckoutStepsWrapper>
  );
}

export default ShoppingCart;
