import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Transition } from "../../../utils/Transitions";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  NoBackgroundButton,
} from "../../../utils/GlobalStyles";
import { DeleteAccountConfirmationModalProps } from "../../../utils/Interfaces/Account";
import { COLORS } from "../../../assets/theme";

function DeleteAccountModal({
  modalOpen,
  handleModalOpen,
  handleDeleteAccount,
}: DeleteAccountConfirmationModalProps) {
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={modalOpen}
      onClose={handleModalOpen}
      fullWidth
    >
      <DialogTitle my={"10px"}>
        <BoldMediumText>
          Tens a certeza que queres apagar a tua conta?
        </BoldMediumText>
      </DialogTitle>
      <DialogContent>
        <MediumText>
          Sem ti, DESARQUIVAR os casos torna-se numa missão impossível...
        </MediumText>
        <BoldMediumText mt={2}>
          Esta ação é irreversível e vais perder acesso a todos os jogos.{" "}
        </BoldMediumText>
      </DialogContent>
      <DialogActions style={{ gap: "10px", margin: "20px" }}>
        <NoBackgroundButton onClick={handleModalOpen}>
          <BoldSmallText color={`${COLORS.redTwo} !important`}>
            Cancelar
          </BoldSmallText>
        </NoBackgroundButton>
        <MainButton onClick={handleDeleteAccount}>
          <BoldSmallText>Apagar</BoldSmallText>
        </MainButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAccountModal;
