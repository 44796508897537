import { Navigate, Route, Routes } from "react-router-dom";
import CasesToPurchase from "../../components/CasesShop";
import CaseToBuy from "./CaseToBuy";

export default function Cases() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate replace to="/cases" />} />
        <Route path="/" element={<CasesToPurchase />} />
        <Route path="/artist-death" element={<CaseToBuy caseId={1} />} />
        <Route path="/last-harvest" element={<CaseToBuy caseId={2} />} />
      </Routes>
    </>
  );
}
