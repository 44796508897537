import { StepCardWrapper, StepNumberBox, StepNumberTextBox } from "./styles";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
} from "../../utils/GlobalStyles";
import { StepCardProps } from "../../utils/Interfaces/Global";
import { COLORS } from "../../assets/theme";

function StepCard({ step, title, description }: StepCardProps) {
  return (
    <StepCardWrapper>
      <StepNumberBox mb={2}>
        <img
          src={require("../../assets/images/proof_card.png")}
          width={60}
          alt="proof card"
        />
        <StepNumberTextBox>
          <BoldLargeText>{step}</BoldLargeText>
        </StepNumberTextBox>
      </StepNumberBox>
      <BoldMediumText color={COLORS.graySix}>{title}</BoldMediumText>
      <MediumText mt={1}>{description}</MediumText>
    </StepCardWrapper>
  );
}

export default StepCard;
