import { Box, styled } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const OrderDetailsWrapper = styled(Box)`
  margin: 30px 0;

  @media (min-width: 1536px) {
    max-width: 1200px;
  }
`;

export const OrderNumberDateBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ProductImage = styled("img")`
  width: 100%;
  margin: 0 auto 16px auto;
  border-radius: 6px;

  @media (min-width: 600px) {
    width: 160px;
    padding: 0 auto 32px auto;
  }
`;

export const ProductOrder = styled(Box)`
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;

  @media (min-width: 600px) {
    flex-flow: row;
    margin-top: 24px;
    align-items: center;
    gap: 24px;
  }
  @media (min-width: 1024px) {
    gap: 32px;
  }
`;

export const ProductOrderDetails = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  @media (min-width: 600px) {
    flex-flow: row;
    align-items: center;
    gap: 0;
  }
`;

export const UpperContentWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  padding: 0 32px 16px 32px;
  gap: 8px;
  border-bottom: 1px solid ${COLORS.grayTwo};
`;

export const SummaryBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border-top: 1px solid ${COLORS.grayTwo};
`;

export const CardBox = styled(Box)`
  display: flex;
  flex-flow: column;
  padding: 16px;
  width: 100%;
  gap: 24px;
  background-color: ${COLORS.white};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  @media (min-width: 600px) {
    padding: 24px;
  }

  @media (min-width: 1024px) {
    padding: 32px;
  }
`;

export const ProductOrderStats = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
`;

export const MainContentWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  margin-top: 10px;
  width: 100%;
  gap: 10px;

  @media (min-width: 1024px) {
    gap: 20px;
  }
`;

export const TableHeader = styled(Box)`
  display: none;
  flex-flow: row;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  @media (min-width: 600px) {
    display: flex;
    padding: 0 24px;
    gap: 80px;
  }

  @media (min-width: 1024px) {
    padding: 0 32px;
  }
`;

export const TableHeaderLeft = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const TableHeaderRight = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
