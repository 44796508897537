import { Box, styled } from "@mui/material";

export const CartDetailsBox = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  flex-flow: column;

  @media (min-width: 900px) {
    flex-flow: row;
    align-items: start;
  }
`;

export const CartItemsHeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const CartTotalBoxLowerSection = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const QuantitySubtotalHeader = styled(Box)`
  display: none;
  gap: 45px;

  @media (min-width: 600px) {
    display: flex;
  }
`;
