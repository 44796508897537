import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AuthenticationContext,
  GeneralAppDataContext,
} from "../../../../utils/GeneralContext";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import validator from "validator";
import PasswordChecker from "../../../../components/PasswordChecker";
import { handleEnterKeyPressAction } from "../../../../utils/HelpFunctions";
import {
  AuthMainContent,
  FormBox,
  InputBox,
  PasswordCheckerBox,
} from "../styles";
import {
  BoldSmallText,
  MainButton,
  MediumText,
  TitleMediumText,
  SmallText,
  UnderlinedButton,
} from "../../../../utils/GlobalStyles";
import { IconButton, TextField } from "@mui/material";
import OTP from "../OTP";
import {
  AuthenticationContextType,
  GeneralAppDataContextType,
} from "../../../../utils/Interfaces/Global";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS } from "../../../../assets/theme";
import { generatePortugueseError } from "../../../../utils/Functions/CognitoErrorTranslator";

function ResetPassword() {
  const { setIsLoading } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const { username, setStep, cognitoMessage, setCognitoMessage } = useContext(
    AuthenticationContext
  ) as AuthenticationContextType;

  const [formReady, setFormReady] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [passswordIsValid, setPasswordIsValid] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showCodeField, setShowCodeField] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    function checkFields() {
      if (!showCodeField) {
        if (validator.isEmail(email)) {
          setFormReady(true);
        } else {
          setFormReady(false);
        }
      } else {
        if (code !== "" && code.length === 6 && passswordIsValid) {
          setFormReady(true);
        } else {
          setFormReady(false);
        }
      }
    }
    checkFields();
  }, [email, code, passswordIsValid, showCodeField, setIsLoading]);

  const handleSubmission = async (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (formReady) {
      if (showCodeField) {
        await handleConfirmResetPassword();
      } else {
        await handleRequestCode();
      }
    }
  };

  async function handleRequestCode() {
    await resetPassword({ username: email })
      .then(async () => {
        setCognitoMessage(null);
        setShowCodeField(true);
      })
      .catch((error: Error) => {
        setCognitoMessage(generatePortugueseError(error));
      });
  }

  async function handleConfirmResetPassword() {
    setIsLoading(true);
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: newPassword,
    })
      .then(() => {
        setSuccessMessage("Nova password definida.");
        setTimeout(() => {
          setStep("LOG_IN");
        }, 1200);
        setIsLoading(false);
      })
      .catch((error: Error) => {
        setCognitoMessage(generatePortugueseError(error));
        setIsLoading(false);
      });
  }

  const handleTogglePassword = (
    setter: Dispatch<SetStateAction<boolean>>,
    value: boolean
  ) => setter(!value);

  return (
    <AuthMainContent>
      <TitleMediumText>Redefinir a Password</TitleMediumText>
      {showCodeField ? (
        <MediumText mt={1} mb={3}>
          Insere o código que recebeste no teu email.
        </MediumText>
      ) : (
        <MediumText mt={1} mb={3}>
          Um código será enviado para o teu email. Garante que lhe tens acesso.
        </MediumText>
      )}
      <FormBox>
        <InputBox>
          <TextField
            label="Email"
            sx={{ backgroundColor: "white" }}
            variant="outlined"
            autoComplete="email"
            placeholder="name@mail.com"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleSubmission, e);
            }}
            disabled={showCodeField}
          />
          {showCodeField && (
            <>
              <SmallText sx={{ textAlign: "center" }}>Código</SmallText>
              <OTP
                separator={<span> </span>}
                value={code}
                onChange={setCode}
                length={6}
              />
              <BoldSmallText mt={1} textAlign="center">
                Verifica a pasta de spam, caso não recebas nada.
              </BoldSmallText>
              <TextField
                label="Nova password"
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ color: COLORS.black, marginRight: "-5px" }}
                      onClick={() =>
                        handleTogglePassword(
                          setShowNewPassword,
                          showNewPassword
                        )
                      }
                    >
                      {showNewPassword ? (
                        <Visibility sx={{ fontSize: 13 }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: 13 }} />
                      )}
                    </IconButton>
                  ),
                }}
                type={showNewPassword ? "text" : "password"}
                placeholder="***********"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewPassword(e?.target?.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  formReady && handleEnterKeyPressAction(handleSubmission, e);
                }}
              />
              <TextField
                label="Confirmar nova password"
                variant="outlined"
                placeholder="***********"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ color: COLORS.black, marginRight: "-5px" }}
                      onClick={() =>
                        handleTogglePassword(
                          setShowConfirmNewPassword,
                          showConfirmNewPassword
                        )
                      }
                    >
                      {showConfirmNewPassword ? (
                        <Visibility sx={{ fontSize: 13 }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: 13 }} />
                      )}
                    </IconButton>
                  ),
                }}
                type={showConfirmNewPassword ? "text" : "password"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmNewPassword(e?.target?.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  formReady && handleEnterKeyPressAction(handleSubmission, e);
                }}
              />
              <PasswordCheckerBox>
                <PasswordChecker
                  password={newPassword}
                  confirmPassword={confirmNewPassword}
                  setPasswordIsValid={setPasswordIsValid}
                />
              </PasswordCheckerBox>
            </>
          )}
        </InputBox>
        <MainButton
          sx={{ margin: "20px 0" }}
          fullWidth
          disabled={!formReady}
          onClick={handleSubmission}
        >
          <BoldSmallText>Redefinir password</BoldSmallText>
        </MainButton>
      </FormBox>
      {cognitoMessage && (
        <BoldSmallText color={`${COLORS.redTwo} !important`}>
          {cognitoMessage}
        </BoldSmallText>
      )}
      {successMessage && (
        <BoldSmallText color={`${COLORS.greenOne} !important`}>
          {successMessage}
        </BoldSmallText>
      )}
      <MediumText
        color={`${COLORS.grayFour} !important`}
        mt={1}
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        Voltar a{" "}
        <UnderlinedButton onClick={() => setStep("LOG_IN")}>
          iniciar sessão
        </UnderlinedButton>
      </MediumText>
    </AuthMainContent>
  );
}

export default ResetPassword;
