import { Box, styled } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const GameMainContentWrapper = styled(Box)`
  padding: 30px;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  overflow-y: auto;
`;
