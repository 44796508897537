import { styled } from "@mui/material";
import { COLORS } from "../../assets/theme";

export const NewsFooterBox = styled("div")`
  width: 100%;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: ${COLORS.orangeOne};
`;

export const NewsWrapper = styled("div")`
  display: flex;
`;

export const NewsInsideWrapper = styled("div")`
  display: flex;
`;

export const NewsFooterDiv = styled("div")`
  white-space: nowrap;
  display: flex;
  width: 100%;
  height: 100%;
  animation: ticker 35s linear infinite;

  @keyframes ticker {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

export const FooterDiv = styled("div")`
  display: flex;
`;

export const LeftContent = styled("div")`
  display: flex;
  flex-flow: column;
`;
