import PasswordChecklist from "react-password-checklist";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { PasswordCheckerProps } from "../../utils/Interfaces/Account";
import { COLORS } from "../../assets/theme";

function PasswordChecker({
  password,
  confirmPassword,
  setPasswordIsValid,
}: PasswordCheckerProps) {
  const icons = {
    ValidIcon: (
      <CheckRoundedIcon
        style={{
          height: "20px",
          width: "20px",
          marginRight: "4px",
          color: "green",
        }}
      />
    ),
    InvalidIcon: (
      <CloseRoundedIcon
        style={{
          height: "20px",
          width: "20px",
          marginRight: "4px",
          color: COLORS.redTwo,
        }}
      />
    ),
  };

  const translations = {
    capital: "A senha deve conter pelo menos uma letra maiúscula",
    lowercase: "A senha deve conter pelo menos uma letra minúscula",
    minLength: "A senha deve ter pelo menos 8 caracteres",
    number: "A senha deve conter pelo menos um número",
    specialChar: "A senha deve conter pelo menos um caracter especial",
    match: "As senhas devem coincidir",
  };

  return (
    <PasswordChecklist
      rules={[
        "capital",
        "lowercase",
        "minLength",
        "number",
        "specialChar",
        "match",
      ]}
      minLength={8}
      style={{ fontSize: "0.9rem", color: COLORS.graySix }}
      value={password}
      valueAgain={confirmPassword}
      iconComponents={icons}
      messages={translations}
      onChange={(isValid) => setPasswordIsValid(isValid)}
    />
  );
}

export default PasswordChecker;
