import { BoldMediumText, BoldSmallText } from "../../../utils/GlobalStyles";
import {
  Step1Wrapper,
  Step2Wrapper,
  Step3Wrapper,
  Step4Wrapper,
} from "./styles";
import {
  MiniTutorialStepProps,
  WrapperCircleProps,
} from "../../../utils/Interfaces/Global";
import CustomTooltip from "./CustomTooltip";
import { PALETTE } from "../../../assets/theme";
import { useState } from "react";
import { ClickAwayListener } from "@mui/material";

export default function MiniTutorialStep({
  stepsData,
}: {
  stepsData: MiniTutorialStepProps;
}) {
  const { step, stepText, icon: Icon, tooltipData } = stepsData;

  const WrapperCircle = ({ children }: WrapperCircleProps) => {
    const [openStepOne, setOpenStepOne] = useState(false);
    const [openStepTwo, setOpenStepTwo] = useState(false);
    const [openStepThree, setOpenStepThree] = useState(false);
    const [openStepFour, setOpenStepFour] = useState(false);

    const handleOpenToolTip = (step: number) => {
      switch (step) {
        case 1:
          setOpenStepOne(true);
          setOpenStepTwo(false);
          setOpenStepThree(false);
          setOpenStepFour(false);
          break;
        case 2:
          setOpenStepOne(false);
          setOpenStepTwo(true);
          setOpenStepThree(false);
          setOpenStepFour(false);
          break;
        case 3:
          setOpenStepThree(true);
          break;
        case 4:
          setOpenStepFour(true);
          break;
      }
    };

    const handleCloseTooltip = () => {
          setOpenStepOne(false);
          setOpenStepTwo(false);
          setOpenStepThree(false);
          setOpenStepFour(false);
    };

    switch (step) {
      case 1:
      default:
        return (
          <ClickAwayListener onClickAway={handleCloseTooltip}>
          <div
            onMouseOver={() => handleOpenToolTip(1)}
            onMouseLeave={handleCloseTooltip}
          >
            <CustomTooltip data={tooltipData} open={openStepOne}>
              <Step1Wrapper onClick={() => handleOpenToolTip(1)}>
                <Icon
                  style={{
                    width: "30px",
                    height: "30px",
                    color: PALETTE.secondaryOne,
                  }}
                />
                {children}
              </Step1Wrapper>
            </CustomTooltip>
          </div>
          </ClickAwayListener>
        );
      case 2:
        return (
          <ClickAwayListener onClickAway={handleCloseTooltip}>
          <div
            onMouseOver={() => handleOpenToolTip(2)}
            onMouseLeave={handleCloseTooltip}
          >
            <CustomTooltip data={tooltipData} open={openStepTwo}>
              <Step2Wrapper>
                <Icon
                  style={{
                    width: "30px",
                    height: "30px",
                    color: PALETTE.secondaryOne,
                  }}
                />
                {children}
              </Step2Wrapper>
            </CustomTooltip>
          </div>
          </ClickAwayListener>
        );
      case 3:
        return (
          <ClickAwayListener onClickAway={handleCloseTooltip}>
          <div
            onMouseOver={() => handleOpenToolTip(3)}
            onMouseLeave={handleCloseTooltip}
          >
            <CustomTooltip data={tooltipData} open={openStepThree}>
              <Step3Wrapper>
                <Icon
                  style={{
                    width: "30px",
                    height: "30px",
                    color: PALETTE.secondaryOne,
                  }}
                />
                {children}
              </Step3Wrapper>
            </CustomTooltip>
          </div>
          </ClickAwayListener>
        );
      case 4:
        return (
          <ClickAwayListener onClickAway={handleCloseTooltip}>
          <div
            onMouseOver={() => handleOpenToolTip(4)}
            onMouseLeave={handleCloseTooltip}
          >
            <CustomTooltip data={tooltipData} open={openStepFour}>
              <Step4Wrapper>
                <Icon
                  style={{
                    width: "30px",
                    height: "30px",
                    color: PALETTE.secondaryOne,
                  }}
                />
                {children}
              </Step4Wrapper>
            </CustomTooltip>
          </div>
          </ClickAwayListener>
        );
    }
  };

  return (
    <WrapperCircle>
      <BoldMediumText
        sx={{
          textAlign: "center",
          whiteSpace: "wrap !important",
          padding: "0 10px",
        }}
        mt={1}
      >
        {stepText}
      </BoldMediumText>
    </WrapperCircle>
  );
}
