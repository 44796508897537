import { GeneralAppDataContext } from "../../utils/GeneralContext";
import PasswordChecker from "../PasswordChecker";
import { deleteUser, updatePassword } from "aws-amplify/auth";
import {
  DeleteAccountButton,
  NewsletterBox,
  PasswordRightFieldBox,
  PasswordRightFieldWrapper,
  ProfileWrapper,
  RowBox,
  RowRightField,
  RowsWrapper,
} from "./styles";
import {
  BoldSmallText,
  MainButton,
  SmallText,
  MediumText,
  SecondaryButton,
} from "../../utils/GlobalStyles";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DeleteAccountModal from "./DeleteAccountModal";
import {
  checkItem,
  registerToDynamoDB,
  removeFromDynamoDB,
} from "../../utils/Functions/DynamoDBActions";
import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/theme";

function Profile() {
  const { user } = React.useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  const navigate = useNavigate();
  const [formReady, setFormReady] = React.useState(false);
  const [cognitoMessage, setCognitoMessage] = React.useState<
    [string, string] | null
  >(null);
  const [passswordIsValid, setPasswordIsValid] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    React.useState(false);
  const [newsletterValue, setNewsletterValue] = React.useState(0);
  const [newsletterChangedSuccessfully, setNewsletterChangedSuccessfully] =
    React.useState<boolean | null>(false);
  const [deleteAccountConfirmation, setDeleteAccountConfirmation] =
    React.useState(false);

  React.useEffect(() => {
    function checkFields() {
      if (passswordIsValid) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
    checkFields();
  }, [passswordIsValid]);

  React.useEffect(() => {
    const checkItemExists = async () => {
      try {
        const email = user?.email || "";
        const newsletterCurrent = await checkItem(email);
        setNewsletterValue(newsletterCurrent as number);
      } catch (error) {
        console.error("Error checking item existence:", error);
      }
    };

    checkItemExists(); // Call the function when the component mounts
  }, []);

  async function handleChangePassword() {
    await updatePassword({
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .then(() => {
        setCognitoMessage(["success", "Password alterada com sucesso."]);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      })
      .catch((error: { message: string }) => {
        setCognitoMessage([
          "error",
          "Erro ao alterar password. Tenta mais tarde.",
        ]);
      });
  }

  const handleDeleteAccountConfirmation = () =>
    setDeleteAccountConfirmation(!deleteAccountConfirmation);

  const handleDeleteAccount = async () => {
    try {
      await deleteUser().then(async () => {
        const email = user?.email || "";
        await removeFromDynamoDB(email, null).then(() => {
          navigate("/");
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTogglePassword = (
    setter: React.Dispatch<React.SetStateAction<boolean>>,
    value: boolean,
  ) => setter(!value);

  const handleChangeNewsletterDecision = (e: SelectChangeEvent<number>) => {
    setNewsletterValue(e?.target?.value as number);
  };

  const handleSaveNewsletterDecision = () => {
    const email = user?.email || "";
    if (newsletterValue === 0) {
      removeFromDynamoDB(email, setNewsletterChangedSuccessfully);
    } else if (newsletterValue === 1) {
      registerToDynamoDB(email, setNewsletterChangedSuccessfully);
    }
  };

  return (
    <React.Fragment>
      <ProfileWrapper>
        <MediumText px={{ xs: 2, lg: 3 }} py={2.5}>
          Informações da conta
        </MediumText>
        <RowsWrapper>
          <RowBox>
            <SmallText>Primeiro nome</SmallText>
            <RowRightField>
              <SmallText>{user?.firstName}</SmallText>
            </RowRightField>
          </RowBox>
          <RowBox>
            <SmallText>Último nome</SmallText>
            <RowRightField>
              <SmallText>{user?.lastName}</SmallText>
            </RowRightField>
          </RowBox>
          <RowBox>
            <SmallText>Email</SmallText>
            <RowRightField>
              <SmallText>{user?.email}</SmallText>
            </RowRightField>
          </RowBox>
          <RowBox>
            <SmallText>Alterar password</SmallText>
            <PasswordRightFieldWrapper>
              <PasswordRightFieldBox>
                <TextField
                  sx={{ width: 200 }}
                  label="Password antiga"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontSize: 13,
                    },
                  }}
                  InputProps={{
                    sx: { fontSize: 13 },
                    endAdornment: (
                      <IconButton
                        sx={{ color: COLORS.black, marginRight: "-10px" }}
                        onClick={() =>
                          handleTogglePassword(
                            setShowOldPassword,
                            showOldPassword,
                          )
                        }
                      >
                        {showOldPassword ? (
                          <Visibility sx={{ fontSize: 13 }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: 13 }} />
                        )}
                      </IconButton>
                    ),
                  }}
                  type={showOldPassword ? "text" : "password"}
                  placeholder="***********"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setOldPassword(e?.target?.value)
                  }
                />
              </PasswordRightFieldBox>
              <PasswordRightFieldBox>
                <TextField
                  sx={{ width: 200 }}
                  label="Nova password"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontSize: 13,
                    },
                  }}
                  InputProps={{
                    sx: { fontSize: 13 },
                    endAdornment: (
                      <IconButton
                        sx={{ color: COLORS.black, marginRight: "-10px" }}
                        onClick={() =>
                          handleTogglePassword(
                            setShowNewPassword,
                            showNewPassword,
                          )
                        }
                      >
                        {showNewPassword ? (
                          <Visibility sx={{ fontSize: 13 }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: 13 }} />
                        )}
                      </IconButton>
                    ),
                  }}
                  type={showNewPassword ? "text" : "password"}
                  placeholder="***********"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewPassword(e?.target?.value)
                  }
                />
              </PasswordRightFieldBox>
              <PasswordRightFieldBox>
                <TextField
                  sx={{ width: 200 }}
                  label="Confirmar nova password"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontSize: 13,
                    },
                  }}
                  InputProps={{
                    sx: { fontSize: 13 },
                    endAdornment: (
                      <IconButton
                        sx={{ color: COLORS.black, marginRight: "-10px" }}
                        onClick={() =>
                          handleTogglePassword(
                            setShowConfirmNewPassword,
                            showConfirmNewPassword,
                          )
                        }
                      >
                        {showConfirmNewPassword ? (
                          <Visibility sx={{ fontSize: 13 }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: 13 }} />
                        )}
                      </IconButton>
                    ),
                  }}
                  type={showConfirmNewPassword ? "text" : "password"}
                  placeholder="***********"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setConfirmNewPassword(e?.target?.value)
                  }
                />
              </PasswordRightFieldBox>
              <PasswordRightFieldBox>
                <PasswordChecker
                  password={newPassword}
                  confirmPassword={confirmNewPassword}
                  setPasswordIsValid={setPasswordIsValid}
                />
              </PasswordRightFieldBox>
              <MainButton onClick={handleChangePassword}>
                <SmallText>Alterar</SmallText>
              </MainButton>
              {cognitoMessage && (
                <BoldSmallText mt={2} color={`${COLORS.redTwo} !important`}>
                  {cognitoMessage[1]}
                </BoldSmallText>
              )}
            </PasswordRightFieldWrapper>
          </RowBox>
          <RowBox>
            <NewsletterBox>
              <FormControl sx={{ width: 150 }}>
                <InputLabel>Newsletter</InputLabel>
                <Select
                  sx={{ height: "45px" }}
                  value={newsletterValue}
                  label="Newsletter"
                  onChange={handleChangeNewsletterDecision}
                >
                  <MenuItem value={1}>Ativado</MenuItem>
                  <MenuItem value={0}>Desativado</MenuItem>
                </Select>
              </FormControl>
              <SecondaryButton
                sx={{ height: "45px" }}
                onClick={handleSaveNewsletterDecision}
              >
                <SmallText color={`${COLORS.white} !important`}>
                  Guardar
                </SmallText>
              </SecondaryButton>
              {newsletterChangedSuccessfully && (
                <BoldSmallText noWrap color={`${COLORS.greenOne} !important`}>
                  Atualizado com sucesso
                </BoldSmallText>
              )}
            </NewsletterBox>
          </RowBox>
          <RowBox>
            <DeleteAccountButton onClick={handleDeleteAccountConfirmation}>
              <SmallText>Apagar conta</SmallText>
            </DeleteAccountButton>
          </RowBox>
        </RowsWrapper>
      </ProfileWrapper>
      <DeleteAccountModal
        modalOpen={deleteAccountConfirmation}
        handleModalOpen={handleDeleteAccountConfirmation}
        handleDeleteAccount={handleDeleteAccount}
      />
    </React.Fragment>
  );
}

export default Profile;
