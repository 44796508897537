import { Box, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const InvitedUserFormWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
  flex: 1;
`;

export const InvitedUserFormBox = styled(Box)`
  margin: 100px 0 100px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const FormBox = styled(Box)`
  display: flex;
  height: 40px;
  gap: 5px;
  max-width: 1200px;
  min-width: 300px;
  width: 40vw;
`;

export const InviteHeaderFooterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 80px;
  width: 100%;
  background-color: ${PALETTE.primaryTwo};
`;
