import { Box, styled } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const GameLandingPageWrapper = styled(Box)`
  display: flex;
  padding: 20px 20px 60px 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const LetterBox = styled(Box)`
  margin-top: 20px;
  padding: 40px 0;
  background-color: ${COLORS.grayOne};
  border-radius: 5px;
  max-width: 900px;
`;

export const LetterTextBox = styled(Box)`
  margin: 0 70px 40px 70px;
`;

export const LetterUpperSection = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReceiverBox = styled(Box)`
  text-align: right;
`;
