import { Box, styled } from "@mui/material";

export const AccountContentWrapper = styled("section")`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
`;

export const AccountContentBox = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  overflow-wrap: break-word;
`;
