import { Box, Button, Divider, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../assets/theme";

export const AccountTopMenu = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 20px;
  border-radius: 8px;
  padding: 24px 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${COLORS.white};
  gap: 20px;

  @media (min-width: 950px) {
    align-items: center;
    flex-direction: row;
    gap: 30px;
  }
`;

export const LoggedUserInitialsBox = styled(Box)`
  display: inline-flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  border-width: 2px;
  border-color: ${COLORS.grayFive};
  width: 60px;
  height: 60px;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: ${COLORS.graySix};
  background-color: ${COLORS.grayOne};

  &:hover {
    background-color: ${COLORS.grayThree};
  }

  @media (min-width: 950px) {
    margin-left: 8px;
  }
`;

export const UserInformationBox = styled(Box)`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`;

export const DividerSection = styled(Divider)`
  border-color: ${COLORS.grayTwo};
`;

export const UserActionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

export const MenuBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: fit-content;

  @media (min-width: 950px) {
    flex-direction: row;
    gap: 15px;
  }
`;

export const MenuButton = styled(Button)<{ tab: number; index: number }>`
  color: ${COLORS.white};
  gap: 10px;
  background-color: ${(props) =>
    props.tab === props.index ? PALETTE.secondaryFour : PALETTE.secondaryTwo};
  padding: 8px 20px;
  text-transform: none;
  border-radius: 6px;

  &:hover {
    background-color: ${(props) =>
      props.tab === props.index ? PALETTE.secondaryFour : COLORS.blueFive};
  }
`;

export const AddCaseCodeButton = styled("button")`
  width: fit-content;
  float: right;
  &:hover {
    text-decoration: underline;
  }
`;
