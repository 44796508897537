import {
  BoldLargeText,
  BoldSmallText,
  MainButton,
  NoBackgroundButton,
} from "../../../../utils/GlobalStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  SolveMyCaseType,
  SuspectType,
} from "../../../../utils/Interfaces/Game";
import ConfirmSolveCaseModal from "./ConfirmSolveCaseModal";
import { Transition } from "../../../../utils/Transitions";
import React from "react";
import { COLORS } from "../../../../assets/theme";

export default function SolveCaseModal({
  suspects,
  modalOpen,
  confirmModalOpen,
  handleModalOpen,
  handleConfirmModalOpen,
  handleReportSubmitted,
}: SolveMyCaseType) {
  const [selectedVeridict, setSelectedVeridict] =
    React.useState<SuspectType | null>(null);

  React.useEffect(() => {
    setSelectedVeridict(null);
  }, [modalOpen]);

  const handleSelectedVeridict = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let suspect = suspects.find(
      (suspect) => suspect.name === event.target.value,
    );
    setSelectedVeridict(suspect as SuspectType);
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={modalOpen}
        onClose={handleModalOpen}
        fullWidth
      >
        <DialogTitle my={"10px"}>
          <BoldLargeText>Descobriste o culpado?</BoldLargeText>
        </DialogTitle>
        <DialogContent>
          <RadioGroup>
            {suspects?.map((suspect, index) => (
              <FormControlLabel
                key={index}
                value={suspect.name}
                control={
                  <Radio
                    onChange={handleSelectedVeridict}
                    checked={selectedVeridict === suspect}
                  />
                }
                label={suspect.name}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions style={{ gap: "10px", margin: "20px" }}>
          <NoBackgroundButton onClick={handleModalOpen}>
            <BoldSmallText color={`${COLORS.redTwo} !important`}>
              Cancelar
            </BoldSmallText>
          </NoBackgroundButton>
          <MainButton onClick={handleConfirmModalOpen}>
            <BoldSmallText color={`${COLORS.white} !important`}>
              Submeter
            </BoldSmallText>
          </MainButton>
        </DialogActions>
      </Dialog>
      <ConfirmSolveCaseModal
        open={confirmModalOpen}
        handleConfirmModalOpen={handleConfirmModalOpen}
        handleReportSubmitted={handleReportSubmitted}
        selectedVeridict={selectedVeridict}
      />
    </>
  );
}
