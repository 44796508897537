import { Box, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../assets/theme";

export const PromotedCaseWrapper = styled(Box)`
  max-width: 1200px;
  margin: 0 auto 40px auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 70px;

  @media (min-width: 1024px) {
    flex-flow: row;
  }
`;

export const ContentBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const BoardImage = styled("img")`
  object-fit: contain;
  border: 8px solid ${PALETTE.secondaryFour};
  opacity: 0.9;
  object-position: top;
  border-radius: 6px;
  height: auto;
`;
