import {
  AuthorBox,
  NavigationBox,
  NavigationSpan,
  ReviewBox,
  ReviewsBox,
  ReviewsWrapper,
} from "./styles";
import {
  MediumText,
  SmallText,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import { Carousel } from "@material-tailwind/react";

function Reviews() {
  return (
    <ReviewsWrapper>
      <TitleMediumText textAlign="center">Reviews</TitleMediumText>
      <Carousel
        autoplay
        autoplayDelay={11000}
        loop
        placeholder=""
        transition={{ duration: 1 }}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <NavigationBox>
            {new Array(length).fill("").map((_, i) => (
              <NavigationSpan
                key={i}
                style={{
                  backgroundColor: activeIndex === i ? "black" : "gray",
                }}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </NavigationBox>
        )}
      >
        <ReviewsBox>
          <ReviewBox alignStart>
            <MediumText>
              "Entretenimento incrível para raciocínio, conversa e passar uma
              boa tarde com amigos. Já estamos a pensar no próximo!"
            </MediumText>
            <AuthorBox>
              <SmallText>Filipa Mendes. 25/06/2024</SmallText>
            </AuthorBox>
          </ReviewBox>
          <ReviewBox>
            <MediumText>
              "Uma montanha russa de emoções! Um desafio muito bom, que
              transformou uma simples tarde de domingo num momento de
              detetives!"
            </MediumText>
            <AuthorBox>
              <SmallText>Gabriel Carrolo. 27/06/2024</SmallText>
            </AuthorBox>
          </ReviewBox>
        </ReviewsBox>
        <ReviewsBox>
          <ReviewBox alignStart>
            <MediumText>
              "Este jogo é a opção perfeita para uma noite diferente mas muito
              divertida com amigos ou família (e muitos detetives)! Certamente
              vamos repetir!!"
            </MediumText>
            <AuthorBox>
              <SmallText>Ana Silva. 28/06/2024</SmallText>
            </AuthorBox>
          </ReviewBox>
          <ReviewBox>
            <MediumText>
              Se gostas de um bom desafio entre amigos, este é o jogo ideal para ti! Mal podemos esperar que lancem novos casos!!"
            </MediumText>
            <AuthorBox>
              <SmallText>Teresa Ferreira. 29/06/2024</SmallText>
            </AuthorBox>
          </ReviewBox>
        </ReviewsBox>
      </Carousel>
    </ReviewsWrapper>
  );
}

export default Reviews;
