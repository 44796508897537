import {
  useState,
  useContext,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { resendSignUpCode, signIn } from "aws-amplify/auth";
import validator from "validator";
import {
  AuthenticationContext,
  GeneralAppDataContext,
} from "../../../../utils/GeneralContext";
import { handleEnterKeyPressAction } from "../../../../utils/HelpFunctions";
import {
  BoldSmallText,
  MainButton,
  MediumText,
  TitleMediumText,
  SmallText,
  UnderlinedButton,
} from "../../../../utils/GlobalStyles";
import { IconButton, TextField } from "@mui/material";
import {
  AuthMainContent,
  ForgotPasswordBox,
  FormBox,
  InputBox,
} from "../styles";
import {
  AuthenticationContextType,
  GeneralAppDataContextType,
} from "../../../../utils/Interfaces/Global";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS } from "../../../../assets/theme";
import { generatePortugueseError } from "../../../../utils/Functions/CognitoErrorTranslator";

function SignIn() {
  const { setIsAuthenticated, setIsLoading } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const { setUsername, setStep, cognitoMessage, setCognitoMessage } =
    useContext(AuthenticationContext) as AuthenticationContextType;
  const [formReady, setFormReady] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    function checkFields() {
      if (validator.isEmail(email) && password !== "") {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
    checkFields();
  }, [email, password]);

  async function handleLogin(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ) {
    e.preventDefault();
    if (formReady) {
      setIsLoading(true);
      await signIn({ username: email, password: password })
        .then(async ({ isSignedIn, nextStep }) => {
          if (isSignedIn) {
            setIsAuthenticated(true);
          } else {
            if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
              setUsername(email);
              resendSignUpCode({ username: email }).then(() => {
                setStep("CONFIRM_SIGN_UP");
              });
            }
          }
          setIsLoading(false);
        })
        .catch((error: Error) => {
          console.log(error);
          setCognitoMessage(generatePortugueseError(error));
          setIsLoading(false);
        });
    }
  }

  const handleTogglePassword = (
    setter: Dispatch<SetStateAction<boolean>>,
    value: boolean,
  ) => setter(!value);

  return (
    <AuthMainContent>
      <TitleMediumText>Bem-vindo detetive!</TitleMediumText>
      <MediumText mt={1} mb={3}>
        Insere o teu email e password para iniciar sessão.
      </MediumText>
      <FormBox>
        <InputBox>
          <TextField
            sx={{ backgroundColor: "white" }}
            label="Email"
            variant="outlined"
            autoComplete="email"
            placeholder="name@mail.com"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleLogin, e);
            }}
          />
          <TextField
            label="Password"
            autoComplete="current-password"
            variant="outlined"
            sx={{ backgroundColor: "white" }}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ color: COLORS.black, marginRight: "-5px" }}
                  onClick={() =>
                    handleTogglePassword(setShowPassword, showPassword)
                  }
                >
                  {showPassword ? (
                    <Visibility sx={{ fontSize: 13 }} />
                  ) : (
                    <VisibilityOff sx={{ fontSize: 13 }} />
                  )}
                </IconButton>
              ),
            }}
            placeholder="***********"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e?.target?.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              formReady && handleEnterKeyPressAction(handleLogin, e);
            }}
          />
        </InputBox>
        <ForgotPasswordBox>
          <UnderlinedButton onClick={() => setStep("FORGOT_PASSWORD")}>
            <SmallText>Esqueceste-te da password?</SmallText>
          </UnderlinedButton>
        </ForgotPasswordBox>
        <MainButton
          sx={{ margin: "20px 0" }}
          fullWidth
          disabled={!formReady}
          onClick={handleLogin}
        >
          <BoldSmallText>Iniciar sessão</BoldSmallText>
        </MainButton>
      </FormBox>
      {cognitoMessage && (
        <BoldSmallText color={`${COLORS.redTwo} !important`}>
          {cognitoMessage}
        </BoldSmallText>
      )}
      <MediumText
        color={`${COLORS.grayFour} !important`}
        mt={1}
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        Ainda não tens conta?{" "}
        <UnderlinedButton onClick={() => setStep("SIGN_UP")}>
          Criar uma conta
        </UnderlinedButton>
      </MediumText>
    </AuthMainContent>
  );
}

export default SignIn;
