import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { BoldSmallText, SmallText } from "../../../../utils/GlobalStyles";
import {
  CustomizeTooltipProps,
  MiniTutorialStepTooltipDataProps,
} from "../../../../utils/Interfaces/Global";
import {
  TooltipStepBox,
  TooltipStepIconBox,
  TooltipStepIconCircle,
  TooltipStepTextBox,
  TooltipStepsBox,
  VerticalLine,
} from "./styles";
import parse from "html-react-parser";
import { COLORS, PALETTE } from "../../../../assets/theme";

export default function CustomTooltip({
  data,
  children,
  open
}: CustomizeTooltipProps) {
  const CustomizeTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} open={open} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: PALETTE.primaryOne,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: PALETTE.primaryOne,
      padding: "20px",
      borderRadius: "6px",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
  }));

  const TooltipSteps = ({
    data,
  }: {
    data: MiniTutorialStepTooltipDataProps[];
  }) => {
    return (
      <TooltipStepsBox>
        {data.map((tooltipStep: MiniTutorialStepTooltipDataProps) => (
          <TooltipStepBox>
            <TooltipStepIconBox>
              <TooltipStepIconCircle>
                <tooltipStep.icon
                  style={{ width: "20px", height: "20px", color: COLORS.white }}
                />
              </TooltipStepIconCircle>
              <VerticalLine orientation="vertical" />
            </TooltipStepIconBox>
            <TooltipStepTextBox>
              <BoldSmallText>{tooltipStep.text}</BoldSmallText>
              <SmallText>{parse(tooltipStep.description)}</SmallText>
            </TooltipStepTextBox>
          </TooltipStepBox>
        ))}
      </TooltipStepsBox>
    );
  };

  return (
    <CustomizeTooltip title={<TooltipSteps data={data} />} arrow>
      {children}
    </CustomizeTooltip>
  );
}
