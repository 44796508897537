import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
import { COLORS, PALETTE } from "../../assets/theme";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { WarningSliderProps } from "../../utils/Interfaces/Global";
import { useEffect } from "react";
import { BoldSmallText } from "../../utils/GlobalStyles";

// Function to create the slide transition
function SlideTransition(props: any) {
  return <Slide {...props} direction="down" />;
}

type Severity = "error" | "warning" | "info" | "success";

export const isSeverity = (value: any): value is Severity => {
  return ["error", "warning", "info", "success"].includes(value);
};

function WarningSlider({
  warningMessage,
  setWarningMessage,
  openWarning,
  setOpenWarning,
}: WarningSliderProps) {
  useEffect(() => {
    if (warningMessage) {
      setOpenWarning(true);
    }
  }, [warningMessage]);

  const handleClose = () => {
    setOpenWarning(false);
    setWarningMessage(null);
  };

  return (
    warningMessage && (
      <Snackbar
        open={openWarning}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        autoHideDuration={4500}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="outlined"
          sx={{
            bgcolor: PALETTE.secondaryTwo,
            display: "flex",
            alignItems: "center",
          }}
          action={
            <IconButton
              onClick={() => {
                setWarningMessage(null);
              }}
            >
              <CloseRoundedIcon
                sx={{ width: "20px", height: "20px", color: COLORS.white }}
              />
            </IconButton>
          }
          severity={isSeverity(warningMessage[0]) ? warningMessage[0] : "info"}
          onClose={() => {
            setWarningMessage(null);
          }}
        >
          <BoldSmallText color={`${COLORS.white} !important`}>
            {warningMessage[1]}
          </BoldSmallText>
        </Alert>
      </Snackbar>
    )
  );
}

export default WarningSlider;
