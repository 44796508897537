import { Box, Button, styled } from "@mui/material";

export const HowToGetStartedWrapper = styled(Box)`
  max-width: 1200px;
  margin: 60px auto 20px auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 30px;
`;

export const HowToGetStartedBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
