import { GameMainContentProps } from "../../../../utils/Interfaces/Game";
import FileItem from "./FileItem";
import { GameMainContentWrapper } from "./styles";

function GameMainContent({
  bucketUrl,
  files,
  activeFolder,
}: GameMainContentProps) {
  return (
    <GameMainContentWrapper>
      {files[activeFolder]?.map((file: string) => (
        <FileItem
          bucketUrl={bucketUrl}
          file={file}
          activeFolder={activeFolder}
        />
      ))}
    </GameMainContentWrapper>
  );
}

export default GameMainContent;
