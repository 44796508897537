import { Box, styled } from "@mui/material";

export const CasesToPurchaseWrapper = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
`;

export const CasesBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 50px auto 40px auto;
  gap: 90px;
  justify-content: center;

  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
  }
`;
