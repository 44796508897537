import { calculate_cart_total_amount } from "../../utils/HelpFunctions";
import {
  CartType,
  AddCartItemProps,
  CartItemType,
  RemoveCartItemProps,
  GroupedCartItemType,
} from "../../utils/Interfaces/Cart";
import { v4 as uuidv4 } from "uuid";

export const addItemToCart = async ({
  user,
  mutation,
  setCart,
  item,
}: AddCartItemProps) => {
  if (user) {
    try {
      await mutation.mutateAsync({
        params: { userId: user?.id!, caseId: item.caseId.toString() },
        body: {},
      });
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  } else {
    const cartItem: CartItemType = {
      id: uuidv4(),
      caseId: item.caseId,
      name: item.name,
      imageUrl: item.imageUrl,
      priceId: item.priceId,
      price: "unitPrice" in item ? item.unitPrice : item.price,
      discount: "discount" in item ? item.discount : item.unitDiscount,
      difficulty: item.difficulty,
      teaserSummary: item.teaserSummary,
      path: item.path,
    };

    const cartFromLocalStorage = localStorage.getItem("cart");
    const cartItems: CartType = cartFromLocalStorage
      ? JSON.parse(cartFromLocalStorage)
      : { totalAmount: 0, items: [] };
    cartItems.items?.push(cartItem);
    cartItems.totalAmount = calculate_cart_total_amount(cartItems.items);
    setCart(cartItems);
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }
};

export const removeItemFromCart = async ({
  user,
  mutation,
  setCart,
  groupedItem,
}: RemoveCartItemProps) => {
  if (user && groupedItem.ids[0]) {
    try {
      await mutation.mutateAsync({
        userId: user?.id!,
        itemId: groupedItem.ids[0]!,
      });
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  } else {
    const cartFromLocalStorage = localStorage.getItem("cart");
    if (cartFromLocalStorage) {
      const cartItems = JSON.parse(cartFromLocalStorage);
      const updatedItems: CartItemType[] = cartItems.items.filter(
        (cartItem: CartItemType) => cartItem.id !== groupedItem.ids[0],
      );
      const updatedTotalAmount = calculate_cart_total_amount(updatedItems);

      const updatedCart: CartType = {
        totalAmount: updatedTotalAmount,
        items: updatedItems,
      };

      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  }
};

export function groupCartByItem(cart: CartItemType[]) {
  const grouped: GroupedCartItemType[] = cart.reduce(
    (accumulator: GroupedCartItemType[], currentItem: CartItemType) => {
      const existingItem = accumulator.find(
        (item) => item.caseId === currentItem.caseId,
      );
      if (existingItem) {
        existingItem.quantity += 1;
        existingItem.ids.push(currentItem.id);
        const newAmount =
          Math.round(
            (Number(existingItem.amount) +
              Math.round(currentItem.price * 100) / 100) *
              100,
          ) / 100;
        existingItem.amount = newAmount;
      } else {
        accumulator.push({
          ids: [currentItem.id],
          caseId: currentItem.caseId,
          imageUrl: currentItem.imageUrl,
          name: currentItem.name,
          teaserSummary: currentItem.teaserSummary,
          difficulty: currentItem.difficulty,
          priceId: currentItem.priceId,
          unitPrice: currentItem.price,
          unitDiscount: currentItem.discount,
          quantity: 1,
          amount: currentItem.price,
          path: currentItem.path,
        });
      }
      return accumulator;
    },
    [],
  );
  return grouped;
}
