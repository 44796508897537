import {
  BoldMediumText,
  MediumText,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import { PageHeaderBox } from "./styles";
import { PageHeaderProps } from "../../utils/Interfaces/Global";
import { COLORS } from "../../assets/theme";

export default function PageHeader({
  section,
  heading,
  content,
}: PageHeaderProps) {
  return (
    <PageHeaderBox>
      <BoldMediumText>{section}</BoldMediumText>
      <TitleMediumText>{heading}</TitleMediumText>
      <MediumText mt={1} color={`${COLORS.grayFour} !important`}>
        {" "}
        {content}
      </MediumText>
    </PageHeaderBox>
  );
}
