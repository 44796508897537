import { Box, styled } from "@mui/material";

export const DialogContentWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
`;

export const AddCaseCodeInputWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  height: 40px;
  width: fit-content;
`;
