import { Link } from "@mui/material";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
  TitleSmallText,
} from "../../utils/GlobalStyles";
import { TermsAndConditionWrapper } from "./styles";

function TermsAndConditions() {
  return (
    <TermsAndConditionWrapper>
      <TitleSmallText mb={1} textAlign="center">
        {" "}
        Termos e Condições de Utilização{" "}
      </TitleSmallText>
      <br />
      <br />
      <MediumText>
        Bem-vindo ao site dos DESARQUIVADOS ("Empresa"). Por favor, leia estes
        Termos e Condições de Utilização ("Termos") cuidadosamente antes de
        utilizar o nosso site www.desarquivados.com ("Site"). Ao aceder ou
        utilizar o Site, concorda em estar vinculado a estes Termos. Se não
        concorda com estes Termos, por favor, não utilize o Site.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>1. Conta de Utilizador</BoldLargeText>
      <br />
      <BoldMediumText>1.1 Registo</BoldMediumText>
      <MediumText>
        Para aceder a determinados serviços e funcionalidades do Site, deverá
        criar uma conta, fornecendo informações precisas, completas e
        atualizadas. É responsável por manter a confidencialidade da sua senha e
        por todas as atividades que ocorram na sua conta.
      </MediumText>
      <br />
      <BoldMediumText>1.2 Informações da Conta</BoldMediumText>
      <MediumText>
        Durante o processo de criação da conta, recolhemos dados pessoais, como
        nome, endereço de e-mail, e data de nascimento. A forma como utilizamos
        esses dados é detalhada na nossa Política de Privacidade.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>2. Compra de Casos</BoldLargeText>
      <br />
      <BoldMediumText>2.1 Processo de Compra</BoldMediumText>{" "}
      <MediumText>
        Ao adquirir um caso no Site, concorda em fornecer informações de
        pagamento válidas e precisas. Todas as compras estão sujeitas à nossa
        aceitação, e podemos, a nosso critério, recusar ou cancelar uma compra.
      </MediumText>
      <br />
      <BoldMediumText>2.2 Preços e Pagamentos</BoldMediumText>{" "}
      <MediumText>
        Todos os preços estão listados em Euros (€) e estão sujeitos a
        alterações sem aviso prévio. Os pagamentos devem ser efetuados através
        dos métodos de pagamento disponibilizados no Site.
      </MediumText>
      <br />
      <BoldMediumText>2.3 Entrega dos Casos</BoldMediumText>{" "}
      <MediumText>
        Após a confirmação do pagamento, receberá acesso ao(s) caso(s)
        comprado(s) automaticamente conforme descrito na página do produto.
      </MediumText>
      <br />
      <BoldMediumText>2.4 Política de Reembolso</BoldMediumText>{" "}
      <MediumText>
        Os reembolsos são tratados conforme a nossa Política de Reembolso.
      </MediumText>
      <br />
      <MediumText>
        <strong>2.4.1 </strong>São aceites devoluções no prazo de 14 dias após a
        compra.{" "}
      </MediumText>
      <MediumText mt={1}>
        <strong>2.4.2</strong>Apenas serão aceites as devoluções de casos não
        utilizados, i.e. casos não ativos. Após a ativação considera-se que o
        produto foi utilizado.{" "}
      </MediumText>
      <MediumText mt={1}>
        <strong>2.4.3 </strong>Para efetuar um pedido de reembolso deverá enviar
        um pedido de contacto <Link href="/contact-us">aqui</Link> ou através do
        email{" "}
        <Link href="mailto: info@desarquivados.com">
          info@desarquivados.com
        </Link>{" "}
        - deverá especificar o email da sua conta e o caso comprado que pretende
        devolver.{" "}
      </MediumText>
      <MediumText mt={1}>
        <strong>2.4.4 </strong>Responderemos ao seu pedido de reembolso no prazo
        de 5 dias úteis e depois de aprovado o prazo de pagamento será,
        dependendo do banco, num prazo de 10-15 dias úteis.{" "}
      </MediumText>
      <br />
      <br />
      <BoldLargeText>3. Utilização do Site</BoldLargeText>
      <br />
      <BoldMediumText>3.1 Conduta do Utilizador</BoldMediumText>
      <MediumText>
        Concorda em utilizar o Site apenas para fins legais e de acordo com
        estes Termos. Não deve usar o Site de maneira que possa danificar,
        desativar, sobrecarregar ou prejudicar qualquer servidor ou rede da
        Empresa.
      </MediumText>
      <br />
      <BoldMediumText>3.2 Propriedade Intelectual</BoldMediumText>
      <MediumText>
        Todo o conteúdo disponível no Site, incluindo, mas não se limitando a,
        texto, gráficos, logótipos, ícones de botão, imagens, clipes de áudio e
        software, é propriedade da Empresa ou dos seus fornecedores de conteúdo
        e está protegido por leis de direitos de autor.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>4. Privacidade</BoldLargeText>
      <MediumText>
        A recolha e utilização de dados pessoais são regidas pela nossa Política
        de Privacidade, que faz parte integrante destes Termos. Ao utilizar o
        Site, consente na recolha e utilização das suas informações conforme
        descrito na nossa Política de Privacidade.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>5. Limitação de Responsabilidade</BoldLargeText>
      <MediumText>
        A Empresa não será responsável por quaisquer danos diretos, indiretos,
        incidentais, consequenciais ou punitivos decorrentes do seu acesso ou
        utilização do Site.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>6. Modificações dos Termos</BoldLargeText>
      <MediumText>
        Reservamo-nos o direito de modificar estes Termos a qualquer momento.
        Quaisquer alterações entrarão em vigor imediatamente após a publicação
        no Site. A utilização continuada do Site após tais modificações
        constitui a sua aceitação dos novos Termos.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>7. Lei Aplicável</BoldLargeText>
      <MediumText>
        Estes Termos são regidos e interpretados de acordo com as leis de
        Portugal, sem considerar os conflitos de princípios legais. Concorda em
        submeter-se à jurisdição exclusiva dos tribunais localizados em Lisboa.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>8. Contacto</BoldLargeText>
      <MediumText>
        Se tiver quaisquer perguntas ou preocupações sobre estes Termos, por
        favor entre em contacto connosco através de{" "}
        <Link href="mailto: info@desarquivados.com">
          info@desarquivados.com
        </Link>{" "}
        ou pelo <Link href="/contact-us">formulário de contacto</Link>.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>9. Disposições Gerais</BoldLargeText>
      <br />
      <BoldMediumText>9.1 Acordo Completo</BoldMediumText>
      <MediumText>
        Estes Termos constituem o acordo completo entre si e a Empresa em
        relação à utilização do Site e substituem todos os acordos anteriores.
      </MediumText>
      <br />
      <BoldMediumText>9.2 Separabilidade</BoldMediumText>
      <MediumText>
        Se qualquer disposição destes Termos for considerada inválida ou
        inexequível, as disposições restantes continuarão em pleno vigor e
        efeito.
      </MediumText>
      <br />
      <BoldMediumText>9.3 Renúncia</BoldMediumText>
      <MediumText>
        A falha da Empresa em exercer ou fazer cumprir qualquer direito ou
        disposição destes Termos não constituirá uma renúncia a tal direito ou
        disposição.
      </MediumText>
      <MediumText>
        Ao usar o nosso Site, reconhece que leu, entendeu e concorda em estar
        vinculado a estes Termos e Condições.
      </MediumText>
    </TermsAndConditionWrapper>
  );
}

export default TermsAndConditions;
