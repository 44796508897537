import { ClimbingBoxLoader } from "react-spinners";
import { PALETTE } from "../../../assets/theme";
import { SmallLoaderDiv } from "./styles";

function SmallLoader() {
  return (
    <SmallLoaderDiv>
      <ClimbingBoxLoader speedMultiplier={1.3} color={PALETTE.secondaryTwo} />
    </SmallLoaderDiv>
  );
}

export default SmallLoader;
