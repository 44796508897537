import { Box, Button, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../assets/theme";

export const ContactUsWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const FormBox = styled("form")`
  max-width: 600px;
  display: flex;
  flex-flow: column;
  gap: 15px;
  width: 100%;
  margin-top: 30px;
`;
