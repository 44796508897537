import { Box, Typography, styled } from "@mui/material";
import { PALETTE, COLORS } from "../../assets/theme";

export const ShoppingCartIconNavbarWrapper = styled(Box)`
  position: relative;
  margin-left: 8px;
  color: ${COLORS.black};

  &:hover {
    color: ${PALETTE.secondaryFour} !important;
  }
`;

export const NumberItems = styled(Typography)`
  position: absolute;
  font-weight: 600;
  top: -12px;
  right: -8px;
  font-size: 0.875rem;
`;
