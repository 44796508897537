import { Box, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../assets/theme";

export const StepCardWrapper = styled(Box)`
  border-radius: 0.5rem;
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  padding: 24px;
  border-width: 2px;
  border-color: ${PALETTE.primaryTwo};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const StepNumberBox = styled(Box)`
  position: relative;
  width: 60px;
  height: 60px;
`;

export const StepNumberTextBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
`;
