const beatriz = require("../../assets/images/team/beatriz.jpg");
const tomas = require("../../assets/images/team/tomas.jpg");
const maria = require("../../assets/images/team/maria.jpg");
const enrique = require("../../assets/images/team/enrique.jpg");
const rogerio = require("../../assets/images/team/rogerio.jpg");
const matilde = require("../../assets/images/team/matilde.jpg");

const teamData = [
  {
    img: beatriz,
    name: "Beatriz",
    position: "Comunicação",
    description:
      "A voz dela provavelmente já ouviste e as suas palavras também já leste. A Beatriz é a nossa atriz e escritora que ajuda os casos a ganharem vida! A Beatriz está um pouco envergonhada porque é ela que te escreve isto e fala na terceira pessoa (o que vamos admitir, é estranho). Mas o que é importante para ela é que te rias e divirtas enquanto ajudas a DESARQUIVAR os casos mais difíceis de Portugal!",
  },
  {
    img: enrique,
    name: "Enrique",
    position: "Operações",
    description:
      "Toda a criatividade só funciona quando alguém trata da logística e se não tivéssemos o Enrique esta operação provavelmente nunca sairia das gavetas poeirentas da Polícia de Portugal. Ele trabalha nos casos para ter a certeza que mais tarde ou mais cedo é possível que os melhores detetives do País consigam encontrar os criminosos.",
  },
  {
    img: maria,
    name: "Maria",
    position: "Marketing",
    description:
      "A Maria é a mãe do grupo. Sabe sempre o que está a acontecer e coordena todos os membros da equipa (e nós às vezes somos difíceis de coordenar!). Uma pro em marketing e uma máquina de comunicação, a Maria é a melhor a fazer com que os nossos desafios cheguem até às mãos das pessoas capazes de os resolver: as tuas!",
  },
  {
    img: matilde,
    name: "Matilde",
    position: "Branding",
    description:
      "Gostas da maneira como todos os nossos casos são super realistas e imersivos? Pois é à Matilde que tens de agradecer. A nossa designer trabalha incansavelmente para que todas as pistas que chegam até ti sejam claras, entusiasmantes e realistas. O mundo dos DESARQUIVADOS está nos detalhes por isso da próxima vez que estiveres a resolver um caso presta muito atenção porque a Matilde adora deixar pistas por aí...",
  },
  {
    img: rogerio,
    name: "Rogério",
    position: "Finanças",
    description:
      "Com tantos casos ainda arquivados e por resolver precisamos de alguém que ajude os DESARQUIVADOS a chegar a mais detetives. O Rogério é o nosso homem de números. Um autêntico entrepreneur que encontra o financiamento necessário para que a equipa de detetives continue a conseguir resolver os casos e apanhar criminosos. A arte do negócio é o seu recreio e a sua experiência é valiosa na equipa dos DESARQUIVADOS.",
  },
  {
    img: tomas,
    name: "Tomás",
    position: "Tecnologia",
    description:
      "Se isto fosse um filme, o Tomás era o nosso hacker geek muito inteligente e rápido com tecnologia. Estas palavras que aparecem no site só aparecem porque ele as conseguiu pôr aqui! Incrível não é? Mesmo quando ele não sabe fazer alguma coisa, ele aprende. E num mundo cheio de casos por resolver é essencial ter alguém como ele na equipa DESARQUIVADOS!",
  },
];

export default teamData;
