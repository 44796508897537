import "react-h5-audio-player/lib/styles.css";
import { Dialog, DialogContent } from "@mui/material";
import { Transition } from "../../../../../../utils/Transitions";
import { BoldMediumText } from "../../../../../../utils/GlobalStyles";
import { ModalFileItemProps } from "../../../../../../utils/Interfaces/Game";
import { CloseRoundedIconWrapper, DialogTitleWrapper } from "./styles";
import { COLORS } from "../../../../../../assets/theme";

function ModalFileItem({
  bucketUrl,
  file,
  activeFolder,
  handleOpenFile,
  fileOpen,
}: ModalFileItemProps) {
  const bucketFolder = `${bucketUrl}game/`;

  return (
    <Dialog
      maxWidth={"md"}
      TransitionComponent={Transition}
      open={fileOpen}
      onClose={handleOpenFile}
      fullWidth
    >
      <DialogTitleWrapper mb={"10px"}>
        <BoldMediumText color={`${COLORS.white}`}>
          {file.split(".")[0]}
        </BoldMediumText>
        <button onClick={handleOpenFile}>
          <CloseRoundedIconWrapper />
        </button>
      </DialogTitleWrapper>
      <DialogContent sx={{ height: "90vh" }}>
        <iframe
          src={`${bucketFolder}${activeFolder}/${encodeURIComponent(file)}#toolbar=0&navpanes=0&scrollbar=0`}
          style={{
            width: "100%",
            height: file.split(".").pop() === "pdf" ? "100%" : "100%",
          }}
          allowFullScreen
        />
      </DialogContent>
    </Dialog>
  );
}

export default ModalFileItem;
