import { Box, Divider, styled } from "@mui/material";
import { PALETTE } from "../../../../assets/theme";

export const TooltipStepsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 10px;
`;

export const TooltipStepBox = styled(Box)`
  display: flex;
  gap: 10px;
`;

export const TooltipStepTextBox = styled(Box)`
  display: flex;
  flex-flow: column;
`;

export const TooltipStepIconBox = styled(Box)`
  display: flex;
  gap: 8px;
  flex-flow: column;
  padding-bottom: 10px;
`;

export const TooltipStepIconCircle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 7px;
  background-color: ${PALETTE.secondaryFour};
`;

export const VerticalLine = styled(Divider)`
  background-color: ${PALETTE.secondaryFour};
  width: 2px;
  border-radius: 30px;
  flex: 1;
  margin: 0 auto;
`;
