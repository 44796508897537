import { Box, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const Image = styled("img")`
  border-radius: 6px;
  width: 320px;
  object-fit: cover;
  height: 350px;
`;

interface BackgroundTextBoxProps {
  isHovered?: boolean;
}

export const BackgroundTextBox = styled(Box)<BackgroundTextBoxProps>`
  background-color: ${PALETTE.primaryOne};
  opacity: 0.9;
  height: 100%;
  width: 100%;
  display: ${({ isHovered }) => (isHovered ? "flex" : "none")};
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const TextBox = styled(Box)<BackgroundTextBoxProps>`
  height: 100%;
  display: ${({ isHovered }) => (isHovered ? "flex" : "none")};
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  left: 0;
`;
