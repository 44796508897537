import { S3GetObjectResponse } from "../Interfaces/Global";

var AWS = require("aws-sdk");
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

export async function getFileFromBucket(
  bucket_name: string,
  file_name: string,
  file_type: string
) {
  const s3 = new AWS.S3();

  const params = {
    Bucket: bucket_name,
    Key: file_name,
  };

  const data: S3GetObjectResponse = await s3.getObject(params).promise();
  let blob = new Blob([data.Body], {
    type: file_type,
  });
  downloadBlob(blob, file_name);
}

export function downloadBlob(blob: Blob, template: string) {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = template;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  document.body.removeChild(link);
}
