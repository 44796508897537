import { useParams } from "react-router-dom";
import CantAccess from "../../../components/CaseGame/CantAccess";
import { PrivateSharedCaseProps } from "../../../utils/Interfaces/Game";
import { cloneElement, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import InvitedUserForm from "../../../components/InvitedUserForm";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { getData } from "../../../utils/endpoints/api";
import BigLoader from "../../../components/loaders/BigLoader";

export const PrivateSharedCase = ({ children }: PrivateSharedCaseProps) => {
  const { userId, orderItemId, sharedCaseId } = useParams();
  const { setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  //   TODO: fix the any
  const {
    data: sharedCaseData,
    error: sharedCaseAccessError,
    isLoading,
  } = useQuery<any, Error>({
    queryKey: ["sharedCaseAccess"],
    queryFn: () =>
      getData("my-case/check-access/shared-case", {
        userId: userId!,
        orderItemId: orderItemId!,
        sharedCaseId: sharedCaseId!,
      }),
    enabled: !!userId && !!orderItemId && !!sharedCaseId,
  });

  useEffect(() => {
    if (sharedCaseAccessError) {
      setWarningMessage([
        "error",
        "Houve um erro ao verificar o acesso ao caso partilhado. Tenta mais tarde!",
      ]);
    }
  }, [sharedCaseAccessError]);

  const PrivateSharedCaseFilter = () => {
    switch (sharedCaseData?.status) {
      case "ACTIVE":
        return <>{cloneElement(children, { caseData: sharedCaseData })}</>;
      case "NAME_REQUIRED":
        return <InvitedUserForm data={sharedCaseData} />;
      case "COMPLETED":
        return <CantAccess reason="Caso terminado." />;
      case "NOT_ACTIVATED":
        return <CantAccess reason="O caso ainda não foi ativado." />;
      case "EXPIRED":
        return <CantAccess reason="O caso expirou." />;
      case "NOT_FOUND":
      default:
        return <CantAccess reason="Caso não encontrado." />;
    }
  };

  return isLoading ? <BigLoader /> : <PrivateSharedCaseFilter />;
};
