import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateSharedCase } from "./PrivateSharedCase";
import { MyCaseAccessType } from "../../utils/Interfaces/Game";
import CaseGamePage from "../../components/CaseGame/CaseGamePage";

export const SharedCase = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate replace to="/" />} />
      <Route
        path="/:userId/:orderItemId/:sharedCaseId"
        element={
          <PrivateSharedCase>
            <CaseGamePage caseData={{} as MyCaseAccessType} />
          </PrivateSharedCase>
        }
      />
    </Routes>
  );
};
