import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import SignIn from "./AuthenticationPages/SignIn";
import SignUp from "./AuthenticationPages/SignUp";
import ResetPassword from "./AuthenticationPages/ResetPassword";
import ConfirmSignUp from "./AuthenticationPages/ConfirmSignUp";
import {
  AuthenticationContext,
  GeneralAppDataContext,
} from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { AuthenticationProps } from "../../utils/Interfaces/Account";

function Authentication({ previousLocation }: AuthenticationProps) {
  const { isAuthenticated } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [step, setStep] = useState<string>("");
  const [extraStepData, setExtraStepData] = useState<string>("");
  const [cognitoMessage, setCognitoMessage] = useState<string | null>(null);
  const [username, setUsername] = useState<string>("");

  const from = previousLocation?.pathname || "/";
  const navigate = useNavigate();

  useEffect(() => {
    function enter() {
      if (isAuthenticated) {
        navigate(from);
      }
    }
    enter();
  }, [isAuthenticated]);

  useEffect(() => {
    setCognitoMessage(null);
  }, [step]);

  const SelectAuthenticationPage = () => {
    switch (step) {
      case "SIGN_UP":
        return <SignUp />;
      case "CONFIRM_SIGN_UP":
        return <ConfirmSignUp />;
      case "FORGOT_PASSWORD":
        return <ResetPassword />;
      case "LOG_IN":
      default:
        return <SignIn />;
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{
        step,
        setStep,
        extraStepData,
        setExtraStepData,
        cognitoMessage,
        setCognitoMessage,
        username,
        setUsername,
      }}
    >
      <SelectAuthenticationPage />
    </AuthenticationContext.Provider>
  );
}

export default Authentication;
