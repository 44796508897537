import { BoldMediumText, MediumText } from "../../utils/GlobalStyles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionInformationWrapper } from "./styles";
import { ActionInformationProps } from "../../utils/Interfaces/Global";
import parse from "html-react-parser";

function ActionInformation({
  message,
  icon: Icon,
  iconColor,
  destinationUrl,
  destinationText,
}: ActionInformationProps) {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        navigate(destinationUrl);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <ActionInformationWrapper my={6}>
      <Icon style={{ width: "60px", height: "60px", color: iconColor }} />
      <BoldMediumText sx={{ whiteSpace: "wrap", textAlign: "center" }} mt={4}>
        {parse(message)}
      </BoldMediumText>
      <MediumText
        mt={2}
      >{`Vais ser redirecionado para ${destinationText} em ${seconds} segundos...`}</MediumText>
    </ActionInformationWrapper>
  );
}

export default ActionInformation;
