import { Box, Divider, styled } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const ReviewAuthorInitialsBox = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: ${COLORS.redOne};
`;

export const ReviewTopContent = styled(Box)`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

export const ReviewBottomContent = styled(Box)`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const RatingBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const LighterDividerSection = styled(Divider)`
  margin: 30px 0;
  border-color: ${COLORS.grayOne};
`;
