import { Box, Button, styled } from "@mui/material";

export const DialogContentWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
`;

export const AddMembersInputWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  height: 40px;
`;

export const InvitationsWapper = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 10px;
`;

export const InvitationRow = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 5px 0 5px;
`;

export const NameEmailBox = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
