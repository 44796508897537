import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import PageHeader from "../PageHeader";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
} from "../../utils/GlobalStyles";
import { FAQBox, FAQWrapper } from "./styles";
import questions_list from "./faqs.json";
import { Box } from "@mui/material";
import { PALETTE } from "../../assets/theme";
import parse from "html-react-parser";

const FAQs = () => {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  return (
    <FAQWrapper>
      <PageHeader
        heading="Questões mais frequentes"
        section={""}
        content="Porque queremos Detetives esclarecidos."
      />
      <FAQBox>
        {questions_list?.map(({ title, questions }) => (
          <Box mt={6}>
            <BoldLargeText
              sx={{
                paddingBottom: "10px",
                borderBottom: `2.5px solid ${PALETTE.primaryTwo}`,
              }}
            >
              {title}
            </BoldLargeText>
            {questions?.map(({ title, description }, key) => (
              <Accordion
                placeholder=""
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                key={key}
                open={open === key + 1}
                onClick={() => handleOpen(key + 1)}
              >
                <AccordionHeader
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  placeholder=""
                >
                  <BoldMediumText whiteSpace={"wrap !important"}>
                    {title}
                  </BoldMediumText>
                </AccordionHeader>
                <AccordionBody>
                  {description.map((point, index) => (
                    <MediumText mb={index < description.length - 1 ? 2 : 0}>
                      {parse(point)}
                    </MediumText>
                  ))}
                </AccordionBody>
              </Accordion>
            ))}
          </Box>
        ))}
      </FAQBox>
    </FAQWrapper>
  );
};

export default FAQs;
