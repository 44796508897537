import { Box, styled } from "@mui/material";
import Slider from "react-slick";
import { COLORS, PALETTE } from "../../../assets/theme";

export const GameFinishedMainContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-flow: column;
  overflow-y: auto;
  flex: 1;
  max-height: calc(100% - 70px);
`;

export const ActionButtonsBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
  padding: 20px;
  background-color: ${PALETTE.primaryTwo};
`;

export const CharactersWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
`;

export const StyledCharactersSlider = styled(Slider)`
  width: 100%;
  margin-bottom: 60px;
  max-width: 1200px;

  .slick-prev:before,
  .slick-next:before {
    color: ${PALETTE.primaryTwo};
  }

  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
  }

  .slick-prev::before,
  .slick-next::before {
    display: none;
  }
`;

export const JudgeLetterBox = styled(Box)`
  padding: 25px;
  max-width: 1200px;
  background-color: ${COLORS.bejeOne};
`;
