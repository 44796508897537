import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import {
  CartActionIconBox,
  CartItemBox,
  CartItemContentLeftSection,
  CartItemContentRightSectionDesktop,
  CartItemContentRightSectionMobile,
  CartItemImage,
  DiscountBox,
  ImageTextBox,
  ItemQuantityWrapper,
  RightContentBox,
  SummaryText,
} from "./styles";
import {
  BoldMediumText,
  BoldSmallText,
  MediumText,
  SmallText,
} from "../../utils/GlobalStyles";
import { GroupedCartItemType } from "../../utils/Interfaces/Cart";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { Box } from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { addItemToCart, removeItemFromCart } from "./helpers";
import { COLORS } from "../../assets/theme";
import { deleteData, postData } from "../../utils/endpoints/api";
import LocalLoader from "../loaders/LocalLoader";

function CartItem(groupedCartItem: GroupedCartItemType) {
  const { user, setCart, setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  const queryClient = useQueryClient();
  const removeCartItemMutation = useMutation({
    mutationFn: (params: Record<string, string>) =>
      deleteData("cart/remove", params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao remover o item do carrinho. Tenta mais tarde!",
      ]);
    },
  });
  const addCartItemMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("cart/add", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao adicionar o item ao carrinho. Tenta mais tarde!",
      ]);
    },
  });

  const handleAddItemToCart = async () => {
    addItemToCart({
      user: user,
      mutation: addCartItemMutation,
      setCart: setCart,
      item: groupedCartItem,
    });
  };

  const handleRemoveItemToCart = async () => {
    removeItemFromCart({
      user: user,
      mutation: removeCartItemMutation,
      setCart: setCart,
      groupedItem: groupedCartItem,
    });
  };

  const cartLoading =
    addCartItemMutation.isPending || removeCartItemMutation.isPending;

  return (
    <CartItemBox>
      <ImageTextBox>
        <CartItemImage
          src={`${groupedCartItem.imageUrl}main_horizontal.png`}
          alt="cart-item"
        />
        <CartItemContentLeftSection>
          <Box display="flex" gap={1.5}>
            <BoldMediumText>{groupedCartItem.name}</BoldMediumText>
            {groupedCartItem.unitDiscount > 0 && (
              <DiscountBox>
                <BoldSmallText>
                  -{groupedCartItem.unitDiscount * 100}%
                </BoldSmallText>
              </DiscountBox>
            )}
          </Box>
          <CartItemContentRightSectionMobile>
            {cartLoading ? (
              <LocalLoader height="40" />
            ) : (
              <ItemQuantityWrapper>
                <CartActionIconBox onClick={handleRemoveItemToCart}>
                  {groupedCartItem.quantity > 1 ? (
                    <RemoveRoundedIcon
                      sx={{
                        width: "18px",
                        height: "18px",
                        color: COLORS.black,
                      }}
                    />
                  ) : (
                    <DeleteOutlineIcon
                      sx={{
                        width: "18px",
                        height: "18px",
                        color: COLORS.black,
                      }}
                    />
                  )}
                </CartActionIconBox>
                <SmallText>{groupedCartItem.quantity}</SmallText>
                <CartActionIconBox onClick={handleAddItemToCart}>
                  <AddRoundedIcon
                    sx={{ width: "18px", height: "18px", color: COLORS.black }}
                  />
                </CartActionIconBox>
              </ItemQuantityWrapper>
            )}
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <BoldMediumText mt={0.5}>
                €{groupedCartItem?.amount}
              </BoldMediumText>
              <SmallText noWrap>€{groupedCartItem.unitPrice} cada</SmallText>
            </Box>
          </CartItemContentRightSectionMobile>
          <SummaryText>{groupedCartItem.teaserSummary}</SummaryText>
        </CartItemContentLeftSection>
      </ImageTextBox>
      <CartItemContentRightSectionDesktop>
        <RightContentBox>
          {cartLoading ? (
            <LocalLoader height="40" />
          ) : (
            <ItemQuantityWrapper>
              <CartActionIconBox onClick={handleRemoveItemToCart}>
                {groupedCartItem.quantity > 1 ? (
                  <RemoveRoundedIcon
                    sx={{ width: "18px", height: "18px", color: COLORS.black }}
                  />
                ) : (
                  <DeleteOutlineIcon
                    sx={{ width: "18px", height: "18px", color: COLORS.black }}
                  />
                )}
              </CartActionIconBox>
              <SmallText>{groupedCartItem.quantity}</SmallText>
              <CartActionIconBox onClick={handleAddItemToCart}>
                <AddRoundedIcon
                  sx={{ width: "18px", height: "18px", color: COLORS.black }}
                />
              </CartActionIconBox>
            </ItemQuantityWrapper>
          )}
        </RightContentBox>
        <RightContentBox sx={{ alignItems: "flex-end", gap: 0 }}>
          <BoldMediumText mt={0.5}>€{groupedCartItem?.amount}</BoldMediumText>
          <SmallText noWrap>€{groupedCartItem.unitPrice} cada</SmallText>
        </RightContentBox>
      </CartItemContentRightSectionDesktop>
    </CartItemBox>
  );
}

export default CartItem;
