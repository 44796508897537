export const desarquivadosAppApiUrl =
  "https://k2nechqmue.execute-api.eu-north-1.amazonaws.com";
export const desarquivadosPaymentsApiUrl =
  "https://vnfwt83a9c.execute-api.eu-north-1.amazonaws.com";

export const apiStage = process.env.REACT_APP_ENVIRONMENT;
export const desarquivadosAppApiKey =
  process.env.REACT_APP_DESARQUIVADOS_API_KEY;
export const desarquivadosPaymentsApiKey =
  process.env.REACT_APP_DESARQUIVADOS_PAYMENTS_KEY;
