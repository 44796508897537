import {
  BoldMediumText,
  BoldSmallText,
  DividerSection,
  MainButton,
  MediumText,
  TitleMediumText,
} from "../../../../../utils/GlobalStyles";
import { GameFailProps } from "../../../../../utils/Interfaces/Game";
import { GeneralAppDataContextType } from "../../../../../utils/Interfaces/Global";
import { GeneralAppDataContext } from "../../../../../utils/GeneralContext";
import { useContext } from "react";
import { CaseGamePageMain } from "../../../CaseGamePage/styles";
import GameHeader from "../../../CaseGamePage/GameHeader";
import {
  GameFinishedMainContentWrapper,
  JudgeLetterBox,
} from "../../../GameFinished/styles";

function GameFail({
  caseData,
  reportSubmitted,
  handleResetReportSubmitted,
}: GameFailProps) {
  const { user } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  return (
    <>
      <CaseGamePageMain>
        <GameHeader
          handleTryAgain={handleResetReportSubmitted}
          caseData={caseData}
          finished
        />
        <GameFinishedMainContentWrapper>
          <TitleMediumText mx="auto" mt={4}>
            Tentativa falhada, Detetive {user?.lastName}
          </TitleMediumText>
          <DividerSection />
          <BoldMediumText>
            Despacho emitido pelo Meretíssimo Juiz de Instrução Criminal da
            Comarca de Lisboa, Silvério Menezes, sobre o caso{" "}
            {caseData?.caseNumber}
          </BoldMediumText>
          <JudgeLetterBox mt={2}>
            <MediumText>A/C Rogério Flores, </MediumText>
            <MediumText>
              Coordenador de investigação criminal da Polícia de Portugal
            </MediumText>
            <MediumText mt={2.5}>
              Após analisar o caso {caseData?.caseNumber} apresentado pelo
              Departamento de Investigação Criminal da Polícia de Portugal, as
              diferentes provas reunidas e tendo em consideração as conclusões
              retiradas pelos investigadores CONSIDERO:
            </MediumText>
            <MediumText mt={1}>
              Não suficientes os indícios aqui apresentados pelo Departamento de
              Investigação criminal para constituir como arguido o suspeito{" "}
              {reportSubmitted?.name} no caso C3358/2022 que vitimou Vincent
              Athayde. Assim sendo, recomendo aos investigadores responsáveis
              por esta argumentação que revejam a mesma e, caso considerem,
              apresentem novas provas e evidências.
            </MediumText>
            <MediumText mt={2.5} textAlign="right">
              Silvério Menezes,
            </MediumText>
            <MediumText textAlign="right">
              Juíz de Instrução Criminal da Comarca de Lisboa
            </MediumText>
          </JudgeLetterBox>
          <DividerSection />
          <BoldMediumText mt={3}>
            O Juíz não validou o nosso caso como suficiente para constituir como
            arguido o suspeito indicado.
          </BoldMediumText>
          <BoldMediumText mt={1}>
            O Coordenador Rogério Flores pede para analisares as provas uma vez
            mais. De certeza que algo te escapou para conseguires descobrir o
            culpado. Contamos contigo!
          </BoldMediumText>
          <MainButton
            sx={{ marginTop: 4 }}
            onClick={handleResetReportSubmitted}
          >
            <BoldMediumText>Voltar a tentar</BoldMediumText>
          </MainButton>
        </GameFinishedMainContentWrapper>
      </CaseGamePageMain>
    </>
  );
}

export default GameFail;
