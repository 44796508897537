import { Box, styled } from "@mui/material";
import { COLORS } from "../../../assets/theme";

export const BigLoaderDiv = styled(Box)`
  position: sticky;
  background-color: ${COLORS.yellowOne};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
