import { useQuery } from "@tanstack/react-query";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
  UnderlinedButton,
} from "../../../../utils/GlobalStyles";
import {
  CardBox,
  MainContentWrapper,
  OrderDetailsWrapper,
  OrderNumberDateBox,
  ProductImage,
  ProductOrder,
  ProductOrderDetails,
  ProductOrderStats,
  SummaryBox,
  TableHeader,
  TableHeaderLeft,
  TableHeaderRight,
  UpperContentWrapper,
} from "./styles";
import {
  OrderCaseType,
  OrderDetailsProps,
  OrderDetailsType,
} from "../../../../utils/Interfaces/Account";
import { COLORS } from "../../../../assets/theme";
import { getData } from "../../../../utils/endpoints/api";
import SmallLoader from "../../../loaders/SmallLoader";
import { useContext, useEffect } from "react";
import { GeneralAppDataContext } from "../../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../../utils/Interfaces/Global";

function OrderDetails({ orderId, setOrderDetails }: OrderDetailsProps) {
  const { setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const {
    data: orderDetails,
    error: orderDetailsError,
    isLoading,
  } = useQuery<OrderDetailsType, Error>({
    queryKey: ["orderDetails"],
    queryFn: () => getData("order/details", { orderId: orderId.toString() }),
  });

  useEffect(() => {
    if (orderDetailsError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter os detalhes da compra. Tenta mais tarde!",
      ]);
    }
  }, [orderDetailsError]);

  return isLoading ? (
    <SmallLoader />
  ) : (
    <OrderDetailsWrapper>
      <UpperContentWrapper>
        <UnderlinedButton onClick={() => setOrderDetails(0)}>
          <MediumText
            color={`${COLORS.blueFour} !important`}
            sx={{ width: "fit-content" }}
          >
            &lt; Voltar
          </MediumText>
        </UnderlinedButton>
        <OrderNumberDateBox>
          <BoldLargeText>Order #{orderDetails?.orderId}</BoldLargeText>
          <MediumText>{orderDetails?.orderDate}</MediumText>
        </OrderNumberDateBox>
      </UpperContentWrapper>
      <MainContentWrapper>
        <TableHeader>
          <TableHeaderLeft>
            <MediumText color={`${COLORS.black} !important`}>
              Produto
            </MediumText>
          </TableHeaderLeft>
          <TableHeaderRight>
            <MediumText color={`${COLORS.black} !important`}>
              Quantidade
            </MediumText>
            <MediumText color={`${COLORS.black} !important`}>
              Valor unitário
            </MediumText>
          </TableHeaderRight>
        </TableHeader>
        <CardBox>
          {orderDetails?.cases?.map((item: OrderCaseType) => (
            <ProductOrder>
              <ProductImage
                src={`${item.imageUrl}main_horizontal.png`}
                alt="product image"
              />
              <ProductOrderDetails>
                <BoldMediumText sx={{ width: "100%" }}>
                  {item?.name}
                </BoldMediumText>
                <ProductOrderStats>
                  <MediumText>x{item?.count}</MediumText>
                  <MediumText>€{item?.unitPrice}</MediumText>
                </ProductOrderStats>
              </ProductOrderDetails>
            </ProductOrder>
          ))}
        </CardBox>
        <CardBox>
          <BoldMediumText>Sumário</BoldMediumText>
          <SummaryBox>
            <MediumText>Total</MediumText>
            <BoldMediumText>€{orderDetails?.orderAmount}</BoldMediumText>
          </SummaryBox>
        </CardBox>
      </MainContentWrapper>
    </OrderDetailsWrapper>
  );
}

export default OrderDetails;
