import { Box, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const OurProductCharacteristicsWrapper = styled(Box)`
  width: 100vw;
  display: flex;
  margin: 75px auto 100px -20px;
  justify-content: center;
  border-top: 5px solid ${PALETTE.primaryOne};
  border-bottom: 5px solid ${PALETTE.primaryOne};
`;

export const OurProductCharacteristicsBox = styled(Box)`
  max-width: 1200px;
  display: flex;
  gap: 50px;
  padding: 40px 32px;
  flex-flow: column;

  @media (min-width: 768px) {
    flex-flow: row;
  }

  @media (min-width: 1024px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`;

export const LeftContentBox = styled(Box)`
  margin: auto 0;
  text-align: center;

  @media (min-width: 768px) {
    width: 350px;
  }

  @media (min-width: 1024px) {
    width: 400px;
  }
`;

export const RightContentBox = styled(Box)`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  gap: 24px;

  @media (min-width: 640px) {
    flex-flow: column;
  }

  @media (min-width: 768px) {
    flex-flow: row;
  }

  @media (min-width: 900px) {
    flex-flow: column;
  }
`;

export const ItemBox = styled(Box)`
  text-align: center;
`;

export const Row = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;

  @media (min-width: 640px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    flex-flow: row;
    gap: 16px;
  }

  @media (min-width: 900px) {
    gap: 64px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const IconBox = styled(Row)`
  margin: 0 auto;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
