import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Transition } from "../../../utils/Transitions";
import { COLORS } from "../../../assets/theme";
import {
  BoldLargeText,
  BoldSmallText,
  MediumText,
  NoBackgroundButton,
  SecondaryButton,
  SmallText,
} from "../../../utils/GlobalStyles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AddCaseCodeInputWrapper, DialogContentWrapper } from "./styles";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AddCaseCodeModalProps } from "../../../utils/Interfaces/Account";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData } from "../../../utils/endpoints/api";
import { useNavigate } from "react-router-dom";
import SmallLoader from "../../loaders/SmallLoader";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";

export default function AddCaseCodeModal({
  modalOpen,
  handleModalOpen,
}: AddCaseCodeModalProps) {
  const { user } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [formReady, setFormReady] = useState(false);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState<[string, string] | null>(null);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const activateOfferecCaseMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("order/activate-offered-case", params, body),
    onSuccess: (data) => {
      setCode("");
      setTimeout(() => {
        setMessage(["sucess", data.message]);
      }, 200);
      queryClient.invalidateQueries({ queryKey: ["myCases"] });
    },
    onError: (error) => {
      setMessage(["error", error.message]);
    },
  });

  useEffect(() => {
    if (!modalOpen) {
      setCode("");
    }
  }, [modalOpen]);

  useEffect(() => {
    function checkFields() {
      if (code.length > 8) {
        setFormReady(true);
        setMessage(null);
      } else {
        setFormReady(false);
        setMessage(null);
      }
    }
    checkFields();
  }, [code]);

  const handleAddCaseToAccount = async () => {
    try {
      await activateOfferecCaseMutation.mutateAsync({
        params: {
          code: code!,
          userId: user?.id!,
        },
        body: {},
      });
    } catch (error) {
      console.error("Error terminating case:", error);
    }
  };

  const isLoading = activateOfferecCaseMutation.isPending;

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={modalOpen}
        onClose={handleModalOpen}
        fullWidth
      >
        <DialogTitle
          my={"10px"}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <BoldLargeText>Adicionar um caso à tua conta</BoldLargeText>
          <NoBackgroundButton onClick={handleModalOpen}>
            <CloseRoundedIcon sx={{ width: "20px", height: "20px" }} />
          </NoBackgroundButton>
        </DialogTitle>
        <DialogContent>
          <MediumText>
            Se tens um código para adicionar um caso à tua conta, insere-o em
            baixo.
          </MediumText>
          <DialogContentWrapper mb={1}>
            {isLoading ? (
              <SmallLoader />
            ) : (
              <>
                <AddCaseCodeInputWrapper mt={4}>
                  <TextField
                    size="small"
                    value={code}
                    label="Código"
                    sx={{ width: "180px" }}
                    variant="outlined"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setCode(e?.target?.value)
                    }
                  />
                  <SecondaryButton
                    sx={{ width: "180px" }}
                    onClick={handleAddCaseToAccount}
                    disabled={!formReady}
                  >
                    <SmallText
                      sx={{ color: COLORS.white, whiteSpace: "nowrap" }}
                    >
                      Adicionar caso
                    </SmallText>
                  </SecondaryButton>
                </AddCaseCodeInputWrapper>
                {message && (
                  <BoldSmallText
                    mt={2}
                    color={`${message[0] === "error" ? COLORS.redTwo : COLORS.greenTwo} !important`}
                  >
                    {message[1]}
                  </BoldSmallText>
                )}
              </>
            )}
          </DialogContentWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
}
