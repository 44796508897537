import LandingItem from "../../components/LandingItem";
import OurProductCharacteristics from "../../components/OurProductCharacteristics";
import HowToGetStarted from "../../components/HowToGetStarted";
import Reviews from "../../components/Reviews";
import { Box } from "@mui/material";
import PromotedCase from "../../components/PromotedCase";
import ReactGA from "react-ga4";

function Homepage() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Homepage",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "Homepage");
    }
  }

  return (
    <Box sx={{ display: "flex", flexFlow: "column", justifyContent: "center" }}>
      <LandingItem />
      <OurProductCharacteristics />
      <PromotedCase />
      <HowToGetStarted />
      <Reviews />
    </Box>
  );
}

export default Homepage;
