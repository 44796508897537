import StepCard from "../StepCard";
import howToPlayData from "../../utils/data/how-to-play-data";
import PageHeader from "../PageHeader";
import { HowToGetStartedBox, HowToGetStartedWrapper } from "./styles";
import { BoldSmallText, MainButton } from "../../utils/GlobalStyles";
import { NavLink } from "react-router-dom";

export default function HowToGetStarted() {
  return (
    <HowToGetStartedWrapper>
      <PageHeader
        heading="Como resolver um caso"
        section={""}
        content="Serão dadas pistas acerca de um caso que ainda não foi resolvido.
          Analisa-as e tira as tuas conclusões."
      />
      <HowToGetStartedBox>
        {howToPlayData?.map(({ title, step, description }) => (
          <StepCard
            key={title}
            title={title}
            step={step}
            description={description}
          />
        ))}
      </HowToGetStartedBox>
      <NavLink to="/how-to-start">
        <MainButton size="large">
          <BoldSmallText>Saber mais</BoldSmallText>
        </MainButton>
      </NavLink>
    </HowToGetStartedWrapper>
  );
}
