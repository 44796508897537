import { COLORS } from "../../../../../assets/theme";
import { SmallText } from "../../../../../utils/GlobalStyles";
import {
  InvitationType,
  InvitedTeamMembersHeaderProps,
} from "../../../../../utils/Interfaces/Game";
import {
  InvitedTeamMemberCircle,
  InvitedTeamMemberCircleButton,
  InvitedTeamMembersHeaderWrapper,
} from "./styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export default function InvitedTeamMembersHeader({
  handleAddTeamMemberModal,
  invitations,
}: InvitedTeamMembersHeaderProps) {
  return (
    <InvitedTeamMembersHeaderWrapper>
      <InvitedTeamMemberCircleButton
        onClick={handleAddTeamMemberModal}
        pos={invitations.length}
      >
        <AddRoundedIcon
          sx={{
            width: "20px",
            height: "20px",
            color: COLORS.grayTwo,
          }}
        />
      </InvitedTeamMemberCircleButton>
      {invitations.map(
        (item: InvitationType, index) =>
          item?.name && (
            <InvitedTeamMemberCircle pos={invitations.length - index - 1}>
              <SmallText
                lineHeight="23px"
                sx={{
                  position: "relative",
                  top: "1px",
                  display: "flex",
                  alignItems: "center",
                }}
                color={`${COLORS.grayThree} !important`}
              >
                {item?.name.trim().split(" ")[0][0].toUpperCase()}
              </SmallText>
            </InvitedTeamMemberCircle>
          ),
      )}
    </InvitedTeamMembersHeaderWrapper>
  );
}
