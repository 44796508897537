import { Box, Typography, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../../assets/theme";

export const UpperBannerWrapper = styled(Box)`
  height: 40px;
  background-color: ${PALETTE.primaryTwo};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${COLORS.white};
`;

export const BoldBannerText = styled(Typography)`
  font-weight: 700;
  font-size: 0.95rem;
  white-space: nowrap;
`;

export const LinkBannerText = styled(Typography)`
  font-weight: 500;
  font-size: 0.95rem;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;
