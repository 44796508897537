import {
  BoldMediumText,
  BoldSmallText,
  MediumText,
} from "../../../../utils/GlobalStyles";
import { TooSmallScreenWrapper } from "./styles";

export default function TooSmallScreen() {
  return (
    <TooSmallScreenWrapper>
      <BoldMediumText textAlign="center">
        Este ecrã é demasiado pequeno para poderes jogar.
      </BoldMediumText>
      <MediumText textAlign="center" mt={1}>
        Estamos a trabalhar para que em breve seja possível.
      </MediumText>
      <MediumText mt={1} mb={2}>
        Mantém-te atento!
      </MediumText>
      <img
        width={150}
        src={require("../../../../assets/images/desarquivados_secondary_logo.png")}
        alt="desarquivados logo"
      />
    </TooSmallScreenWrapper>
  );
}
