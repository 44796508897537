import { NavLink } from "react-router-dom";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
  SecondaryButton,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import { BoardImage, ContentBox, PromotedCaseWrapper } from "./styles";
import { COLORS } from "../../assets/theme";

function PromotedCase() {
  return (
    <PromotedCaseWrapper>
      <ContentBox>
        <BoldLargeText color={`${COLORS.redTwo} !important`}>
          NOVO
        </BoldLargeText>
        <TitleMediumText>A Morte do Artista</TitleMediumText>
        <MediumText
          mt={2}
          mb={4}
          color={`${COLORS.grayFour} !important`}
          textAlign="center"
        >
          Conceituado Pintor Português é encontrado morto no seu atelier. Deixa
          para trás umas dezenas de quadros e milhares de euros. A Polícia de
          Portugal investigou os vários suspeitos mas não encontrou o culpado. O
          caso está arquivado como um assalto mal sucedido mas será que há algo
          mais nesta historia?
        </MediumText>
        <NavLink to="/cases/artist-death">
          <SecondaryButton size="large">
            <BoldMediumText color={`${COLORS.white} !important`}>
              Analisar caso
            </BoldMediumText>
          </SecondaryButton>
        </NavLink>
      </ContentBox>
      <NavLink to="/cases/artist-death">
        <BoardImage
          src={require("../../assets/cases-content/suspects_board.png")}
          alt="agency logo"
        />
      </NavLink>
    </PromotedCaseWrapper>
  );
}

export default PromotedCase;
