import { Box, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const ReviewsWrapper = styled(Box)`
  max-width: 1200px;
  margin: 100px auto;
  width: 100%;
  gap: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

export const ReviewsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  padding: 64px 80px 80px 80px;
  gap: 40px;
  background-color: ${PALETTE.primaryOne};
  border-radius: 6px;

  @media (min-width: 768px) {
    flex-flow: row;
    gap: 80px;
  }
`;

interface ReviewBoxProps {
  alignStart?: boolean;
}

export const ReviewBox = styled(Box)<ReviewBoxProps>`
  display: flex;
  flex-direction: column;
  grid-column: span 3 / span 3;
  min-height: 130px;
  justify-content: center;
  align-items: ${({ alignStart }) => (alignStart ? "flex-start" : "flex-end")};

  @media (min-width: 768px) {
    min-height: 100px;
  }
`;

export const AuthorBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const NavigationBox = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  gap: 8px;
  width: 80px;
  height: 12px;
`;

export const NavigationSpan = styled("span")`
  display: block;
  width: 40px;
  height: 4px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;
`;
