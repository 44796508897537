import InboxRoundedIcon from "@mui/icons-material/InboxRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import LocalMallRoundedIcon from "@mui/icons-material/LocalMallRounded";
import {
  BoldMediumText,
  SmallText,
  UnderlinedButton,
} from "../../utils/GlobalStyles";
import { signOut } from "aws-amplify/auth";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { useContext, useState } from "react";
import {
  AccountTopMenu,
  AddCaseCodeButton,
  DividerSection,
  LoggedUserInitialsBox,
  MenuBox,
  MenuButton,
  UserActionsBox,
  UserInformationBox,
} from "./styles";
import { AccountHeaderProps } from "../../utils/Interfaces/Account";
import { COLORS } from "../../assets/theme";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import AddCaseCodeModal from "./AddCaseCodeModal";

export default function AccountHeader({ tab, setTab }: AccountHeaderProps) {
  const { user, setIsAuthenticated, isLoading, setIsLoading } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const navigate = useNavigate();
  const [addCaseCodeModal, setAddCaseCodeModal] = useState(false);

  async function handleLogout() {
    setIsLoading(true);
    await signOut()
      .then(() => {
        setIsAuthenticated(false);
        setIsLoading(false);
      })
      .catch((error: { message: string }) => {
        setIsLoading(false);
        console.log(error.message);
      });
  }

  const handleAddCaseCodeModal = () => setAddCaseCodeModal(!addCaseCodeModal);

  return (
    <>
      <AddCaseCodeModal
        modalOpen={addCaseCodeModal}
        handleModalOpen={handleAddCaseCodeModal}
      />
      <Box
        mt={-1.5}
        mb={1.5}
        px={2}
        sx={{
          width: "100%",
          textAlign: "right",
        }}
      >
        <AddCaseCodeButton onClick={handleAddCaseCodeModal}>
          <SmallText>Tenho um código para adicionar um caso</SmallText>
        </AddCaseCodeButton>
      </Box>
      <AccountTopMenu>
        <UserInformationBox>
          <LoggedUserInitialsBox>
            <BoldMediumText>
              {user?.firstName?.substring(0, 1).toUpperCase()}
              {user?.lastName?.substring(0, 1).toUpperCase()}
            </BoldMediumText>
          </LoggedUserInitialsBox>
          <UserActionsBox>
            <BoldMediumText mb={0.5}>
              {user?.firstName} {user?.lastName}
            </BoldMediumText>
            <UnderlinedButton onClick={handleLogout}>
              <ExitToAppRoundedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: COLORS.blueFour,
                }}
              />
              <SmallText
                color={`${COLORS.blueFour} !important`}
                sx={{ width: "fit-content" }}
              >
                Terminar sessão
              </SmallText>
            </UnderlinedButton>
          </UserActionsBox>
        </UserInformationBox>
        <DividerSection orientation="vertical" flexItem />
        <MenuBox>
          <MenuButton
            tab={tab}
            index={0}
            onClick={() => navigate("/account", { state: { tab: 0 } })}
          >
            <InboxRoundedIcon style={{ width: "20px", height: "20px" }} />
            Os meus casos
          </MenuButton>
          <MenuButton
            tab={tab}
            index={1}
            onClick={() => navigate("/account", { state: { tab: 1 } })}
          >
            <AccountBoxRoundedIcon style={{ width: "20px", height: "20px" }} />
            Conta
          </MenuButton>
          <MenuButton
            tab={tab}
            index={2}
            onClick={() => navigate("/account", { state: { tab: 2 } })}
          >
            <LocalMallRoundedIcon style={{ width: "20px", height: "20px" }} />
            Compras
          </MenuButton>
        </MenuBox>
      </AccountTopMenu>
    </>
  );
}
