import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { COLORS } from "../../assets/theme";
import { AddToCartMinusPlusProps } from "../../utils/Interfaces/Cart";
import { ActionIconButton, AddToCartMinusPlusBox } from "./styles";
import { BoldMediumText } from "../../utils/GlobalStyles";

function AddToCartMinusPlus(props: AddToCartMinusPlusProps) {
  return (
    <AddToCartMinusPlusBox caseCartToBuy={props.caseCartToBuy}>
      <ActionIconButton onClick={props.handleRemoveItemToCart}>
        {props.quantity > 1 ? (
          <RemoveRoundedIcon
            sx={{ width: "18px", height: "18px", color: COLORS.black }}
          />
        ) : (
          <DeleteOutlineIcon
            sx={{ width: "18px", height: "18px", color: COLORS.black }}
          />
        )}
      </ActionIconButton>
      <BoldMediumText>{props.quantity}</BoldMediumText>
      <ActionIconButton onClick={props.handleAddItemToCart}>
        <AddRoundedIcon
          sx={{ width: "18px", height: "18px", color: COLORS.black }}
        />
      </ActionIconButton>
    </AddToCartMinusPlusBox>
  );
}

export default AddToCartMinusPlus;
