import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import {
  NoOrdersBox,
  OrdersTableComp,
  OrdersTableWrapper,
  TDBody,
  THBody,
  THHeader,
  TRBody,
} from "./styles";
import {
  BoldSmallText,
  MainButton,
  MediumText,
  SecondaryButton,
  SmallText,
} from "../../../utils/GlobalStyles";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { OrderType, OrdersTableProps } from "../../../utils/Interfaces/Account";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../../assets/theme";
import { Box } from "@mui/material";
import { getData } from "../../../utils/endpoints/api";
import SmallLoader from "../../loaders/SmallLoader";

export default function OrdersTable({ setOrderDetails }: OrdersTableProps) {
  const { user, setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  const {
    data: orders,
    error: ordersError,
    isLoading,
  } = useQuery<OrderType[], Error>({
    queryKey: ["orders"],
    queryFn: () => getData("order/list", { userId: user?.id! }),
    enabled: !!user,
  });

  useEffect(() => {
    if (ordersError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter a lista de compras. Tenta mais tarde!",
      ]);
    }
  }, [ordersError]);

  const numberOfItems = 5;
  const [showItems, setShowItems] = useState(numberOfItems);
  const handleShowMore = () => setShowItems(showItems + numberOfItems);

  return isLoading ? (
    <SmallLoader />
  ) : orders && orders?.length > 0 ? (
    <>
      <OrdersTableWrapper>
        <OrdersTableComp>
          <thead style={{ backgroundColor: COLORS.yellowOne }}>
            <tr>
              <THHeader>
                <SmallText>Nº encomenda</SmallText>
              </THHeader>
              <THHeader scope="col">
                <SmallText>Data</SmallText>
              </THHeader>
              <THHeader scope="col">
                <SmallText>ID transação</SmallText>
              </THHeader>
              <THHeader scope="col">
                <SmallText>Valor</SmallText>
              </THHeader>
            </tr>
          </thead>
          <tbody>
            {orders?.slice(0, showItems).map((props, key) => (
              <TRBody key={key}>
                <THBody scope="row">
                  <button
                    type="button"
                    onClick={() => setOrderDetails(props.id)}
                  >
                    <BoldSmallText sx={{ textDecoration: "underline" }}>
                      {props.id}
                    </BoldSmallText>
                  </button>
                </THBody>
                <TDBody>
                  <SmallText>{props.orderDate}</SmallText>
                </TDBody>
                <TDBody>
                  <SmallText>{props.paymentIntentId}</SmallText>
                </TDBody>
                <TDBody>
                  <SmallText>€{Number(props.totalAmount)}</SmallText>
                </TDBody>
              </TRBody>
            ))}
          </tbody>
        </OrdersTableComp>
      </OrdersTableWrapper>
      {showItems < orders.length && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SecondaryButton onClick={handleShowMore}>
            <SmallText color={`${COLORS.white} !important`}>Ver mais</SmallText>
          </SecondaryButton>
        </Box>
      )}
    </>
  ) : (
    <NoOrdersBox>
      <MediumText mt={5}>
        Ainda não fizeste nenhuma compra. De que estás à espera detetive?
      </MediumText>
      <NavLink to="/cases">
        <MainButton sx={{ marginTop: 2 }}>
          <BoldSmallText>Comprar agora!</BoldSmallText>
        </MainButton>
      </NavLink>
    </NoOrdersBox>
  );
}
