import { Dispatch, SetStateAction } from "react";

var AWS = require("aws-sdk");
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

export async function registerToDynamoDB(
  email: string,
  setNewsletterRegistedSuccessfully: Dispatch<
    SetStateAction<boolean | null>
  > | null,
) {
  const dynamoDB = new AWS.DynamoDB({ apiVersion: "2012-08-10" });
  var params = {
    Item: {
      email: {
        S: email,
      },
    },
    TableName: "desarquivados-newsletter",
  };
  try {
    const data = await dynamoDB.putItem(params).promise();
    if (setNewsletterRegistedSuccessfully) {
      setNewsletterRegistedSuccessfully(true);
    }
  } catch (err) {
    if (setNewsletterRegistedSuccessfully) {
      setNewsletterRegistedSuccessfully(false);
    }
    console.log("Error: ", err);
  }
}

export async function removeFromDynamoDB(
  email: string,
  setNewsletterRemovedSuccessfully: Dispatch<
    SetStateAction<boolean | null>
  > | null,
) {
  const dynamoDB = new AWS.DynamoDB({ apiVersion: "2012-08-10" });

  var params = {
    Key: {
      email: {
        S: email,
      },
    },
    TableName: "desarquivados-newsletter",
  };

  try {
    const data = await dynamoDB.deleteItem(params).promise();
    if (setNewsletterRemovedSuccessfully) {
      setNewsletterRemovedSuccessfully(true);
    }
  } catch (err) {
    if (setNewsletterRemovedSuccessfully) {
      setNewsletterRemovedSuccessfully(false);
    }
    console.log("Error: ", err);
  }
}

export async function checkItem(email: string) {
  const dynamoDB = new AWS.DynamoDB({ apiVersion: "2012-08-10" });

  const params = {
    Key: {
      email: {
        S: email,
      },
    },
    TableName: "desarquivados-newsletter",
  };

  try {
    const response = await dynamoDB.getItem(params).promise();
    return response?.Item ? 1 : 0;
  } catch (err) {
    console.log("Error: ", err);
  }
}
