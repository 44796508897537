import { Box, Button, styled } from "@mui/material";
import { COLORS } from "../../../assets/theme";

export const AuthMainContent = styled(Box)`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  margin: 25px auto;

  @media (min-width: 1024px) {
    width: 60%;
  }
`;

export const InputBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 15px;
`;

export const FormBox = styled(Box)`
  width: 100%;
  margin: 0 auto;
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const ForgotPasswordBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 12px;
`;

export const PasswordCheckerBox = styled(Box)`
  text-align: left;
`;

export const TermsAndConditionsBox = styled(Box)`
  display: flex;
  flex-flow: column;
  margin-top: 5px;
`;

export const TermsAndConditionsLink = styled("a")`
  font-weight: 700;
  &:hover {
    color: ${COLORS.blueFour};
    text-decoration: underline;
  }
`;
