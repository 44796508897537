import { Box, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const CharacterStoryCardWrapper = styled(Box)`
  margin: 0 20px;
`;

export const CharacterStoryCardBox = styled(Box)`
  display: flex;
  flex-flow: column;
  position: relative;
`;

export const UpperSectionBox = styled(Box)`
  display: flex;
  gap: 10px;
  padding-left: 15px;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  z-index: 1;
`;

export const Image = styled("img")`
  height: 150px;
  border-radius: 6px;
  width: 150px;
  object-fit: cover;
  object-position: top;
`;

export const TextInfoBox = styled(Box)`
  display: flex;
  flex-flow: column;
`;

export const TextCard = styled(Box)`
  display: flex;
  padding: 60px 15px 15px 15px;
  background-color: ${PALETTE.primaryOne};
  border-radius: 6px;
  margin-top: 110px;
  z-index: 0;
  width: 100%;
`;
