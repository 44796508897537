import { Box, Divider, styled } from "@mui/material";
import { COLORS } from "../../../assets/theme";

export const CheckoutDetailsBox = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  width: 100%;
  flex-flow: column;
`;

export const CheckoutBoxUpperSection = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const CheckoutBoxLowerSection = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 8px;
  justify-content: space-between;
`;

export const SubDividerSection = styled(Divider)`
  margin: 20px 0;
  border-color: ${COLORS.grayTwo};
`;
