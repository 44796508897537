import { Box, DialogTitle, styled } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { COLORS } from "../../../../../../assets/theme";

export const DialogTitleWrapper = styled(DialogTitle)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.blueFour};
`;

export const CloseRoundedIconWrapper = styled(CloseRoundedIcon)`
  height: 30px;
  width: 30px;
  color: ${COLORS.white};
`;
