import { Box, styled } from "@mui/material";

export const BoughtCasesBox = styled(Box)`
  display: flex;
  flex-flow: column;
  margin: 40px 15px;
  align-items: center;
`;

export const CasesWrapper = styled(Box)`
  display: flex;
  flex-flow: wrap;
  margin-top: 2.5rem;
  column-gap: 5rem;
  row-gap: 2.5rem;
  max-width: calc(900px + 10rem);
`;

export const WatchMoreBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`;
