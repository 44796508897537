import { Navigate, Route, Routes } from "react-router-dom";
import CaseGamePage from "../../components/CaseGame/CaseGamePage";
import CantAccess from "../../components/CaseGame/CantAccess";
import PrivateGame from "../PrivateGame";
import { MyCaseAccessType } from "../../utils/Interfaces/Game";

export default function CaseGame() {
  return (
    <Routes>
      <Route path="/*" element={<CantAccess reason="Caso não encontrado." />} />
      <Route
        path="/artist-death/:orderItemId"
        element={
          <PrivateGame>
            <CaseGamePage caseData={{} as MyCaseAccessType} />
          </PrivateGame>
        }
      />
    </Routes>
  );
}
