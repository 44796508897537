import { Box, styled } from "@mui/material";
import Slider from "react-slick";
import {
  LargeText,
  MainButton,
  MediumText,
  SecondaryButton,
  TitleLargeText,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import { COLORS } from "../../assets/theme";

export const CarouselSliderBox = styled(Box)`
  height: fit-content;
  width: 100vw;
  margin-left: -20px;

  .slick-dots {
    bottom: 0px;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
`;

export const LandingItemTextBoxOne = styled(Box)`
  position: absolute;
  left: 50%;
  width: 90%;
  top: 47%;
  text-align: center;
  transform: translate(-50%, 0%);

  @media (min-width: 450px) {
    width: 80%;
  }

  @media (min-width: 700px) {
    width: 50%;
  }

  @media (min-width: 700px) {
    text-align: left;
    top: 47%;
    left: 40%;
    transform: translate(0%, -50%);
  }
`;

export const LandingItemOneTitle = styled(TitleLargeText)`
  font-size: 1.35rem;
  white-space: nowrap;
  color: ${COLORS.black};

  @media (min-width: 400px) {
    font-size: 1.45rem;
  }

  @media (min-width: 500px) {
    white-space: normal;
    font-size: 1.8rem;
  }

  @media (min-width: 600px) {
    font-size: 2.1rem;
  }

  @media (min-width: 700px) {
    font-size: 2.1rem;
    line-height: 1;
  }

  @media (min-width: 850px) {
    margin-right: 50%;
    font-size: 2.5rem;
  }

  @media (min-width: 1000px) {
    font-size: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 3.5rem;
    margin-right: 40%;
  }
`;

export const LandingItemOneText = styled(MediumText)`
  font-size: 0.9rem;

  @media (min-width: 400px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.1rem;
  }

  @media (min-width: 950px) {
    font-size: 1.3rem;
  }
`;

export const LandingItemOneActionButton = styled(SecondaryButton)`
  margin: 5px auto 0 auto;

  @media (min-width: 500px) {
    margin: 15px auto 0 auto;
  }

  @media (min-width: 700px) {
    margin: 5px 0 0 0;
  }

  @media (min-width: 900px) {
    margin: 10px 0 0 0;
  }
`;

export const LandingItemTextBoxTwo = styled(Box)`
  position: absolute;
  top: 50%;
  left: 5%;
  width: 45%;
  text-align: center;
  transform: translate(0%, -50%);

  @media (min-width: 500px) {
    width: 40%;
  }

  @media (min-width: 700px) {
    width: 40%;
    top: 45%;
    left: 59%;
    transform: translate(0%, -50%);
  }

  @media (min-width: 750px) {
    left: 57%;
  }
`;

export const LandingItemTwoTitle = styled(TitleMediumText)`
  color: ${COLORS.white};
  margin: 0 20px;
  font-size: 1.4rem;

  @media (min-width: 500px) {
    font-size: 1.6rem;
  }

  @media (min-width: 600px) {
    font-size: 1.8rem;
  }

  @media (min-width: 800px) {
    margin: 0 50px;
  }

  @media (min-width: 1100px) {
    font-size: 2rem;
  }

  @media (min-width: 1250px) {
    font-size: 2.5rem;
  }
`;

export const LandingItemTwoText = styled(MediumText)`
  color: ${COLORS.white};
  font-size: 0.9rem;

  @media (min-width: 400px) {
    font-size: 1rem;
  }

  @media (min-width: 500px) {
    font-size: 1.1rem;
  }

  @media (min-width: 700px) {
    font-size: 0.9rem;
  }

  @media (min-width: 900px) {
    font-size: 1rem;
  }

  @media (min-width: 1100px) {
    font-size: 1.1rem;
  }

  @media (min-width: 1250px) {
    font-size: 1.3rem;
  }
`;

export const LandingItemTwoActionButton = styled(SecondaryButton)`
  margin: 5px auto 0 auto;

  @media (min-width: 500px) {
    margin: 15px auto 0 auto;
  }

  @media (min-width: 700px) {
    margin: 5px auto 0 auto;
  }

  @media (min-width: 900px) {
    margin: 10px auto 0 auto;
  }
`;

export const LandingItemTextBoxThree = styled(Box)`
  top: 62%;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;

  @media (min-width: 450px) {
    top: 65%;
  }

  @media (min-width: 700px) {
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
  }
`;

export const LandingItemThreeText = styled(TitleLargeText)`
  color: ${COLORS.white};
  white-space: nowrap;
  font-size: 2.2rem;

  @media (min-width: 450px) {
    font-size: 2.5rem;
  }

  @media (min-width: 540px) {
    font-size: 3rem;
  }

  @media (min-width: 900px) {
    font-size: 3.5rem;
  }

  @media (min-width: 900px) {
    font-size: 3.5rem;
  }

  @media (min-width: 1100px) {
    font-size: 4.5rem;
  }
`;

export const LandingItemThreeActionButton = styled(MainButton)`
  margin: 0 auto;
  padding: 10px 20px;

  @media (min-width: 1100px) {
    margin: 0;
  }
`;
