import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  BoldLargeText,
  BoldSmallText,
  MainButton,
  SmallText,
} from "../../../utils/GlobalStyles";
import { Transition } from "../../../utils/Transitions";
import { CaseReviewSubmittedProps } from "../../../utils/Interfaces/Case";
import { COLORS } from "../../../assets/theme";

function CaseReviewSubmittedModal({
  caseReviewSubmitted,
  handleModalClose,
}: CaseReviewSubmittedProps) {
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={caseReviewSubmitted}
      onClose={handleModalClose}
      fullWidth
    >
      <DialogTitle my={"10px"}>
        <BoldLargeText>Review submetida com sucesso. </BoldLargeText>
      </DialogTitle>
      <DialogContent>
        <SmallText>
          Obrigado! O teu feedback é muito importante para nós!
        </SmallText>
      </DialogContent>
      <DialogActions style={{ gap: "10px", margin: "20px" }}>
        <MainButton onClick={handleModalClose}>
          <BoldSmallText color={`${COLORS.white} !important`}>
            Fechar
          </BoldSmallText>
        </MainButton>
      </DialogActions>
    </Dialog>
  );
}

export default CaseReviewSubmittedModal;
