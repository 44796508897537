import {
  CarouselSliderBox,
  LandingItemOneActionButton,
  LandingItemOneText,
  LandingItemOneTitle,
  LandingItemTextBoxOne,
  LandingItemTextBoxThree,
  LandingItemTextBoxTwo,
  LandingItemThreeActionButton,
  LandingItemThreeText,
  LandingItemTwoActionButton,
  LandingItemTwoText,
  LandingItemTwoTitle,
  StyledSlider,
} from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingSliderItem from "./LandingSliderItem";
import { useMediaQuery } from "@mui/material";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  SecondaryButton,
} from "../../utils/GlobalStyles";
import { COLORS } from "../../assets/theme";
import { NavLink } from "react-router-dom";

function LandingItem() {
  const desktop = useMediaQuery("(min-width: 700px)");

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 750,
    arrows: false,
    pauseOnHover: true,
    initialSlide: 0,
    className: "slides",
  };

  return (
    <CarouselSliderBox>
      <StyledSlider {...settings}>
        <LandingSliderItem
          text={
            <LandingItemTextBoxOne>
              <LandingItemOneTitle>
                Resolve Casos Arquivados!
              </LandingItemOneTitle>
              <LandingItemOneText>
                Precisamos da tua ajuda para resolver estes crimes ainda por
                desvendar! Junta a tua equipa de detetives e descobre os
                culpados!
              </LandingItemOneText>
              <NavLink to="/cases">
                <LandingItemOneActionButton>
                  <BoldMediumText color={`${COLORS.white} !important`}>
                    Ver casos
                  </BoldMediumText>
                </LandingItemOneActionButton>
              </NavLink>
            </LandingItemTextBoxOne>
          }
          imageUrl={
            desktop
              ? require("../../assets/images/banners/banner_1_desktop.png")
              : require("../../assets/images/banners/banner_1_mobile.png")
          }
        />
        <LandingSliderItem
          text={
            <LandingItemTextBoxTwo>
              <LandingItemTwoTitle>A Morte do Artista</LandingItemTwoTitle>
              <LandingItemTwoText>
                Um conceituado artista é encontrado morto no seu atelier. Terá
                sido um assalto mal sucedido ou um homicídio premeditado?
              </LandingItemTwoText>
              <NavLink to="/cases/artist-death">
                <LandingItemTwoActionButton>
                  <BoldMediumText color={`${COLORS.white} !important`}>
                    Ver caso
                  </BoldMediumText>
                </LandingItemTwoActionButton>
              </NavLink>
            </LandingItemTextBoxTwo>
          }
          imageUrl={
            desktop
              ? require("../../assets/images/banners/banner_2_desktop.png")
              : require("../../assets/images/banners/banner_2_mobile.png")
          }
        />
        <LandingSliderItem
          text={
            <LandingItemTextBoxThree>
              <LandingItemThreeText>EM BREVE!</LandingItemThreeText>
              <NavLink to="/cases">
                <LandingItemThreeActionButton>
                  <BoldMediumText color={`${COLORS.white} !important`}>
                    Ver outros casos
                  </BoldMediumText>
                </LandingItemThreeActionButton>
              </NavLink>
            </LandingItemTextBoxThree>
          }
          imageUrl={
            desktop
              ? require("../../assets/images/banners/banner_3_desktop.png")
              : require("../../assets/images/banners/banner_3_mobile.png")
          }
        />
      </StyledSlider>
    </CarouselSliderBox>
  );
}

export default LandingItem;
