import { Box, styled } from "@mui/material";
import { COLORS } from "../../assets/theme";

export const ProfileWrapper = styled(Box)`
  overflow: hidden;
  border-radius: 8px;
  margin: 25px 0;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: ${COLORS.white};
`;

export const RowBox = styled(Box)`
  padding: 12px 16px;
  border-top: 1px solid ${COLORS.grayTwo};

  @media (min-width: 768px) {
    padding: 20px 24px;
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const RowsWrapper = styled(Box)`
  padding: 0;
`;

export const RowRightField = styled(Box)`
  margin-top: 4px;

  @media (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const PasswordRightFieldWrapper = styled(Box)`
  margin-top: 10px;
  padding-bottom: 12px;

  @media (min-width: 768px) {
    margin-top: 4px;
  }
`;

export const PasswordRightFieldBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const DeleteAccountButton = styled("button")`
  p {
    color: ${COLORS.grayFive} !important;
  }

  &:hover {
    p {
      text-decoration: underline;
      color: ${COLORS.blueFour} !important;
    }
  }
`;

export const NewsletterBox = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;
