import { Link } from "@mui/material";
import {
  BoldLargeText,
  BoldMediumText,
  MediumText,
  TitleSmallText,
} from "../../utils/GlobalStyles";
import { PrivacyTermsWrapper } from "./styles";

function PrivacyTerms() {
  return (
    <PrivacyTermsWrapper>
      <TitleSmallText mb={1} textAlign="center">
        {" "}
        Política de Privacidade{" "}
      </TitleSmallText>
      <br />
      <br />
      <BoldLargeText>1. Introdução</BoldLargeText>
      <MediumText>
        O site desarquivados.com pode ser consultado sem que o utilizador tenha
        de fornecer informações pessoais. No entanto, algumas funcionalidades
        apenas estão disponíveis após a criação de conta , com disponibilização
        de dados pessoais.
      </MediumText>
      <br />
      <MediumText>
        Esta política descreve como os DESARQUIVADOS trata os dados pessoais
        fornecidos pelo utilizador quando este utiliza o site desarquivados.com,
        de acordo com as regras do Regulamento (UE) 2016/679 de 27 de abril de
        2016 (Regulamento Geral de Proteção de Dados Pessoais) e da Lei nº
        58/2019 de 8 de agosto.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>2. Informações Recolhidas</BoldLargeText>
      <br />
      <BoldMediumText>2.1 Informações de Registo</BoldMediumText>{" "}
      <MediumText>
        Quando cria uma conta, recolhemos informações como nome, endereço de
        e-mail e data de nascimento.
      </MediumText>
      <br />
      <BoldMediumText>2.2 Informações de Compra</BoldMediumText>{" "}
      <MediumText>
        Recolhemos detalhes das transações realizadas no Site, incluindo
        produtos adquiridos, valor pago e método de pagamento.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>3. Tratamento de dados</BoldLargeText>
      <MediumText>
        Os dados recolhidos no site desarquivados.com serão tratados pela equipa
        dos DESARQUIVADOS, marca da empresa Business Endeavours, Lda, e apenas
        para as utilizações descritas no ponto seguinte.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>4. Utilização das Informações</BoldLargeText>
      <br />
      <BoldMediumText>4.1 Prestação de Serviços</BoldMediumText>{" "}
      <MediumText>
        Utilizamos as suas informações para fornecer, manter e melhorar os
        nossos serviços, processar transações e enviar comunicações relacionadas
        com a sua utilização do Site.
      </MediumText>
      <br />
      <BoldMediumText>4.2 Comunicações de Marketing</BoldMediumText>{" "}
      <MediumText>
        Podemos usar as suas informações para enviar promoções e atualizações
        sobre os nossos serviços. Pode optar por não receber essas comunicações
        a qualquer momento.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>5. Partilha de Informações</BoldLargeText>
      <br />
      <BoldMediumText>5.1 Terceiros</BoldMediumText>{" "}
      <MediumText>
        Não partilhamos as suas informações pessoais com terceiros, exceto
        conforme necessário para processar as suas transações e cumprir
        requisitos legais.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>6. Segurança</BoldLargeText>
      <MediumText>
        Implementamos medidas de segurança para proteger as suas informações
        contra acesso não autorizado, alteração, divulgação ou destruição.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>7. Os Seus Direitos</BoldLargeText>
      <MediumText>
        Tem o direito de aceder, corrigir ou eliminar as suas informações
        pessoais. Para exercer esses direitos, entre em contacto através de
      </MediumText>
      <br />
      <br />
      <BoldLargeText>8. Alterações à Política de Privacidade</BoldLargeText>
      <MediumText>
        Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer
        alterações serão publicadas no Site com a data de revisão atualizada.
      </MediumText>
      <br />
      <br />
      <BoldLargeText>9. Contacto</BoldLargeText>
      <MediumText>
        Se tiver dúvidas sobre esta Política de Privacidade, entre em contacto
        connosco através de{" "}
        <Link href="mailto: info@desarquivados.com">
          info@desarquivados.com
        </Link>{" "}
        .
      </MediumText>
    </PrivacyTermsWrapper>
  );
}

export default PrivacyTerms;
