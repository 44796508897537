import { Box, IconButton, styled } from "@mui/material";
import { COLORS } from "../../assets/theme";
import { SmallText } from "../../utils/GlobalStyles";

export const CartItemBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-flow: row;
  margin-top: 10px;
  gap: 50px;
`;

export const ImageTextBox = styled(Box)`
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
`;

export const SummaryText = styled(SmallText)`
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const CartItemImage = styled("img")`
  border-radius: 6px;
  object-position: top;
  object-fit: cover;
  width: 90px;
  height: 75px;

  @media (min-width: 500px) {
    width: 120px;
    height: 100px;
  }
`;

export const ItemQuantityWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 599px) {
    height: fit-content;
  }
`;

export const CartActionIconBox = styled(IconButton)`
  height: 26px;
  width: 26px;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid ${COLORS.grayTwo};
`;

export const CartItemContentLeftSection = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 6px;
`;

export const CartItemContentRightSectionDesktop = styled(Box)`
  display: none;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 600px) {
    display: flex;
  }
`;

export const CartItemContentRightSectionMobile = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const RightContentBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const DiscountBox = styled(Box)`
  padding: 3px 8px;
  background-color: ${COLORS.orangeOne};
  border-radius: 6px;
`;
