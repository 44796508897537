import { useContext } from "react";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { NumberItems, ShoppingCartIconNavbarWrapper } from "./styles";
import { NavLink } from "react-router-dom";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";

function ShoppingCartIconNavbar() {
  const { user, cart } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  return (
    <ShoppingCartIconNavbarWrapper>
      <NavLink to="/shopping-cart">
        <ShoppingCartRoundedIcon style={{ width: "24px", height: "24px" }} />
        {cart?.items && cart?.items?.length > 0 && (
          <NumberItems>{cart?.items?.length}</NumberItems>
        )}
      </NavLink>
    </ShoppingCartIconNavbarWrapper>
  );
}

export default ShoppingCartIconNavbar;
