import { autoSignIn, confirmSignUp } from "aws-amplify/auth";
import { FormEvent, MouseEvent, useContext, useEffect, useState } from "react";
import {
  AuthenticationContext,
  GeneralAppDataContext,
} from "../../../../utils/GeneralContext";
import { AuthMainContent, FormBox } from "../styles";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  TitleMediumText,
} from "../../../../utils/GlobalStyles";
import OTP from "../OTP";
import {
  AuthenticationContextType,
  GeneralAppDataContextType,
} from "../../../../utils/Interfaces/Global";
import { registerToDynamoDB } from "../../../../utils/Functions/DynamoDBActions";
import { COLORS } from "../../../../assets/theme";
import { generatePortugueseError } from "../../../../utils/Functions/CognitoErrorTranslator";

function ConfirmSignUp() {
  const { setIsLoading, setIsAuthenticated } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const {
    username,
    setStep,
    extraStepData,
    cognitoMessage,
    setCognitoMessage,
  } = useContext(AuthenticationContext) as AuthenticationContextType;
  const [formReady, setFormReady] = useState(false);
  const [code, setCode] = useState("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    function checkFields() {
      if (code !== "" && code.length === 6 && /^\d+$/.test(code)) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
    checkFields();
  }, [code]);

  async function handleConfirmSignup(
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    if (formReady) {
      setIsLoading(true);
      await confirmSignUp({
        username: username,
        confirmationCode: code,
      })
        .then(async ({ isSignUpComplete }) => {
          if (isSignUpComplete) {
            if (extraStepData === "RegisterNewsletter") {
              registerToDynamoDB(username, null);
            }
            setSuccessMessage("A conta foi confirmada.");
            await autoSignIn();
            setIsAuthenticated(true);
          }
          setIsLoading(false);
        })
        .catch((error: Error) => {
          setCognitoMessage(generatePortugueseError(error));
          setIsLoading(false);
        });
    }
  }

  return (
    <AuthMainContent>
      <TitleMediumText> Recebeste um código no email</TitleMediumText>
      <MediumText mt={1} mb={3}>
        Confirma a tua conta ao inserires o código que recebeste no teu email.
      </MediumText>
      <FormBox>
        <BoldMediumText mb={3} sx={{ display: "flex", gap: "5px" }}>
          Email:<MediumText>{username}</MediumText>
        </BoldMediumText>
        <OTP
          separator={<span> </span>}
          value={code}
          onChange={setCode}
          length={6}
        />
        <BoldSmallText mt={3} textAlign="center">
          Verifica a pasta de spam, caso não recebas nada.
        </BoldSmallText>
        <MainButton
          sx={{ margin: "20px 0" }}
          fullWidth
          disabled={!formReady}
          onClick={handleConfirmSignup}
        >
          <BoldSmallText>Iniciar sessão</BoldSmallText>
        </MainButton>
        {cognitoMessage && (
          <BoldSmallText color={`${COLORS.redTwo} !important`}>
            {cognitoMessage}
          </BoldSmallText>
        )}
        {successMessage && (
          <BoldSmallText color={`${COLORS.greenOne} !important`}>
            {successMessage}
          </BoldSmallText>
        )}
      </FormBox>
    </AuthMainContent>
  );
}

export default ConfirmSignUp;
