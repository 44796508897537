import React from "react";

import OrdersTable from "./OrdersTable";
import OrderDetails from "./OrdersTable/OrderDetails";

function Orders() {
  const [orderDetails, setOrderDetails] = React.useState<number>(0);

  return (
    <>
      {orderDetails === 0 ? (
        <OrdersTable setOrderDetails={setOrderDetails} />
      ) : (
        <OrderDetails
          orderId={orderDetails}
          setOrderDetails={setOrderDetails}
        />
      )}
    </>
  );
}

export default Orders;
