import {
  FooterBox,
  FooterLeftBox,
  FooterWrapper,
  OtherCasesButton,
} from "./styles";
import NewsFooter from "../../../NewsFooter";
import { SmallText } from "../../../../utils/GlobalStyles";
import { NavLink, useLocation } from "react-router-dom";

export default function GameFooter() {
  const location = useLocation();
  return (
    <FooterWrapper>
      <NewsFooter />
      <FooterBox>
        <FooterLeftBox>
          <SmallText>Departamento de Investigação Criminal de Lisboa</SmallText>
          <span style={{ fontSize: "0.25rem" }}>{"\u2B24"}</span>
          <NavLink to="/cases" target="_blank">
            <OtherCasesButton>
              <SmallText sx={{ textDecoration: "underline" }}>
                Analisar outros casos
              </SmallText>
            </OtherCasesButton>
          </NavLink>
          <span style={{ fontSize: "0.25rem" }}></span>
        </FooterLeftBox>
        <SmallText>﹡CONFIDENCIAL﹡</SmallText>
      </FooterBox>
    </FooterWrapper>
  );
}
