import { Box, Button, styled } from "@mui/material";
import { COLORS } from "../../assets/theme";

export const CaseCardToPurchaseWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  width: 330px;
`;

export const AddToCartButton = styled(Button)`
  color: ${COLORS.white};
  padding: 8px 30px;
  background-color: ${COLORS.blueTwo};
  border-radius: 6px;
  white-space: nowrap;
  font-weight: 700;

  &:hover {
    background-color: ${COLORS.blueFour};
  }
`;

export const PriceBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Image = styled("img")`
  object-fit: cover;
  object-position: top;
  border-radius: 6px;
  width: 100%;
  height: 250px;
`;

export const DiscountBox = styled(Box)`
  padding: 4px 10px;
  background-color: ${COLORS.orangeOne};
  border-radius: 6px;
`;
