import {
  BrandLabelBox,
  FooterLowerSection,
  FooterLowerSectionLeftContent,
  FooterLowerSectionUL,
  FooterLowerWrapper,
  FooterUpperSection,
  FooterUpperSectionLeftContent,
  FooterUpperWrapper,
  LinkUL,
  NewsletterBox,
  NewsletterInputBox,
  SocialNetworksBox,
} from "./styles";
import {
  BoldMediumText,
  BoldSmallText,
  SecondaryButton,
  MediumText,
  SmallText,
} from "../../utils/GlobalStyles";
import { NavLink } from "react-router-dom";
import { Box, Link, TextField } from "@mui/material";
import { DictOfStringsType } from "../../utils/Interfaces/Global";
import validator from "validator";
import { registerToDynamoDB } from "../../utils/Functions/DynamoDBActions";
import React from "react";
import { COLORS } from "../../assets/theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const LINKS = [
  { ref: "about-us", title: "Sobre nós" },
  { ref: "cases", title: "Casos" },
  { ref: "payments", title: "Pagamentos" },
  { ref: "how-to-start", title: "FAQ" },
];

const SUB_LINKS = [
  { ref: "contact-us", title: "Contactos" },
  { ref: "terms-and-conditions", title: "Termos & Condições" },
  { ref: "privacy-terms", title: "Privacidade" },
];
const CURRENT_YEAR = new Date().getFullYear();

function Footer() {
  const [emailValid, setEmailValid] = React.useState<boolean>(false);
  const [newsletterEmail, setNewsletterEmail] = React.useState<string>("");
  const [newsletterRegistedSuccessfully, setNewsletterRegistedSuccessfully] =
    React.useState<boolean | null>(null);

  React.useEffect(() => {
    function checkFields() {
      if (validator.isEmail(newsletterEmail)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    checkFields();
  }, [newsletterEmail]);

  const handleRegisterEmailToDynamoDB = async () => {
    if (emailValid) {
      registerToDynamoDB(newsletterEmail, setNewsletterRegistedSuccessfully);
    }
  };

  return (
    <>
      <FooterUpperWrapper>
        <FooterUpperSection>
          <FooterUpperSectionLeftContent>
            <img
              style={{ margin: "-30px 0 20px 0" }}
              width={150}
              src={require("../../assets/images/desarquivados_secondary_logo.png")}
              alt="desarquivados logo"
            />
            <LinkUL>
              {LINKS?.map((link: DictOfStringsType, index) => (
                <li key={index}>
                  <NavLink to={link.ref}>
                    <SmallText marginRight="20px">{link.title}</SmallText>
                  </NavLink>
                </li>
              ))}
            </LinkUL>
          </FooterUpperSectionLeftContent>
          <NewsletterBox>
            <BoldMediumText mb={2}>Newsletter</BoldMediumText>
            <Box>
              <NewsletterInputBox>
                <TextField
                  sx={{ width: "100%", backgroundColor: "white" }}
                  size="small"
                  autoComplete="email"
                  label="Email"
                  variant="outlined"
                  placeholder="name@mail.com"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewsletterEmail(e?.target?.value)
                  }
                />
                <SecondaryButton
                  size="medium"
                  onClick={handleRegisterEmailToDynamoDB}
                  disabled={!emailValid}
                >
                  <BoldSmallText color={`${COLORS.white} !important`}>
                    Subscrever
                  </BoldSmallText>
                </SecondaryButton>
              </NewsletterInputBox>
              {newsletterRegistedSuccessfully !== null && (
                <SmallText
                  color={
                    newsletterRegistedSuccessfully
                      ? `${COLORS.greenTwo} !important`
                      : `${COLORS.redTwo} !important`
                  }
                  mt={1}
                >
                  {newsletterRegistedSuccessfully
                    ? "Email registado com sucesso"
                    : "Ocorreu um erro"}
                </SmallText>
              )}
            </Box>
          </NewsletterBox>
        </FooterUpperSection>
      </FooterUpperWrapper>
      <FooterLowerWrapper>
        <FooterLowerSection>
          <FooterLowerSectionLeftContent>
            <BrandLabelBox>
              &copy;
              <MediumText> {CURRENT_YEAR} Desarquivados</MediumText>
            </BrandLabelBox>
            <SocialNetworksBox>
              <Link href="https://www.facebook.com/profile.php?id=61560810073061" target="_blank">
              <FacebookIcon sx={{ color: "#1877F2" }} />
              </Link>
              <Link href="https://www.instagram.com/desarquivados?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
              <InstagramIcon sx={{ color: "#C13584" }} />
              </Link>
              </SocialNetworksBox>
          </FooterLowerSectionLeftContent>
          <FooterLowerSectionUL>
            {SUB_LINKS?.map((subLink: DictOfStringsType, index) => (
              <li key={index}>
                <NavLink to={subLink.ref}>
                  <SmallText marginLeft="20px">{subLink.title}</SmallText>
                </NavLink>
              </li>
            ))}
          </FooterLowerSectionUL>
        </FooterLowerSection>
      </FooterLowerWrapper>
    </>
  );
}

export default Footer;
