import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  BoldLargeText,
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  NoBackgroundButton,
} from "../../../utils/GlobalStyles";
import { Transition } from "../../../utils/Transitions";
import { ActivateCaseProps } from "../../../utils/Interfaces/Game";
import { COLORS } from "../../../assets/theme";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import { InfoRow } from "./styles";

function ActivateCaseModal({
  expirationLength,
  activateCase,
  handleActivateCase,
  handleModalClose,
}: ActivateCaseProps) {
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={activateCase}
      onClose={handleModalClose}
      fullWidth
    >
      <DialogTitle my={"10px"}>
        <BoldLargeText>Ativar caso</BoldLargeText>
      </DialogTitle>
      <DialogContent>
        <MediumText>Tens a certeza que queres ativar este caso?</MediumText>
        <InfoRow my={3}>
          <AccessTimeRoundedIcon
            sx={{ width: "20px", height: "20px", color: COLORS.graySix }}
          />
          <BoldMediumText sx={{ lineHeight: 1 }}>
            Uma vez ativado, não pode ser desativado, e expirará em{" "}
            {expirationLength} dias
          </BoldMediumText>
        </InfoRow>
        <InfoRow>
          <GroupAddRoundedIcon
            sx={{ width: "20px", height: "20px", color: COLORS.graySix }}
          />
          <MediumText sx={{ lineHeight: 1 }}>
            Vais poder adicionar membros à equipa quando iniciares o caso
          </MediumText>
        </InfoRow>
      </DialogContent>
      <DialogActions style={{ gap: "10px", margin: "20px" }}>
        <NoBackgroundButton onClick={handleModalClose}>
          <BoldSmallText color={`${COLORS.redTwo} !important`}>
            Cancelar
          </BoldSmallText>
        </NoBackgroundButton>
        <MainButton
          sx={{ backgroundColor: COLORS.greenTwo }}
          onClick={handleActivateCase}
        >
          <BoldSmallText color={`${COLORS.white} !important`}>
            Ativar
          </BoldSmallText>
        </MainButton>
      </DialogActions>
    </Dialog>
  );
}

export default ActivateCaseModal;
