import { Box, styled } from "@mui/material";
import { MainButton } from "../../utils/GlobalStyles";
import { COLORS } from "../../assets/theme";

export const Image = styled("img")`
  height: 400px;
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
  object-position: top;
`;

export const UpperContentSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentSection = styled(Box)`
  height: 305px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const LowerButtonBox = styled(Box)`
  height: 35px;
`;

export const WriteReviewButton = styled("button")`
  color: ${COLORS.black};
  white-space: nowrap !important;
  text-decoration: underline;

  &:hover {
    color: ${COLORS.blueFour};
  }
`;

export const BoughtCaseCardBox = styled(Box)`
  width: 300px;
`;

export const ActivateButton = styled(MainButton)`
  background-color: ${COLORS.greenOne};
  color: ${COLORS.white};

  &:hover {
    background-color: ${COLORS.greenTwo};
  }
`;
