import { Box, Button, Divider, styled, Typography } from "@mui/material";
import { PALETTE, COLORS } from "../../assets/theme";

interface ContentBoxProps {
  isCaseGameRoute: boolean;
}

export const MainContent = styled(Box)`
  background-color: ${COLORS.yellowOne};
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: relative;
`;

export const ContentBox = styled(Box)<ContentBoxProps>`
  padding: ${({ isCaseGameRoute }) => !isCaseGameRoute && "60px 20px"};
  flex: 1;
`;

export const ContentWrapper = styled(Box)`
  flex: 1;
  position: relative;
`;

export const TitleLargeText = styled(Typography)`
  font-family: "DesarquivadosRegular";
  font-weight: 700;
  word-spacing: 5px;
  color: ${COLORS.graySix};
  font-size: 2.5rem;
`;

export const TitleMediumText = styled(Typography)`
  font-family: "DesarquivadosRegular";
  word-spacing: 5px;
  font-weight: 700;
  color: ${COLORS.graySix};
  font-size: 2.1rem;
`;

export const TitleSmallText = styled(Typography)`
  font-family: "DesarquivadosRegular";
  letter-spacing: 1.5px;
  word-spacing: 5px;
  font-weight: 700;
  color: ${COLORS.graySix};
  font-size: 1.7rem;
`;

export const BoldLargeText = styled(Typography)`
  font-family: "BAHNSCHRIFT";
  font-weight: 600;
  color: ${COLORS.graySix};
  font-size: 1.4rem;
`;

export const BoldMediumText = styled(Typography)`
  font-family: "BAHNSCHRIFT";
  font-weight: 600;
  font-size: 1.1rem;
  white-space: nowrap;
`;

export const BoldSmallText = styled(Typography)`
  font-family: "BAHNSCHRIFT";
  font-weight: 600;
  color: ${COLORS.graySix};
  font-size: 0.8rem;
`;

export const LargeText = styled(Typography)`
  font-family: "BAHNSCHRIFT";
  font-weight: 200;
  color: ${COLORS.graySix};
  font-size: 1.4rem;
`;

export const MediumText = styled(Typography)`
  font-family: "BAHNSCHRIFT";
  font-weight: 200;
  color: ${COLORS.graySix};
  font-size: 1.1rem;
`;

export const SmallText = styled(Typography)`
  font-family: "BAHNSCHRIFT";
  font-weight: 200;
  text-align: left;
  color: ${COLORS.graySix};
  font-size: 0.8rem;
`;

export const MainButton = styled(Button)`
  color: ${COLORS.black};
  background-color: ${PALETTE.primaryThree};
  padding: 8px 30px;
  text-transform: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: ${COLORS.yellowFive};
  }

  &:disabled {
    opacity: 0.7;
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${COLORS.black};
  background-color: ${PALETTE.primaryTwo};
  padding: 8px 30px;
  text-transform: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: ${COLORS.redThree};
  }

  &:disabled {
    opacity: 0.7;
  }
`;

export const NoBackgroundButton = styled(Button)`
  color: ${COLORS.black};
  background-color: transparent;
  padding: 8px 20px;
  text-transform: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const UnderlinedButton = styled("button")`
  color: ${COLORS.black};
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap !important;

  &:hover {
    text-decoration: underline;
    color: ${COLORS.blueFour};
  }
`;

interface FullWidthFullHeightButtonProps {
  bgColor: string;
  textColor: string;
}

export const FullWidthFullHeightButton = styled(
  Button
)<FullWidthFullHeightButtonProps>`
  display: flex;
  border-radius: 0 !important;
  flex-wrap: nowrap;
  gap: 8px;
  color: ${({ textColor }) => textColor};
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  height: 100%;
  width: 100%;

  &:hover {
    background-color: ${COLORS.blueFour};
  }
`;

export const DividerSection = styled(Divider)`
  margin: 24px 0;
  width: 100%;
  border-color: ${COLORS.grayTwo};
`;
