import { Box, styled } from "@mui/material";

export const PaymentsWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;
`;

export const PaymentsBox = styled(Box)`
  display: grid;
  max-width: 1000px;
  gap: 20px;
  margin: 0 auto 0 auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 960px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 30px;
  }
`;

export const PaymentCard = styled(Box)`
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const Image = styled("img")`
  height: 100%;
  border-radius: 6px;
  width: 100%;
  object-fit: contain;
  height: 120px;
`;
