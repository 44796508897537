import { useContext, useEffect } from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { NavLink, useLocation } from "react-router-dom";
import { GeneralAppDataContext } from "../../../../utils/GeneralContext";
import {
  GameHeaderBox,
  GameHeaderLeftContent,
  GameHeaderRightContent,
  HeaderButton,
} from "./styles";
import {
  BoldSmallText,
  MainButton,
  MediumText,
  SmallText,
} from "../../../../utils/GlobalStyles";
import { Box, Divider } from "@mui/material";
import { GeneralAppDataContextType } from "../../../../utils/Interfaces/Global";
import {
  GameHeaderProps,
  InvitationType,
} from "../../../../utils/Interfaces/Game";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import { COLORS } from "../../../../assets/theme";
import InvitedTeamMembersHeader from "./InvitedTeamMembersHeader";
import { getData } from "../../../../utils/endpoints/api";
import { useQuery } from "@tanstack/react-query";

export default function GameHeader({
  caseData,
  handleModalOpen,
  handleTryAgain,
  handleAddTeamMemberModal,
  finished = false,
}: GameHeaderProps) {
  const { user, setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const location = useLocation();
  const isSharedCaseGame = location.pathname.startsWith("/shared-case");

  const { data: invitations, error: invitationsError } = useQuery<
    InvitationType[],
    Error
  >({
    queryKey: ["invitations"],
    queryFn: () =>
      getData("my-case/team-members", {
        userId: user?.id!,
        orderItemId: caseData?.orderItemId.toString()!,
      }),
  });

  useEffect(() => {
    if (invitationsError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter os membros da equipa. Tenta mais tarde!",
      ]);
    }
  }, [invitationsError]);

  return (
    <GameHeaderBox>
      <GameHeaderLeftContent>
        <img
          width={200}
          src={require("../../../../assets/cases-content/policia_portugal_white.png")}
          alt="agency logo"
        />
        <Box display="flex" alignItems="center">
          <MediumText color={`${COLORS.white} !important`}>Caso</MediumText>
          <MediumText
            color={`${COLORS.grayTwo} !important`}
            fontWeight="400 !important"
            mx={1}
          >
            {caseData?.caseNumber}
          </MediumText>
          <Divider
            sx={{
              margin: "0 5px",
              borderColor: COLORS.grayThree,
              height: "20px",
            }}
            orientation="vertical"
          />
          <MediumText
            ml={1}
            color={`${COLORS.white} !important`}
            fontWeight="700"
          >
            {!isSharedCaseGame ? (
              <>Detetive {user?.lastName}</>
            ) : (
              caseData?.invitedName
            )}
          </MediumText>
        </Box>
      </GameHeaderLeftContent>
      <GameHeaderRightContent>
        <>
          {!isSharedCaseGame && (
            <>
              {handleModalOpen &&
                !finished &&
                (invitations &&
                invitations.filter(
                  (invitation) => invitation.status === "ACCEPTED"
                ).length > 0 ? (
                  <InvitedTeamMembersHeader
                    handleAddTeamMemberModal={handleAddTeamMemberModal}
                    invitations={invitations.filter(
                      (invitation) => invitation.status === "ACCEPTED"
                    )}
                  />
                ) : (
                  <HeaderButton onClick={handleAddTeamMemberModal}>
                    <AddCircleOutlineRoundedIcon
                      sx={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <SmallText sx={{ height: "17px" }} className="text">
                      Equipa
                    </SmallText>
                  </HeaderButton>
                ))}
              {handleModalOpen &&
                (finished ? (
                  <MainButton onClick={handleModalOpen}>
                    <ReviewsRoundedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                    <BoldSmallText>Fazer review</BoldSmallText>
                  </MainButton>
                ) : (
                  <MainButton onClick={handleModalOpen}>
                    <MoveToInboxRoundedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                    <BoldSmallText>Submeter relatório</BoldSmallText>
                  </MainButton>
                ))}
            </>
          )}
          {handleModalOpen ? (
            <NavLink to={!isSharedCaseGame ? "/account" : "/cases"}>
              <HeaderButton variant="text">
                <SmallText className="text">Sair do caso</SmallText>
              </HeaderButton>
            </NavLink>
          ) : (
            <HeaderButton variant="text" onClick={handleTryAgain}>
              <SmallText className="text">Voltar a tentar</SmallText>
            </HeaderButton>
          )}
        </>
      </GameHeaderRightContent>
    </GameHeaderBox>
  );
}
