import { Box, styled } from "@mui/material";

export const LandingSliderItemWrapper = styled(Box)`
  width: 95vw;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;

  @media (min-width: 700px) {
    max-width: 1200px;
    width: 90vw;
  }
`;

export const LandingItemImage = styled("img")`
  object-fit: contain;
  border-radius: 6px;
`;

export const LandingItemTextBox = styled(Box)`
  top: 0;
  right: 0;
  position: absolute;
`;
