import { createContext } from "react";
import {
  AuthenticationContextType,
  GeneralAppDataContextType,
} from "../Interfaces/Global";
import { CheckoutContextType } from "../Interfaces/Payment";

export const GeneralAppDataContext =
  createContext<GeneralAppDataContextType | null>(null);

export const AuthenticationContext =
  createContext<AuthenticationContextType | null>(null);

export const CheckoutContext = createContext<CheckoutContextType>({
  clientSecret: null,
  setClientSecret: () => {},
  step: "",
  setStep: () => {},
});
