import { Box, Button, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../../assets/theme";

export const CaseToBuyWrapper = styled(Box)`
  margin: 0 auto;
  max-width: 1200px;
`;

export const TopRightContent = styled(Box)`
  display: flex;
  gap: 60px;
  flex-flow: column;

  @media (min-width: 900px) {
    gap: 40px;
    flex-flow: row;
  }

  @media (min-width: 1200px) {
    gap: 60px;
  }
`;

export const PriceButtonBox = styled(Box)`
  display: flex;
  gap: 20px;
  width: 270px;
  flex-flow: column;
`;

export const ProductImage = styled("img")`
  min-width: 350px;
  object-fit: contain;
  object-position: top;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-width: 500px;
`;

export const ProductTitleBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ShareButtonBox = styled(Box)`
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const ClassificationWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DiscountBox = styled(Box)`
  padding: 4px 8px;
  background-color: ${COLORS.orangeOne};
  border-radius: 6px;
`;

interface CustomBgButtonProps {
  textColor: string;
  bgColor: string;
}

export const CustomBgButton = styled(Button)<CustomBgButtonProps>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  text-transform: none;
  border-radius: 6px;

  &:hover {
    background-color: ${COLORS.blueFour};
  }
`;

export const PriceBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;

export const DescriptionRules = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 10px;
`;

export const DescriptionRulesItem = styled(Box)`
  display: flex;
  gap: 8px;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  flex-flow: column;

  @media (min-width: 900px) {
    flex-flow: row;
    gap: 40px;
  }
`;

export const ContentImageBox = styled(Box)`
  border-radius: 8px;
  border: 4px solid ${PALETTE.primaryThree};
  display: flex;
  width: 100% !important;
  height: auto;
  position: relative;

  @media (min-width: 570px) {
    width: 500px !important;
  }

  @media (min-width: 900px) {
    width: 400px !important;
    height: 340px;
  }

  @media (min-width: 1100px) {
    width: 500px !important;
    height: 425px;
  }
`;

export const ContentImage = styled("img")`
  opacity: 0.25;
  object-position: top;
  object-fit: cover;
`;

export const ContentTextBox = styled(Box)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;

  p {
    white-space: wrap;
  }
`;

export const CartBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;

  @media (min-width: 600px) {
    gap: 20px;
  }
`;
