import { Box } from "@mui/material";
import FAQs from "../../components/FAQS";
import MiniTutorial from "../MiniTutorial";
import ReactGA from "react-ga4";

export default function HowToStart() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/how-to-start",
      title: "How to start",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "How to start");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        gap: "100px",
        margin: "20px 0",
      }}
    >
      <MiniTutorial />
      <FAQs />
    </Box>
  );
}
