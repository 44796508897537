import { cloneElement, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { useQuery } from "@tanstack/react-query";
import CantAccess from "../../components/CaseGame/CantAccess";
import {
  MyCaseAccessType,
  PrivateGameProps,
} from "../../utils/Interfaces/Game";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import GameFinished from "../../components/CaseGame/GameFinished";
import { getData } from "../../utils/endpoints/api";
import BigLoader from "../../components/loaders/BigLoader";

const PrivateGame = ({ children }: PrivateGameProps) => {
  const { orderItemId } = useParams();
  const { user, setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  const {
    data: caseDetails,
    error: caseAccessError,
    isLoading,
  } = useQuery<MyCaseAccessType, Error>({
    queryKey: ["caseAccess"],
    queryFn: () =>
      getData("my-case/check-access/case", {
        userId: user?.id!,
        orderItemId: orderItemId!,
      }),
    enabled: !!orderItemId && !!user,
  });

  useEffect(() => {
    if (caseAccessError) {
      setWarningMessage([
        "error",
        "Houve um erro ao verificar o acesso ao caso. Tenta mais tarde!",
      ]);
    }
  }, [caseAccessError]);

  const PrivateGameFilter = () => {
    switch (caseDetails?.status || "") {
      case "ACTIVE":
        return <>{cloneElement(children, { caseData: caseDetails })}</>;
      case "COMPLETED":
        return (
          <GameFinished
            caseData={caseDetails}
            characters={caseDetails?.characters}
          />
        );
      case "EXPIRED":
        return <CantAccess reason="Este caso expirou." />;
      case "NOT_ACTIVATED":
        return <CantAccess reason="Este caso ainda não foi ativado." />;
      case "DENIED":
      default:
        return <CantAccess reason="Não tens acesso a este caso." />;
    }
  };

  return isLoading ? <BigLoader /> : <PrivateGameFilter />;
};

export default PrivateGame;
