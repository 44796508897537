import { Box, styled } from "@mui/material";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { PALETTE } from "../../assets/theme";

export const MiniTutorialWrapper = styled(Box)`
  max-width: 1200px;
  margin: 0 auto auto;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: 1200px) {
    padding: 0 60px;
  }
`;

export const StepsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 790px) {
    flex-flow: row;
  }
`;

export const ArrowStyledIcon = styled(ArrowRightRoundedIcon)`
  width: 50px;
  height: 50px;
  color: ${PALETTE.primaryTwo};

  @media (max-width: 789px) {
    transform: rotate(90deg);
  }
`;
