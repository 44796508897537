import { Box, styled } from "@mui/material";

export interface LocalLoaderDivProps {
  height: string;
}

export const LocalLoaderDiv = styled(Box)<LocalLoaderDivProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 2;
`;
