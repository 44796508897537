import { Box, Button, styled } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const GameHeaderBox = styled(Box)`
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: ${COLORS.blueFour};
`;

export const GameHeaderLeftContent = styled(Box)`
  display: flex;
  gap: 15px;
  align-items: center;
  height: 40px;
`;

export const GameHeaderRightContent = styled(Box)`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const HeaderButton = styled(Button)`
  gap: 5px;
  color: ${COLORS.white} !important;
  transition: color 0.15s ease-in-out;

  .text {
    transition: color 0.15s ease-in-out;
    color: ${COLORS.white} !important;
  }

  &:hover {
    color: ${COLORS.grayTwo} !important;
    .text {
      color: ${COLORS.grayTwo} !important;
    }
  }
`;
