import { Link, TextField } from "@mui/material";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  SecondaryButton,
  SmallText,
  TitleMediumText,
} from "../../utils/GlobalStyles";
import {
  FormBox,
  InviteHeaderFooterWrapper,
  InvitedUserFormBox,
  InvitedUserFormWrapper,
} from "./styles";
import { useContext, useEffect, useState } from "react";
import { COLORS, PALETTE } from "../../assets/theme";
import { NavLink, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData } from "../../utils/endpoints/api";
import SmallLoader from "../loaders/SmallLoader";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";

//   TODO: fix the any
export default function InvitedUserForm({ data }: any) {
  const { setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const { userId, orderItemId, sharedCaseId } = useParams();
  const [formReady, setFormReady] = useState(false);
  const [name, setName] = useState<string>("");
  const queryClient = useQueryClient();
  const acceptSharedCaseInviteMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("my-case/team-member/accept", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sharedCaseAccess"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao aceitar o convite. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    function checkFields() {
      if (name !== "" && name.length >= 3) {
        setFormReady(true);
      } else {
        setFormReady(false);
      }
    }
    checkFields();
  }, [name]);

  const handleSubmitForm = async () => {
    if (formReady && userId && orderItemId && sharedCaseId) {
      try {
        await acceptSharedCaseInviteMutation.mutateAsync({
          params: {
            userId: userId,
            orderItemId: orderItemId,
            sharedCaseId: sharedCaseId,
            invitedEmail: data.email,
            invitedName: name,
          },
          body: {},
        });
      } catch (error) {
        console.error("Error accepting team member invitation:", error);
      }
    }
  };

  const isLoading = acceptSharedCaseInviteMutation.isPending;

  return (
    <InvitedUserFormWrapper>
      <InviteHeaderFooterWrapper>
        <Link href="https://desarquivados.com" target="_blank">
          <img
            width={120}
            src={require("../../assets/images/desarquivados_secondary_logo.png")}
            alt="desarquivados logo"
          />
        </Link>
        <NavLink to="https://desarquivados.com/cases" target="_blank">
          <MainButton>
            <BoldSmallText>Quero resolver outros casos!</BoldSmallText>
          </MainButton>
        </NavLink>
      </InviteHeaderFooterWrapper>
      <InvitedUserFormBox>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <>
            <img
              width={120}
              src={require("../../assets/images/desarquivados_main_logo.png")}
              alt="desarquivados logo"
            />
            <TitleMediumText mt={4} textAlign="center">
              Convite para resolver um caso!
            </TitleMediumText>
            <BoldMediumText
              my={5}
              textAlign="center"
              sx={{ whiteSpace: "wrap" }}
            >
              Foste convidado por{" "}
              <span style={{ color: PALETTE.secondaryFour }}>
                {data?.userName}
              </span>{" "}
              para entrar na sua equipa e resolver o caso{" "}
              <span style={{ color: PALETTE.secondaryFour }}>
                {data?.caseName}
              </span>
            </BoldMediumText>
            <FormBox>
              <TextField
                size="small"
                label="Nome"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e?.target?.value)
                }
              />
              <SecondaryButton onClick={handleSubmitForm} disabled={!formReady}>
                <SmallText sx={{ color: COLORS.white }}>Resolver</SmallText>
              </SecondaryButton>
            </FormBox>
            <MediumText mt={2} textAlign="center">
              Por favor insere o teu nome e estás pronto para começar!
            </MediumText>
          </>
        )}
      </InvitedUserFormBox>
      <InviteHeaderFooterWrapper />
    </InvitedUserFormWrapper>
  );
}
