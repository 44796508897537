import { useNavigate } from "react-router-dom";
import PageHeader from "../../PageHeader";
import { CantAccessBox } from "./styles";
import { CantAccessProps } from "../../../utils/Interfaces/Game";
import React from "react";

export default function CantAccess({ reason }: CantAccessProps) {
  const navigate = useNavigate();
  const [seconds, setSeconds] = React.useState(5);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
        navigate("/account");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <CantAccessBox>
      <PageHeader
        section=""
        content={`Vais ser redirecionado em ${seconds} segundos...`}
        heading={reason}
      />
    </CantAccessBox>
  );
}
