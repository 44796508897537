import { Box, Button, styled } from "@mui/material";
import { PALETTE } from "../../assets/theme";

export const FAQWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
`;

export const FAQBox = styled(Box)`
  max-width: 1200px;

  a {
    color: ${PALETTE.primaryTwo} !important;
    text-decoration: underline;
  }
`;
