import { styled, Box, Button } from "@mui/material";
import { COLORS } from "../../../../assets/theme";

export const GameLeftContentWrapper = styled(Box)`
  width: 25%;
  min-width: 320px;
  text-align: center;
  background-color: ${COLORS.grayOne};
  border-right: 1.5px solid ${COLORS.grayFour};
`;

export const ContentSectionWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  height: calc(100% - 50px);
  padding: 30px;
`;

export const BottomSectionWrapper = styled(Box)`
  display: flex;
  height: 50px;
  flex-flow: column;
  align-items: center;
`;

export const FoldersBox = styled(Box)`
  display: flex;
  flex-flow: column;
  gap: 6px;
  width: 100%;
  margin-bottom: 20px;
`;

interface FolderButtonProps {
  active: boolean;
}

export const FolderButton = styled(Button)<FolderButtonProps>`
  display: flex;
  padding: 8px 15px;
  width: 100%;
  height: 40px;
  gap: 10px;
  color: ${COLORS.white};
  align-items: center;
  justify-content: start;
  border: 1px solid gray;
  background-color: ${({ active }) => active && COLORS.grayThree};

  &:hover {
    background-color: ${({ active }) =>
      active ? COLORS.grayThree : COLORS.grayTwo};
  }
`;
