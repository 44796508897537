import { useContext, useEffect, useState } from "react";
import {
  GeneralAppDataContext,
  CheckoutContext,
} from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import {
  CheckoutBoxLowerSection,
  CheckoutBoxUpperSection,
  CheckoutDetailsBox,
  SubDividerSection,
} from "./styles";
import {
  BoldMediumText,
  DividerSection,
  MainButton,
  SmallText,
  TitleMediumText,
} from "../../../utils/GlobalStyles";
import { GroupedCartItemType } from "../../../utils/Interfaces/Cart";
import CheckoutCartItem from "./CheckoutCartItem";
import { CheckoutContextType } from "../../../utils/Interfaces/Payment";
import { CardWrapper, CheckoutStepsWrapper } from "../styles";
import { Link } from "@mui/material";
// import BillingFields from "./BillingFields";

export default function BillingInformation() {
  const { cart, groupedCart } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const { clientSecret, setStep } = useContext(
    CheckoutContext
  ) as CheckoutContextType;
  const [paymentReady, setPaymentReady] = useState(false);

  useEffect(() => {
    function checkFields() {
      if (clientSecret) {
        setPaymentReady(true);
      } else {
        setPaymentReady(false);
      }
    }
    checkFields();
  }, [clientSecret]);

  const handleGoToPayment = () => {
    if (clientSecret) {
      setStep("PAYMENT");
    }
  };

  return (
    <CheckoutStepsWrapper>
      <TitleMediumText>Checkout</TitleMediumText>
      <CheckoutDetailsBox>
        <CardWrapper>
          <CheckoutBoxUpperSection>
            <BoldMediumText>Casos a comprar</BoldMediumText>
          </CheckoutBoxUpperSection>
          <DividerSection sx={{ margin: "0" }} />
          <CheckoutBoxLowerSection>
            {groupedCart?.map((item: GroupedCartItemType) => (
              <CheckoutCartItem {...item} />
            ))}
          </CheckoutBoxLowerSection>
          <SubDividerSection sx={{ margin: "0" }} />
          <CheckoutBoxUpperSection my={1}>
            <BoldMediumText>Subtotal</BoldMediumText>
            <BoldMediumText>€{cart?.totalAmount}</BoldMediumText>
          </CheckoutBoxUpperSection>
          <SmallText>
            Os códigos promocionais serão aplicados no próximo passo
          </SmallText>
          <CheckoutBoxLowerSection>
            <MainButton
              sx={{ opacity: paymentReady ? "1" : "0.5" }}
              disabled={!paymentReady}
              size="medium"
              fullWidth
              onClick={handleGoToPayment}
            >
              <BoldMediumText>Ir para pagamento</BoldMediumText>
            </MainButton>
          </CheckoutBoxLowerSection>
        </CardWrapper>
        <SmallText width={"100%"}>
          *se pretenderes fatura com número de contribuinte, envia um email para{" "}
          <Link href="mailto: info@desarquivados.com">
            info@desarquivados.com
          </Link>{" "}
          com o nome, NIF e morada que pretendes na fatura.
        </SmallText>
      </CheckoutDetailsBox>
    </CheckoutStepsWrapper>
  );
}
