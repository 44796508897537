import { Box, Button } from "@mui/material";
import {
  BoldMediumText,
  MainButton,
  MediumText,
  BoldLargeText,
  TitleMediumText,
  SmallText,
} from "../../../../utils/GlobalStyles";
import { GameLandingPageProps } from "../../../../utils/Interfaces/Game";
import {
  GameLandingPageWrapper,
  LetterBox,
  LetterTextBox,
  LetterUpperSection,
  ReceiverBox,
} from "./styles";
import parse from "html-react-parser";
import { useContext } from "react";
import { GeneralAppDataContext } from "../../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../../utils/Interfaces/Global";
import { NavLink, useLocation } from "react-router-dom";
import { COLORS } from "../../../../assets/theme";

export default function GameLandingPage({
  caseData,
  handleOpenCase,
}: GameLandingPageProps) {
  const { user } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const location = useLocation();
  const isSharedCaseGame = location.pathname.startsWith("/shared-case");

  const getFormattedDate = () => {
    const today = new Date();

    const day = today.getDate();
    const year = today.getFullYear();

    // Array of month names
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const month = months[today.getMonth()];

    return `${day} de ${month}, ${year}`;
  };

  return (
    <GameLandingPageWrapper>
      <Box sx={{ position: "fixed", top: "20px", right: "30px" }}>
        <NavLink to={!isSharedCaseGame ? "/account" : "/cases"}>
          <Button variant="text">
            <SmallText color={`${COLORS.black} !important`}>
              Sair do caso
            </SmallText>
          </Button>
        </NavLink>
      </Box>
      <TitleMediumText mt={4} mb={2}>
        Carta da Polícia de Portugal
      </TitleMediumText>
      <LetterBox>
        <LetterTextBox>
          <LetterUpperSection>
            <BoldLargeText>CASO {caseData?.caseNumber}</BoldLargeText>
            <img
              width={200}
              src={require("../../../../assets/cases-content/policia_portugal_black.png")}
              alt="agency logo"
            />
          </LetterUpperSection>
          <Box mt={3}>
            <BoldMediumText>Rogério Flores,</BoldMediumText>
            <BoldMediumText>
              Departamento de Investigação Criminal
            </BoldMediumText>
          </Box>
          <ReceiverBox mb={4}>
            <BoldMediumText>
              Detetive {user?.firstName} {user?.lastName}
            </BoldMediumText>
            <MediumText mt={2}>Lisboa, {getFormattedDate()}</MediumText>
          </ReceiverBox>
          <BoldMediumText mt={2} mb={4} textAlign="center">
            ﹡INFORMAÇÃO CONFIDENCIAL﹡
          </BoldMediumText>
          <MediumText mb={3}>
            {parse(caseData?.letterText)}
            <br></br>O vosso,
            <br></br>
            <BoldMediumText>
              Coordenador de investigação criminal,
              <br></br>
              Rogério Flores
            </BoldMediumText>
          </MediumText>
          <BoldMediumText mt={2} textAlign="center">
            ﹡INFORMAÇÃO CONFIDENCIAL﹡
          </BoldMediumText>
        </LetterTextBox>
        <img
          src={require("../../../../assets/cases-content/policia_portugal_footer.png")}
          alt="agency logo"
        />
      </LetterBox>
      <MainButton
        sx={{ marginTop: "20px" }}
        size="large"
        onClick={handleOpenCase}
      >
        <BoldMediumText>Analisar caso</BoldMediumText>
      </MainButton>
    </GameLandingPageWrapper>
  );
}
