import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";

export function generateFileType(extension: string) {
  switch (extension) {
    case "mp4":
      return "video/mp4";
    case "pdf":
      return "application/pdf";
    case "jpg":
      return "image/jpg";
    default:
      return "";
  }
}

export function generateFileTypeIcon(extension: string) {
  switch (extension) {
    case "mp4":
      return <VideocamRoundedIcon style={{ width: "20px", height: "20px" }} />;
    case "pdf":
      return (
        <InsertDriveFileRoundedIcon style={{ width: "20px", height: "20px" }} />
      );
    case "jpg":
      return <ImageRoundedIcon style={{ width: "20px", height: "20px" }} />;
    case "mp3":
      return <MicRoundedIcon style={{ width: "20px", height: "20px" }} />;
    default:
      return <></>;
  }
}
