import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useContext } from "react";
import { CheckoutContext } from "../../../utils/GeneralContext";
import { CheckoutContextType } from "../../../utils/Interfaces/Payment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

function Payment() {
  const { clientSecret } = useContext(CheckoutContext) as CheckoutContextType;
  const options: any = {
    clientSecret: clientSecret?.clientSecret,
  };

  return (
    <div style={{ width: "100%" }}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
        data-email="someone@example.com"
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

export default Payment;
