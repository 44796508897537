import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextField,
} from "@mui/material";
import { Transition } from "../../../utils/Transitions";
import { CaseReviewModalProps } from "../../../utils/Interfaces/Case";
import {
  BoldLargeText,
  BoldSmallText,
  MainButton,
  NoBackgroundButton,
  SmallText,
} from "../../../utils/GlobalStyles";
import React from "react";
import { COLORS } from "../../../assets/theme";
import SmallLoader from "../../loaders/SmallLoader";

function CaseReviewModal({
  caseReviewModal,
  handleCaseReview,
  handleModalClose,
  isLoading,
}: CaseReviewModalProps) {
  const [reviewReady, setReviewReady] = React.useState<boolean>(false);
  const [classification, setClassification] = React.useState<number | null>(
    null,
  );
  const [title, setTitle] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");

  const handlePostCaseReview = () => {
    handleCaseReview({ title, comment, classification });
  };

  React.useEffect(() => {
    setClassification(null);
    setTitle("");
    setComment("");
  }, [caseReviewModal]);

  React.useEffect(() => {
    if (classification && title !== "" && comment !== "") {
      setReviewReady(true);
    } else {
      setReviewReady(false);
    }
  }, [classification, title, comment]);

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={caseReviewModal}
      fullWidth
    >
      <DialogTitle my={"10px"}>
        <BoldLargeText>Escreve uma crítica</BoldLargeText>
      </DialogTitle>
      <Box display="flex" sx={{ flexFlow: "column" }}>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <>
            <DialogContent>
              <SmallText>
                A tua opinião conta. E ficará visível para outros detetives
                verem!
              </SmallText>
              <Rating
                sx={{ margin: "10px 0 15px 0" }}
                name="simple-controlled"
                value={classification}
                onChange={(event, newValue) => {
                  setClassification(newValue);
                }}
              />
              <TextField
                label="Título"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTitle(e?.target?.value)
                }
              />
              <TextField
                sx={{ marginTop: "10px" }}
                label="Comentário"
                variant="outlined"
                multiline
                rows={4}
                value={comment}
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setComment(e?.target?.value)
                }
              />
            </DialogContent>
            <DialogActions style={{ gap: "10px", margin: "20px" }}>
              <NoBackgroundButton onClick={handleModalClose}>
                <BoldSmallText color={`${COLORS.redTwo} !important`}>
                  Fechar
                </BoldSmallText>
              </NoBackgroundButton>
              <MainButton
                sx={{ opacity: reviewReady ? 1 : 0.7 }}
                disabled={!reviewReady}
                onClick={handlePostCaseReview}
              >
                <BoldSmallText color={`${COLORS.white} !important`}>
                  Submeter
                </BoldSmallText>
              </MainButton>
            </DialogActions>
          </>
        )}
      </Box>
    </Dialog>
  );
}

export default CaseReviewModal;
