import { NavLink } from "react-router-dom";
import { BoldBannerText, LinkBannerText, UpperBannerWrapper } from "./styles";

function UpperBanner() {
  return (
    <UpperBannerWrapper>
      <BoldBannerText>Queres ser um detetive?</BoldBannerText>
      <NavLink to="/cases">
        <LinkBannerText>Resolve casos!</LinkBannerText>
      </NavLink>
    </UpperBannerWrapper>
  );
}

export default UpperBanner;
