import { Box, styled } from "@mui/material";

export const TooSmallScreenWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  padding: 0 10vw;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    display: none;
  }
`;
