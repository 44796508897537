import CookieConsent from "react-cookie-consent";
import { BoldSmallText, MediumText } from "../../utils/GlobalStyles";
import { NavLink } from "react-router-dom";
import { COLORS, PALETTE } from "../../assets/theme";
import { Box } from "@mui/material";
import { CookieConsentTextBox } from "./styles";

function Cookies() {
  return (
    <Box sx={{ backgroundColor: "red", position: "relative" }}>
      <CookieConsent
        location="bottom"
        buttonText="Concordo"
        declineButtonText="Não concordo"
        cookieName="myAppCookieConsent"
        style={{
          display: "flex",
          justifyContent: "right",
          width: "fit-content",
          maxWidth: "500px",
          borderRadius: "6px",
          height: "fit-content",
          background: COLORS.grayOne,
          position: "absolute",
          top: "50%",
          left: "50%",
          padding: "5px",
          transform: "translate(-50%, -50%)",
          boxShadow: "box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;",
        }}
        buttonStyle={{
          background: PALETTE.primaryThree,
          color: COLORS.graySix,
          fontSize: "12px",
          borderRadius: "6px",
          marginRight: "15px",
        }}
        declineButtonStyle={{
          background: PALETTE.primaryTwo,
          color: COLORS.white,
          fontSize: "12px",
          borderRadius: "6px",
        }}
        expires={150}
        enableDeclineButton
        overlay
      >
        <CookieConsentTextBox>
          <BoldSmallText>
            Este site utiliza cookies para melhorar a experiência do utilizador.
            Ver{" "}
            <NavLink to="/terms-and-conditions">
              <span
                style={{
                  color: "${COLORS.blueThree}",
                  textDecoration: "underline",
                }}
              >
                política de privacidade
              </span>
            </NavLink>{" "}
          </BoldSmallText>
        </CookieConsentTextBox>
      </CookieConsent>
    </Box>
  );
}

export default Cookies;
