import PageHeader from "../../components/PageHeader";
import { Image, PaymentCard, PaymentsBox, PaymentsWrapper } from "./styles";
import { BoldMediumText } from "../../utils/GlobalStyles";

const Methods = [
  {
    image: require("../../assets/payment-methods/apple_pay.png"),
    name: "Apple Pay",
  },
  {
    image: require("../../assets/payment-methods/paypal.png"),
    name: "Paypal",
  },
  {
    image: require("../../assets/payment-methods/google_pay.png"),
    name: "Google Pay",
  },
  {
    image: require("../../assets/payment-methods/mastercard.png"),
    name: "Mastercard",
  },
  {
    image: require("../../assets/payment-methods/visa.png"),
    name: "Visa",
  },
  {
    image: require("../../assets/payment-methods/american_express.png"),
    name: "American Express",
  },
];

export default function PaymentMethods() {
  return (
    <PaymentsWrapper>
      <PageHeader
        heading="Métodos de pagamento"
        section={""}
        content="A segurança dos teus pagamentos é muito importante e, por isso, usamos uma plataforma que permite diferentes formas de pagamento e garante a segurança ponto a ponto."
      />
      <PaymentsBox>
        {Methods?.map((method, index) => (
          <PaymentCard>
            <Image src={method?.image} alt={method?.name} />
            <BoldMediumText textAlign="center">{method?.name}</BoldMediumText>
          </PaymentCard>
        ))}
      </PaymentsBox>
    </PaymentsWrapper>
  );
}
