import { useContext, useEffect } from "react";
import { CheckoutContextType } from "../../../utils/Interfaces/Payment";
import {
  CheckoutContext,
  GeneralAppDataContext,
} from "../../../utils/GeneralContext";
import BillingInformation from "../../../components/CheckoutSteps/BillingInformation";
import { generateCheckoutSessionItems } from "../../../utils/HelpFunctions";
import { useMutation } from "@tanstack/react-query";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import Payment from "../../../components/CheckoutSteps/Payment";
import { postData } from "../../../utils/endpoints/api";
import SmallLoader from "../../../components/loaders/SmallLoader";

const SelectCheckoutPage = () => {
  const { user, cart, groupedCart, setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const { step, setClientSecret } = useContext(
    CheckoutContext,
  ) as CheckoutContextType;
  const checkoutSessionCreateMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("checkout/create", params, body, true),
    onSuccess: (data) => {
      setClientSecret(data);
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao criar o checkout. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    createCheckoutSession();
  }, [groupedCart]);

  async function createCheckoutSession() {
    if (groupedCart && groupedCart.length > 0) {
      try {
        if (user?.id && user?.email && cart) {
          const checkoutItems = generateCheckoutSessionItems(
            window.location.origin,
            user.id,
            user.email,
            groupedCart,
            cart,
          );
          await checkoutSessionCreateMutation.mutateAsync({
            params: {},
            body: checkoutItems,
          });
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
      }
    }
  }

  const isLoading = checkoutSessionCreateMutation.isPending;

  const CheckoutPage = () => {
    switch (step) {
      case "BILLING":
        return <BillingInformation />;
      case "PAYMENT":
        return <Payment />;
      default:
        return <BillingInformation />;
    }
  };

  return isLoading ? <SmallLoader /> : <CheckoutPage />;
};

export default SelectCheckoutPage;
