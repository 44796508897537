import { LandingItemImage, LandingSliderItemWrapper } from "./styles";
import { LandingSliderItemProps } from "../../../utils/Interfaces/Global";

function LandingSliderItem({ text, imageUrl }: LandingSliderItemProps) {
  return (
    <LandingSliderItemWrapper>
      <LandingItemImage src={imageUrl} alt="" />
      {text}
    </LandingSliderItemWrapper>
  );
}

export default LandingSliderItem;
