import { Box, Button, styled } from "@mui/material";

export const AboutUsWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;
`;

export const AboutUsBox = styled(Box)`
  display: grid;
  max-width: 1000px;
  gap: 30px;
  margin: 20px auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
  }

  @media (min-width: 960px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 50px;
  }
`;
