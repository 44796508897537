import { Fragment, useContext, useEffect, useState } from "react";
import Profile from "../../components/Profile";
import BoughtCases from "../../components/BoughtCases";
import Orders from "../../components/Orders";
import { useLocation } from "react-router-dom";
import { AccountContentBox, AccountContentWrapper } from "./styles";
import AccountHeader from "../../components/AccountHeader";
import ReactGA from "react-ga4";

export default function Account() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.send({
      hitType: "pageview",
      page: "/account",
      title: "Account",
    });
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "Account");
    }
  }

  let location = useLocation();
  const [tab, setTab] = useState(location?.state?.tab ?? 0);

  useEffect(() => {
    const checkLocationIndex = () => {
      if (location?.state) {
        setTab(location?.state?.tab);
      } else {
        setTab(0);
      }
    };
    checkLocationIndex();
  }, [location?.state]);

  const TabContent = () => {
    switch (tab) {
      case 0:
        return <BoughtCases />;
      case 1:
        return <Profile />;
      case 2:
        return <Orders />;
      default:
        return <BoughtCases />;
    }
  };

  return (
    <AccountContentWrapper>
      <AccountContentBox>
        <AccountHeader tab={tab} setTab={setTab} />
        <TabContent />
      </AccountContentBox>
    </AccountContentWrapper>
  );
}
