import { useContext } from "react";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  NoBackgroundButton,
} from "../../../../../utils/GlobalStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ConfirmSolveMyCaseType } from "../../../../../utils/Interfaces/Game";
import { GeneralAppDataContext } from "../../../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../../../utils/Interfaces/Global";
import { Transition } from "../../../../../utils/Transitions";
import { COLORS } from "../../../../../assets/theme";

export default function ConfirmSolveCaseModal({
  open,
  handleConfirmModalOpen,
  handleReportSubmitted,
  selectedVeridict,
}: ConfirmSolveMyCaseType) {
  const { user } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      onClose={handleConfirmModalOpen}
      fullWidth
    >
      <DialogTitle my={"10px"}>
        <BoldMediumText>
          Tens a certeza de que foi {selectedVeridict?.name}?
        </BoldMediumText>
        <MediumText mt={2}>
          Acusar um inocente de um crime que não cometeu pode comprometer a tua
          carreira, Detetive {user?.lastName}
        </MediumText>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions style={{ gap: "10px", margin: "20px" }}>
        <NoBackgroundButton onClick={handleConfirmModalOpen}>
          <BoldSmallText color={`${COLORS.redTwo} !important`}>
            Voltar
          </BoldSmallText>
        </NoBackgroundButton>
        <MainButton onClick={() => handleReportSubmitted(selectedVeridict)}>
          <BoldSmallText>Confirmar</BoldSmallText>
        </MainButton>
      </DialogActions>
    </Dialog>
  );
}
