import { Box, styled } from "@mui/material";

export const CheckoutCartItemBox = styled(Box)`
  display: flex;
  gap: 15px;
  height: 70px;
`;

export const CheckoutCartItemImage = styled("img")`
  width: 90px;
  height: 100%;
  border-radius: 6px;
  object-position: top;
  object-fit: cover;
`;

export const TextInfoBox = styled(Box)`
  display: flex;
  padding-top: 15px;
  width: 100%;
  justify-content: space-between;
`;

export const LeftColumnBox = styled(Box)`
  display: flex;
  flex-flow: column;
`;

export const SubCostBox = styled(Box)`
  display: flex;
  gap: 4px;
`;
