import { ClipLoader } from "react-spinners";
import { PALETTE } from "../../../assets/theme";
import { LocalLoaderDiv, LocalLoaderDivProps } from "./styles";

function LocalLoader({ height }: LocalLoaderDivProps) {
  return (
    <LocalLoaderDiv height={height}>
      <ClipLoader
        size={25}
        speedMultiplier={1.3}
        color={PALETTE.primaryThree}
      />
    </LocalLoaderDiv>
  );
}

export default LocalLoader;
